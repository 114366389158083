.usermgmt,
.admin {
  max-width: 1080px;
  background-color: $hellgrau;
}

.ibody {
  position: relative;
  min-height: 100%;
}

.ibody::after {
  content: "";
  display: block;
  height: 35px;
}

.ibody__cart::after {
  height: 150px !important;
}

.campaigninfo {
  padding: 0 $std-padding-mobile 0 $std-padding-mobile;
  margin: 0 auto;
  text-align: center;
  background-color: $eyecatcherorange;
}

.upload {
  padding: 35px $std-padding-mobile 60px $std-padding-mobile;
  margin: 0 auto;
  text-align: center;
  background-color: $hellgrau;
}

.upload__header,
.payment__header,
.payment__choiceheader {
  font-family: $font_header_bold;
  font-size: 25px;
  line-height: 20px;
  color: $dunkelblau;
}

.upload__header_tooltip {
  height: 20px;
}

.upload__table {
  display: table;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 25px;
}

.upload__section {
  display: table-cell;
  width: 50%;
}

.upload__section:first-child {
  padding-right: 7.5px;
}

.upload__section:last-child {
  padding-left: 7.5px;
}

.upload__section_header {
  display: none;
  margin: 0;
  font-family: $font_header_normal;
  font-size: 25px;
  line-height: 20px;
  color: $dunkelblau;
}

.tmp-comingsoon .upload__section_header {
  font-family: $font_header_bold;
  margin-bottom: 25px;
}

.tmp-comingsoon p {
  color: $blau;
}

.upload__section_header_tooltip {
  height: 20px;
}

.upload__section_download {
  width: 100%;
  height: 150px;
  border: 3px solid $dashedborder;
  border-radius: 20px;
  background-color: $weiss;
  background-repeat: no-repeat;
  background-size: auto 60px;
  background-position: center 25px;
}

.upload__section_download--einzelbrief {
  background-image: url(../../images/upload_einzelbrief.png);
}

.upload__section_download--serienbrief {
  background-image: url(../../images/upload_serienbrief.png);
}

.upload__section_clickable {
  width: 100%;
  height: 100%;
}

.upload__section_action {
  display: none;
  padding-top: 164px;
  padding-bottom: 35px;
  font-size: 18px;
  line-height: 20px;
  color: $blau;
}

.upload__section_action--bold {
  font-family: $font_content_bold;
}

.upload__section_label {
  padding-top: 95px;
  padding-bottom: 20px;
  font-family: $font_header_normal;
  font-size: 15px;
  line-height: 20px;
  color: $gruen;
  display: block;
  text-transform: uppercase;
}

.upload__section_type {
  display: block;
  font-family: $font_header_bold;
}

.upload__section_formatinfo {
  display: none;
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
  color: $dunkelgrau;
}

.upload__section_formatinfo-green {
  color: $gruen;
}

.upload__formatinfo_mobile {
  margin-top: 15px;
  font-size: 12px;
  color: $dunkelgrau;
}

.upload__formatinfo_bold {
  font-family: $font_content_normal;
}

.mailbox {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: $grau;
}

.mailbox__empty {
  border: 1px solid $dashedborder;
  border-radius: $box-radius;
  padding: $std-padding-desktop 50px $std-padding-desktop 50px;
  font-size: 15px;
  line-height: 20px;
  color: $blau;
  background-color: $weiss;
  margin-bottom: 20px;
  font-family: $font_content_normal;
  display: none;
  &.mailbox__empty--is-empty {
    display: block;
  }
}

.accountfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background-color: $blau;
}

.accountfooter__linklist {
  padding: 0 $std-padding-mobile 0 $std-padding-mobile;
  margin: 0 auto;
  margin-top: 1px;
  text-align: center;
  font-size: 0;
  height: 35px;
}

.accountfooter__listitem {
  display: inline-block;
  list-style-type: none;
  padding-right: $std-padding-mobile;
}

.accountfooter__listitem:last-child {
  padding-right: 0;
}

.accountfooter__listitem_link {
  font-family: $font_header_normal;
  font-size: 12px;
  line-height: 34px;
  color: $weiss;
}

.accountfooter__listitem_link--desk {
  display: none;
}

.dz-message,
.dz-error {
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
}

.mailbox__navigation--mobile {
  background: $weiss;
  width: 100%;
  min-height: 79px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.blockitems {
  display: table;
  width: 100%;
}

.blockitems__info {
  display: table-cell;
  width: 35%;
  float: right;
  background-color: #b0413e;
}

.blockitems__main {
  display: table-cell;
  width: 65%;
  float: left;
  background-color: #e4a600;
}

.cart-navigation-items-left {
  width: 50%;
  float: left;
  text-align: left;
  padding: 10px 10px 10px $std-padding-mobile;
}

.cart-navigation-items-right {
  width: 50%;
  float: right;
  padding: $std-padding-mobile;
  max-width: 300px;
}

.cart-navigation-item {
  &-quantity {
    font-size: 16px;
    color: $blau;
    font-family: $font_header_normal;
    text-transform: uppercase;
  }
  &-total {
    font-size: 20px;
    color: $blau;
    font-family: $font_header_bold;
  }
  &-vat {
    font-size: 15px;
    color: $dunkelgrau;
    font-family: $font_header_normal;
  }
}

.mailbox-navigation-items-left {
  width: 35%;
  float: left;
  text-align: left;
  padding: 15px 10px 10px $std-padding-mobile;
}

.mailbox-navigation-items-right {
  width: 65%;
  float: right;
  padding: $std-padding-mobile;
  max-width: 300px;
}

.mailbox-navigation-item {
  &-quantity {
    font-size: 18px;
    color: $blau;
    font-family: $font_header_normal;
    text-transform: uppercase;
  }
  &-total {
    font-size: 26px;
    color: $blau;
    font-family: $font_header_bold;
  }
}

.mailbox__block,
.preview__image {
  box-shadow: 0 0 50px 6px rgba(0, 0, 0, 0.1);
}

.payment__cartarticles {
  //padding: 20px 0; !!!
  text-align: left;
}

.payment__cartarticles .joblist {
  text-align: center;
}

.payment__cartaddress_header,
.payment__cartarticles_header {
  margin-bottom: 15px;
  color: $blau;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
}

.payment__cartaddress_header {
  height: auto;
}

.payment__cartarticles_headeraction {
  display: inline-block;
  float: right;
  color: $dunkelgrau;
  font-family: $font_content_normal;
  font-size: 11px;
  text-transform: none;
}

.payment__cartarticles_header.payment__verifizieren_header {
  margin-bottom: 5px;
  height: auto;
}

.payment__verifizieren_subheader {
  color: #315171;
  margin-bottom: 15px;
  min-height: 25px;
}

.payment__cartarticles_headeractionlong {
  display: none;
}

.payment__cartarticles_headeraction:hover {
  color: $joberrorcolor;
}

.payment__productinfo_mobile {
  padding: 15px 20px 30px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: none;
  background-color: #fff;
  margin-top: 1em;
}

.payment__productinfo-cta_mobile {
  margin-top: 1em;
}

.disabled__overlay--wrapper {
  position: relative;

  > div.disabled__activeoverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.6);
  }

  > div.disabled__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
  }

  &.disabled__overlay--active {
    opacity: 0.4;
    > .disabled__overlay {
      display: block;
    }
  }
}

.mailbox__headline {
  color: $blau;
  width: 100%;
  height: 40px;
  margin-bottom: 25px;
  display: none;
  position: relative;
  .headline-item-left {
    width: 40%;
    text-align: left;
    display: inline-block;
    float: left;
    font-size: 11px;
    padding-top: 13px;
    font-family: $font_content_normal;
    > div {
      display: inline-block;
      position: relative;
      &:first-child {
        margin-right: 20px;
      }
    }
    .icofont-trash {
      color: #c6c6c6;
      font-size: 13px;
      display: inline-block;
      position: relative;
      top: -1px;
    }
  }
  .headline-item-right {
    width: 60%;
    text-align: right;
    display: inline-block;
    position: relative;
    height: 40px;
    h1 {
      font-size: 26px;
    }
  }
  .item-right--price {
    position: absolute;
    right: 215px;
    top: 0;
    .job-price {
      font-family: $font_header_bold;
    }
  }
  .item-right--button {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 200px;
    margin-left: 20px;
  }
}

.mailbox__iconlink_basketall {
  display: inline-block;
  height: 17px;
  line-height: 13px;
  padding: 2px 0 0 25px;
  background-repeat: no-repeat;
  background-size: auto 17px;
  background-position: left center;
  background-image: url(../../images/mailbox-basket-all.svg);
}

.cbox__account {
  padding: 20px;
  border: 1px solid #dedede;
  box-shadow: none;
}

.accountfooter__payment--mobile {
  background: $grau;
  text-align: center;
  position: relative;
}

.accountfooter__payment--desktop {
  background: $grau;
  text-align: center;
  position: relative;
  width: 100%;
}

.content--hellgrau {
  background: $hellgrau;
}

.accountfooter__payment--desktop {
  display: none;
}

.accountfooter__payment--mobile {
  display: block;
}

.accountfooter__payment--offset {
  padding: 0 20px;
}

.dropzone {
  cursor: pointer;
}

.payment {
  max-width: 1140px;
  padding: 0;
}

.payment--cart {
  padding-bottom: 205px;
  min-height: calc(100vh - 456px);
}

.payment--pc {
  min-height: calc(100vh - 86px);
}

.payment__header {
  margin-top: 110px;
  margin-bottom: 25px;
}

.payment__choiceheader {
  margin-top: 35px;
  line-height: 30px;
}

.payment__choiceheader-mobileline {
  display: block;
}

.payment__choiceheader-step {
  font-size: 15px;
}

.payment__address-type--hidden {
  display: none;
}

.payment__sepaheader {
  padding-bottom: 10px;
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 18px;
  color: $dunkelblau;
  text-align: left;
}

.payment__productbox {
  position: absolute;
  top: 0;
  width: 100%;
  border-top: 1px solid $grau;
  box-shadow: 0 0 70px 6px rgba(0, 0, 0, 0.15);
  text-align: left;
}

.payment__productinfo {
  padding: 12.5px 20px 15px 20px;
  background-color: $weiss;
  box-shadow: inset 0 36px 28px -36px rgba(20, 34, 47, 0.175);
}

.payment__productinfo_list {
  display: table;
  width: 100%;
  max-width: 350px;
  margin: 0;
  padding: 0;
}

.payment__productinfo_list--fw {
  max-width: none;
}

.payment__productinfo_listitem {
  display: table-row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.payment__productinfo_listitem-desc,
.payment__productinfo_listitem-value {
  display: table-cell;
  //width: 50%;
  font-family: $font_content_normal;
  font-size: 15px; // 16px
  line-height: 21px;
  color: $blau; // $dunkelblau
}

.payment__productinfo_listitem-value {
  min-width: fit-content;
  font-size: 17px; // 18
}

.payment__productinfo_listitem--strong .payment__productinfo_listitem-desc,
.payment__productinfo_listitem--strong .payment__productinfo_listitem-value {
  padding-top: 5px;
  //font-family: $font_content_bold;
  font-family: $font-header_normal;
  font-size: 19px;
  line-height: 23px;
  text-transform: uppercase;
}

.payment__productinfo_listitem-desc {
  text-align: left;
}

.payment__productinfo_listitem-value {
  text-align: right;
}

.payment__productinfo-info {
  margin-top: 10px;
  font-family: $font_content_normal;
  font-size: 12px;
  line-height: 12px;
  color: $dunkelgrau;
}

.payment__productinfo-info--desk {
  display: none;
}

.payment__productinfo-cta {
  display: none;
}

.payment__productinfo-paymentchoices {
  margin-top: 12.5px;
}

.payment__productinfo-cta--guest {
  margin-top: 22.5px;
}

.payment__productinfo-cta--login {
  margin-top: 42.5px;
}

.payment__productinfo-cta--register {
  margin-top: 22.5px;
}

.payment__choices {
  padding: 35px $std-padding-mobile 0 $std-padding-mobile;
}

.payment__choices--cartinfo {
  padding-top: 420px;
}

.payment__choices--cartinfo.payment__choices--verify {
  padding-top: 320px;
}

.payment__choices--cartinfo.registered {
  padding-top: 221px;
}

.payment__choices--cartinfohigher {
  padding-top: 242px;
}

.payment__choices--pcinfo {
  padding-top: 169px;
}

.payment__choices--pcinfohigher {
  padding-top: 190px;
}

.paymentchoices__choicelist {
  padding: 0;
  margin: 0;
}

.paymentchoices__choicelistitem {
  list-style-type: none;
  margin-bottom: 20px;
  background-color: $weiss;
  border: 1px solid $bordergrau;
  border-radius: $box-radius;

  &.selected {
    border-color: #96c11f !important;
  }
}

.selected-label {
  color: #e4a600;
  font-size: smaller;
  margin-left: 0.1rem;
  font-weight: 400;
}

.paymentchoices__choicelistitem-wrapper {
  cursor: pointer;
  padding: 15px;
  display: table;
  width: 100%;
}

.paymentchoices__choicelistitem-left {
  float: left;
  padding-top: 3px;
  padding-bottom: 3px;
}

.paymentchoices__choicelistitem-thumb {
  float: right;
  width: auto;
  height: 24px;
  margin-left: 3px;
}

.paymentchoices__choicelistitem-thumb:last-of-type {
  margin-left: 0;
}

.paymentchoices__choicelistitem-availability {
  display: inline-block;
  float: right;
  height: 26px;
  margin-right: 15px;
  font-family: $font_content_semibold;
  font-size: 14px;
  line-height: 26px;
}

.payment__mobilesubmit {
  padding: $std-padding-mobile;
  padding-bottom: 30px;
  text-align: center;
}

.payment__mobilesubmit_info {
  margin-bottom: 20px;
  font-size: 13px;
  text-align: left;
  color: #315171;
}

.payment__mobilesubmit_ba2ba {
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px;
}

.paymentchoices__error {
  list-style-type: none;
  margin-top: 35px;
}

.paymentcc__error {
  list-style-type: none;
}

.paymentchoices__error > .form__errors,
.paymentcc__error > .form__errors {
  display: inline-block;
  margin: 0 auto;
  color: $dunkelblau;
}

.payment__content_errorbox,
.payment__content_successbox,
.payment__choicecontent {
  padding: $std-padding-mobile;
  border-radius: $box-radius;
  background-color: $weiss;
}

.payment__content_successbox {
  width: 100%;
  padding: 130px 40px 50px 40px;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 30px;
  background-image: url(../../images/logo-success.svg);
  background-size: auto 50px;
  background-repeat: no-repeat;
  background-position: center 50px;
}

.payment__content_info {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 21px;
  color: $blau;
}

.payment__content_thanks {
  font-family: $font_content_bold;
  font-size: 15px;
  color: $blau;
}

.payment__content_successheader {
  margin: 0;
  font-family: $font_header_bold;
  font-size: 25px;
  line-height: 35px;
}

.payment__content_ctawrapper {
  margin-top: 45px;
}

.payment__choice,
.payment__choicecontent {
  padding: $std-padding-mobile;
}

.payment__mobilesubmit_ba2pc {
  font-family: $font_content_normal;
  font-size: 15px;
  color: $gruen;
  margin: 0 auto;
}

.payment__mandat {
  font-family: $font_content_normal;
  font-size: 18px;
  color: $blau;
}

.payment__mandat-info {
  margin-bottom: 15px;
}

.payment__mandat-text strong {
  font-family: $font_content_bold;
  color: $dunkelblau;
}

.payment__mandat-text p {
  margin-bottom: 15px;
}

.payment__mandat-text dl {
  margin: 20px 0 25px 0;
}

.payment__mandat-text dl dt {
  font-family: $font_content_bold;
  color: $dunkelblau;
}

.payment__mandat-text dl dd {
  font-family: $font_content_normal;
  color: $blau;
}

.payment__mandat-text dl dd {
  margin-left: 0;
  margin-bottom: 10px;
}

.payment__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  background-color: rgba(49, 81, 113, 0.9);
  background-image: url(../../images/payment-loading-75.png);
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
}

.spinner__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  background-color: rgba(249, 249, 249, 0.5);
  background-image: url(../../images/loading-commissions.svg);
  background-size: 75px 75px;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
}

.preview__image {
  border: 3px dashed $gruen;
  width: 100%;
}

/* Slideshow container */
.preview__container {
  position: relative;
  text-align: center;
  margin: auto;
  font-family: $font_content_bold;
}

/* Hide the images by default */
.preview__slide_container {
  //display: none;
}

.preview__slide {
  padding: 5px 0;
  position: relative;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: $gruen;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px;
  user-select: none;
}

.prev {
  left: 30px;
}

.next {
  right: 30px;
}

.preview-nav-desk {
  display: none;
}

.preview-nav-mobile {
  display: inline-block;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: relative;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.preview__number {
  color: $gruen;
  font-size: 18px;
  position: absolute;
  bottom: -20px;
  top: unset;
  font-family: $font-content_bold;
}

.preview__error {
  color: $rot;
  font-family: $font-content_bold;
  margin-bottom: 25px;
  i {
    display: block;
  }
}

.preview__error-fixing {
  color: $blau;
  font-family: $font-content_bold;
  margin-bottom: 5px;
  i {
    display: inline-block;
  }
}

.help {
  padding-top: 0;
}

.help__tabs {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 0;
}

.help-tab-list {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: $font_content_normal;
}

.help__tab {
  display: inline-block;
  height: 48px;
  width: calc(50% - 5px);
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 48px;
  color: #96c11f;
  text-transform: uppercase;
  background-color: $weiss;
  border-left: 1px solid $bordergrau;
  border-top: 1px solid $bordergrau;
  border-right: 1px solid $bordergrau;
  border-top-left-radius: $box-radius;
  border-top-right-radius: $box-radius;
}

.help__tab--spacer {
  width: 10px;
  background-color: transparent;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid $bordergrau;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  vertical-align: bottom;
}

.help__tab--inactive {
  color: $weiss;
  background-color: $blau;
  //border-color: $blau;
}

.help__tab--fill {
  display: none;
}

.help__tab-desklabel {
  display: none;
}

.help__tab_link {
  display: block;
  width: 100%;
  height: 100%;
  color: $weiss;
}

.help__content {
  width: 100%;
  padding: $std-padding-mobile;
  background-color: $weiss;
  border-left: 1px solid $bordergrau;
  border-bottom: 1px solid $bordergrau;
  border-right: 1px solid $bordergrau;
  border-bottom-left-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
}

.help__header {
  font-family: $font_header_bold;
  font-size: 15px;
  line-height: 21px;
  color: $dunkelblau;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.help__info {
  font-size: 13px;
  line-height: 20px;
  color: $dunkelblau;
  padding-bottom: 25px;
  text-align: left;
}

.help__topic_header {
  font-size: 20px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: $font_header_bold;
  text-transform: uppercase;
  text-align: left;
  color: #022856;
}

.online_schreiben__header {
  text-align: center;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 25px;
}

.online_schreiben__header--bold {
  font-family: $font_header_bold;
}

.online_schreiben__info {
  text-align: center;
  font-size: 18px;
}

.online_schreiben__content__comparison {
  display: inline-block;
}

.online_schreiben__comparison__item_sublistitem {
  margin-bottom: 20px;
  font-size: 18px;
  color: #022856;
  font-family: SourceSansProSemiBold, "sans-serif";
}

.online_schreiben--normal {
  font-family: FiraSansCondensed, "sans-serif";
}

.online_schreiben__list {
  padding: 0;
}

.help__faqbox {
  margin-top: 35px;
  padding-bottom: 15px;
}

.help__content_submittalbox {
  width: 100%;
  background-color: $hellgrau;
  border-radius: $box-radius;
  padding: $std-padding-mobile;
  margin: 15px 0 15px 0;
}

.help__content_submittalbox:last-child {
  margin-bottom: 7rem;
}

.help__content_submittalheader {
  font-family: $font_content_bold;
  font-size: 18px;
  line-height: 18px;
  color: $dunkelblau;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px;
}

.help__content_submittalinfo--image {
  max-width: 130px;
}

.help__content_submittalboxcell .cta--download--doc {
  width: 80px;
  height: 161px;
  display: inline-block;
  padding-top: 85px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50% 20%;
  border-radius: 6px;
}

.help__content_submittalboxcell .cta--download--doc:hover {
  background-color: #ffffff;
}

.help__content_submittalboxcell .cta__wrapper--text {
  color: #022856;
  font-size: 12px;
  display: block;
  margin-bottom: 6px;
  font-family: SourceSansPro, "sans-serif";
}

.help__content_submittalboxcell .word {
  background-image: url(../../images/logo-word.png);
}

.help__content_submittalboxcell .openoffice {
  background-image: url(../../images/logo-oo.png);
}

.help__content_submittalboxcell .apple {
  background-image: url(../../images/logo-pages.png);
}

.help__content_submittalinfo {
  font-size: 13px;
  line-height: 18px;
  color: $dunkelgrau;
  text-align: center;
  margin-bottom: 25px;
}

.usermgmt__headtable {
  display: table;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 0;
  font-family: $font_content_normal;
  font-size: 18px;
  color: $dunkelblau;
}

.usermgmt__headcell {
  display: table-cell;
  vertical-align: middle;
}

.usermgmt__headcell--header {
  display: none;
  text-align: left;
}

.usermgmt__headcell_header {
  margin: 0;
  font-family: $font_header_bold;
  font-size: 25px;
  line-height: 19px;
}

.usermgmt__headcell--usernr,
.usermgmt__headcell--search {
  width: 100%;
  text-align: center;
}

.usermgmt__headcell_usernr {
  display: block;
  padding: $std-padding-mobile;
  border-radius: $box-radius;
  background-color: $grau;
}

.usermgmt__list {
  width: 100%;
  margin: 0;
  padding: 0;
  color: $blau;
}

.usermgmt__listitem {
  list-style-type: none;
  border: 1px solid $bordergrau;
  border-radius: $box-radius;
  margin-bottom: 25px;
  background-color: $weiss;
}

.usermgmt__listitem:last-child {
  margin-bottom: 0;
}

.usermgmt__listitem_question {
  display: table;
  width: 100%;
  padding: 20px;
  border-top-left-radius: $box-radius;
  border-top-right-radius: $box-radius;
  cursor: pointer;

  &.usermgmt__listitem_question--delete {
    padding: 0;
  }
}

.usermgmt__listitem_question--visible {
  background-color: $grauweiss;
  border-bottom: 1px solid $bordergrau;
}

.usermgmt__listitem_question-header {
  display: table-cell;
  width: calc(100% - 18px);
  margin: 0;
  padding: 0 10px 0 0;
  text-align: left;
  vertical-align: middle;
  font-family: $font_content_bold;
  font-size: 18px;
  line-height: 18px;
  color: $dunkelblau;
}

.usermgmt__listitem_question-header--delete {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: $errorcolor;
  padding: 20px;
}

.usermgmt__listitem_question-action {
  display: table-cell;
  width: 18px;
  text-align: left;
  vertical-align: middle;
}

.usermgmt__listitem_question-icon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../../images/usermgmt-is-closed.svg);
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: center center;
}

.usermgmt__listitem_question-icon--open {
  background-image: url(../../images/usermgmt-is-open.svg);
}

.usermgmt__listitem--logout {
  list-style-type: none;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-bottom: 25px;
  background-color: #ffffff;
}

.usermgmt__listitem_question--logout {
  display: table;
  width: 100%;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.usermgmt__listitem_question-header--logout {
  margin: 0 auto;
  text-align: center;
  color: #022856;
  font-weight: bold;
}

.usermgmt__listitem_answer {
  display: none;
  height: 0;
  padding: 20px 20px 40px 20px;
  text-align: left;
  line-height: 18px;
  -webkit-transition: height 2s;
  transition: height 2s;
  border-bottom-left-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
}

.usermgmt__listitem_answer--visible {
  display: block;
  height: auto;
  -webkit-transition: height 2s;
  transition: height 2s;
}

.usermgmt__delete_header {
  font-size: 25px;
  margin: 0;
  margin-bottom: 25px;
}

.usermgmt__delete_confirmation {
  padding: $std-padding-mobile;
  border-radius: $box-radius;
  background-color: $weiss;
}

.mailbox__headline-text {
  color: $blau;
  font-size: 24px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.progress-bar__wrapper {
  text-align: center;
  width: 100%;
  padding: 77.5px 5% 35px;
  line-height: 20px;
}

.progress-bar__upload {
  width: 100%;
  padding: 3px;
  line-height: 20px;
  background: $grau;
  text-align: center;
  color: $weiss;
  border-radius: $box-radius;
  margin-top: 15px;
  font-family: $font_content_normal;
  font-size: 13px;

  .progress-bar__upload-progress {
    background-color: $gruen;
    border-radius: $box-radius;
    width: 50%;
  }
}

.grosse-sendungsmengen__header,
.grosse_sendung__header {
  padding-top: 15px;
}

.grosse_sendung__header__text {
  text-align: center;
}

.grosse_sendung__subheader.help__info {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
}

.grosse_sendungsmengen__section__header {
  font-size: 30px;
  text-align: center;
}

.availabilityinfo--desk {
  display: none;
}

@media screen and (min-width: 340px) {
  .payment__productinfo_listitem-desc {
    font-size: 16px;
  }

  .payment__productinfo_listitem-value {
    font-size: 18px;
  }
}

@media screen and (min-width: 360px) {
  .payment--cart {
    padding-bottom: 217px;
  }
}

@media screen and (min-width: 380px) {
  .payment__cartarticles_headeractionshort {
    display: none;
  }

  .payment__cartarticles_headeractionlong {
    display: inline;
  }
}

@media screen and (min-width: 392px) {
  .payment__choices--topinfo {
    padding-top: 211px;
  }

  .payment__choices--topinfohigher {
    padding-top: 232px;
  }
  .payment__choices--pcinfo {
    padding-top: 159px;
  }

  .payment__choices--pcinfohigher {
    padding-top: 180px;
  }
}

@media screen and (min-width: 400px) {
  .paymentchoices__choicelistitem-thumb {
    height: 30px;
    margin-left: 5px;
  }

  .paymentchoices__choicelistitem-left {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .paymentchoices__choicelistitem-availability {
    height: 30px;
    line-height: 30px;
  }
}

@media screen and (min-width: 450px) {
  .availabilityinfo--desk {
    display: inline;
  }
}

@media screen and (min-width: 668px) {
  .mailbox__empty > .text--mobile-line {
    display: inline;
  }
}

@media screen and (min-width: 768px) {
  .selected-label {
    color: #e4a600;
    font-size: smaller;
    margin-left: 0.5rem;
    font-weight: 800;
  }

  .progress-bar__wrapper {
    padding: 150px 5% 35px;
  }

  .upload__header {
    display: none;
  }

  .upload__section:first-child {
    padding-right: 15px;
  }

  .upload__section:last-child {
    padding-left: 15px;
  }

  .upload__section_header {
    display: block;
  }

  .upload__section_label {
    display: none;
  }

  .upload__section_type {
    display: inline;
  }

  .upload__section_download {
    margin-top: 20px;
    height: 225px;

    background-size: auto 110px;
    background-position: center 38px;
  }

  .upload__section_action {
    display: block;
  }

  .upload__formatinfo_mobile {
    display: none;
  }

  .upload__section_formatinfo {
    display: block;
  }

  .accountfooter__payment--mobile {
    display: none;
  }

  .accountfooter__payment--desktop {
    display: block;
  }

  .paymentchoices__choicelistitem-thumb {
    height: 40px;
  }

  .paymentchoices__choicelistitem-left {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }

  .paymentchoices__choicelistitem-availability {
    height: 40px;
    line-height: 40px;
  }

  .payment__overlay {
    background-image: url(../../images/payment-loading-150.png);
  }

  .spinner__overlay {
    background-size: 150px 150px;
  }

  .payment__content_successbox {
    margin-top: 70px;
  }

  .help__tab {
    position: relative;
    z-index: 2;
    height: 55px;
    width: 240px;
    font-size: 25px;
    line-height: 54px;
  }

  .help__tab--spacer {
    width: 10px;
  }

  .help__tab--fill {
    display: inline-block;
    width: calc(100% - 495px);
    background-color: transparent;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $bordergrau;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    vertical-align: bottom;
    margin-right: 5px;
  }

  .help__tab-mobilelabel {
    display: none;
  }

  .help__tab-desklabel {
    display: block;
    height: 54px;
    padding-top: 2.5px;
  }

  .help__content {
    position: relative;
    top: -1px;
    z-index: 1;
    border-top: 1px solid $bordergrau;
    border-top-right-radius: $box-radius;
  }

  .help__header {
    font-size: 25px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .grosse_sendungsmengen__section__header {
    font-size: 36px;
    text-align: center;
  }

  .grosse_sendung__header__text.help__info {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .grosse_sendung__subheader.help__info {
    margin-top: 30px;
    margin-bottom: 5px;
    font-size: 20px;
  }

  .help__subheader {
    text-align: center;
    margin-top: 5rem;
    color: #96c11f;
  }

  .help__info {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }

  .help__content_submittalbox {
    display: table;
    padding: 15px;
    margin: 25px 0 25px 0;
  }

  .help__content_submittalboxcell {
    display: table-cell;
    vertical-align: middle;
  }

  .help__content_submittalboxcell:first-child {
    width: calc(100% - 160px);
    padding-right: 25px;
    text-align: left;
  }

  .help__content_submittalboxcell:last-child {
    width: 350px;
    text-align: center;
  }

  .help__content_submittalheader {
    margin-top: 0;
    text-align: left;
  }

  .help__content_submittalboxcell .cta--download--doc {
    width: 100px;
  }

  .help__content_submittalboxcell .cta__wrapper--text {
    font-size: 15px;
  }

  .help__content_submittalinfo {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 0;
    text-align: left;
  }

  .usermgmt__headcell--header {
    display: table-cell;
  }

  .usermgmt__headcell--usernr,
  .usermgmt__headcell--search {
    width: 50%;
  }

  .usermgmt__headcell_usernr {
    float: right;
    max-width: 300px;
  }

  .form__formrow--smallwidth .form__row:nth-child(1) {
    width: 32%;
  }

  .form__formrow--smallwidth .form__row:nth-child(2) {
    width: 14%;
  }

  .form__formrow--smallwidth-register .form__row:nth-child(1) {
    width: 30%;
  }

  .form__formrow--smallwidth-register .form__row:nth-child(2) {
    width: 16%;
  }

  .form__formrow--smallwidth .form__row:last-child {
    padding-left: 27px !important;
  }

  .form__formrow--smallwidth-register .form__row:last-child {
    padding-left: 27px !important;
  }
}

@media screen and (min-width: 850px) {
  .mailbox__navigation--mobile {
    display: none;
  }

  .mailbox__block {
    box-shadow: none;
  }

  .mailbox__headline {
    display: inline-block;
    .headline-item-left {
      font-size: 16px;
    }
  }
  .headline-item-right {
    h1 {
      font-size: 26px;
      padding-top: 7px;
    }
  }
}

@media screen and (min-width: 1120px) {
  // 1030
  .payment__cartarticles_header {
    font-size: 32px;
  }

  .payment__cartaddress_header {
    height: 40px;
  }

  .payment__cartarticles_headeraction {
    font-size: 17px;
  }

  .form__formrow--smallwidth .form__row:nth-child(1) {
    width: 37%;
  }

  .form__formrow--smallwidth .form__row:nth-child(2) {
    width: 10%;
  }

  .form__formrow--smallwidth-register .form__row:nth-child(1) {
    width: 30%;
  }

  .form__formrow--smallwidth-register .form__row:nth-child(2) {
    width: 16%;
  }

  .payment--cart {
    padding: 25px 30px 25px 30px;
    min-height: calc(100vh - 287px);
  }

  .payment--cart .job__control {
    padding: 20px 15px;
  }

  .payment--pc {
    padding: 25px 30px 25px 30px;
    //min-height: calc(100vh - 287px);
  }

  .payment__header {
    margin-top: 0;
    padding: 8px 0 0 5px;
    text-align: left;
  }

  .payment__content {
    display: table;
    width: 100%;
    margin-top: 30px;
  }

  .payment__productbox {
    position: relative;
    width: 335px;
    box-shadow: none;
    border: none;
  }

  .payment__productbox,
  .payment__choices {
    display: table-cell;
  }

  .payment__productbox {
    float: right;
  }

  .payment__choices {
    float: left;
    width: calc(100% - 335px);
    padding: 0 30px 0 0;
  }

  .payment__choices.payment__choices--verify,
  .payment__choices.registered {
    padding-top: 0;
  }

  .payment__productinfo_mobile {
    display: none;
  }

  .payment__productinfo {
    padding: 15px 20px 30px 20px;
    border: 1px solid $grau;
    border-radius: $box-radius;
    box-shadow: none;
  }

  .payment__productinfo-info--desk {
    display: block;
  }

  .payment__productinfo_listitem-desc,
  .payment__productinfo_listitem-value {
    //font-size: 15px;
    line-height: 30px;
  }

  .payment__productinfo_listitem--strong .payment__productinfo_listitem-desc,
  .payment__productinfo_listitem--strong .payment__productinfo_listitem-value {
    font-size: 22px;
    line-height: 28px;
  }

  .payment__productinfo-info {
    margin-top: 20px;
    line-height: 18px;
  }

  .payment__productinfo-cta {
    display: block;
  }

  .payment__productinfo-cta--submit,
  .payment__productinfo-cta--ba2ba {
    margin-top: 20px;
  }

  .payment__productinfo-cta--ba2ba {
    font-size: 15px;
  }

  .payment__mobilesubmit {
    display: none;
  }
}
