.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background: linear-gradient(135deg, $blaugrau, $hellgruen);
  background-color: $blaugrau;
}

.sidenav__listwrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.sidenav__listbox {
  display: table-cell;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.sidenav__list {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}

.sidenav__listitem {
  width: 100%;
  margin: 0;
  list-style-type: none;
}

.sidenav__listitem--headeritem {
  display: table-cell;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  padding: 0;
  background-image: url(../../images/sidenav-triangle.svg),
    url(../../images/ebrief-logo-black001.svg);
  background-repeat: no-repeat;
  background-size:
    20px 50px,
    125px 21.05px;
  background-position:
    right top,
    center center;
}

.sidenav__listitem--buttomitem {
  margin-top: 20px;
}

.sidenav__link {
  color: $dunkelblau;
  font-family: $font_header_bold, $font_fallback;
  font-size: 18px;
  line-height: 45px;
  text-decoration: none;
}

.sidenav__button {
  display: inline-block;
  height: 40px;
  padding: 0 $std-padding-mobile 0 $std-padding-mobile;
  background-color: $weiss;
  border: 1px solid $weiss;
  border-radius: 5px;
  color: $gruen;
  font-family: $font_header_bold, $font_fallback;
  font-size: 18px;
  line-height: 40px;
  text-decoration: none;
  text-transform: uppercase;
}

.mainnav,
.accounthead {
  width: 100%;
  height: $nav-mobile-height;
  margin: 0 auto;
}

.mainnav {
  transition: top 0.5s ease-in-out;
  z-index: 99999998;
  max-width: 1680px;
  background-color: $weiss;
}

.accounthead {
  position: relative;
  z-index: 2;
  background-color: $accountblau;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.accounthead--admin {
  background-color: $admingrau;
}

.accounthead--noshadow {
  box-shadow: none;
}

.mainnav__list,
.accounthead__list {
  width: 100%;
  max-width: 1140px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}

.mainnav__list__live {
  border-bottom: thin solid #f1f5f9;
}

.accounthead__list {
  text-align: right;
}

.mainnav__list {
  display: table;
  padding-left: $std-padding-mobile;
  padding-right: $std-padding-mobile;
}

.accounthead__list {
  font-size: 0;
}

.mainnav__listitem {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  list-style-type: none;
}

.mainnav__listitem--menubutton,
.mainnav__listitem--userbutton {
  width: 22px;
}

.mainnav__listitem--leadbutton {
  width: 140px;
  height: 30px;
}

.mainnav__listitem--basket {
  width: 39px;
}

.mainnav__listitem--basket {
  overflow: visible;
}

.mainnav__listitem--user {
  width: 0;
}

.accounthead__listitem {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  list-style-type: none;
}

.accounthead__listitem--user {
  width: 0;
  overflow: hidden;
  text-align: center;
  line-height: 50px;
  background-color: $dunkelblau;
  background-image: url(../../images/account-triangle.svg);
  background-repeat: no-repeat;
  background-size: 20px 50px;
  background-position: right top;
  font-family: $font_content_bold;
  color: $weiss;
  font-size: 15px;
  text-transform: uppercase;
  transition: 0.5s;
}

.accounthead__listitem_logoutuser {
  padding-left: 20px;
  background-image: url(../../images/user-alt-logout.svg);
  background-repeat: no-repeat;
  background-size: 14px auto;
  background-position: left center;
}

.accounthead__listitem_logoutlink {
  font-family: $font_content_normal;
  color: $gruen;
}

.accounthead__listitem--logout {
  width: 42px;
  padding-left: 20px;
  display: none;
}

.accounthead__listitem--logo {
  width: calc(100% - 150px);
  padding: 16px 20px 16px 33px;
  text-align: center;
}

.accounthead__listitem--account {
  display: none;
  width: calc(100% - 245px);
  text-align: right;
}

.accounthead__listitem--userbutton {
  padding: 15px 10px;
}

.accounthead__listitem_account {
  display: inline-block;
  height: 100%;
  padding-left: 20px;
  font-family: $font_header_bold;
  font-size: 12px;
  line-height: 51px;
  color: $weiss;
  text-transform: uppercase;
  background-image: url(../../images/user-alt-account.svg);
  background-repeat: no-repeat;
  background-size: 14px auto;
  background-position: left center;
}

.anonymous-accounthead__listitem--user .accounthead__listitem_account {
  line-height: 23px;
}

.accounthead__logo {
  width: auto;
  height: 18px;
}

.accounthead__listitem--basket {
  width: 55px;
  padding: 11px 20px 11px 0;
  text-align: left;
}

.basket__icon {
  position: relative;
  display: inline-block;
  height: 28px;
  width: 35px;
  background-image: url(../../images/basket-white.svg);
  background-repeat: no-repeat;
  background-size: 30px auto;
  background-position: left bottom;
}

.basket__count-jobs {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $gruen;
  font-family: $font_header_normal;
  font-size: 12px;
  line-height: 16px;
  color: $weiss;
  text-align: center;
}

.basket__count-jobs--empty {
  background-color: $dunkelblau;
}

.basket__text {
  display: none;
  height: 100%;
  font-family: $font_header_bold;
  font-size: 12px;
  line-height: 32px;
  color: $gruen;
  vertical-align: top;
  text-transform: uppercase;
}

.basket__text--empty {
  color: $blau;
}

.menubutton {
  position: relative;
  width: 22px;
  height: 12px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.menubutton__rounded--rotate45 {
  transform: translate(0, 4px) rotate(45deg) scalex(1);
}

.menubutton__rounded--rotate135 {
  transform: translate(0px, -4px) rotate(-45deg) scalex(1);
  margin: 0;
}

.menubutton__rounded {
  height: 4px;
  margin-top: 4px;
  background-color: $dunkelblau;
  border: 1px solid $dunkelblau;
  border-radius: 2px;
  list-style-type: none;
}

.menubutton__rounded:first-child {
  margin-top: 0;
}

.menubutton__logout--back {
  background-image: url(../../images/login-white.svg);
}

.accountbutton {
  position: relative;
  width: 22px;
  height: 24px;
  margin: 0;
  padding: 13px 0 13px 0;
  cursor: pointer;
}

.accountbutton__logout {
  width: 22px;
  height: 24px;
  background-image: url(../../images/logout-white.svg);
  background-repeat: no-repeat;
  background-size: 22px auto;
  background-position: center center;
  list-style-type: none;
}

.accountbutton__logout--open {
  background-image: url(../../images/login-white.svg);
}

.mainnav__listitem--logo {
  padding: 0 20px 0 20px;
  text-align: center;
}

.mainnav__listitem--infologo {
  text-align: left;
}

.mainnav__listitem--logo-account {
  padding: 0 20px 0 33px;
  text-align: center;
}

.mainnav__logo {
  width: 110px;
  height: auto;
}

.mainnav__listitem--links {
  display: none;
}

.mainnav__linklist,
.mainnav__infolist {
  display: table;
  height: 18px;
  margin: 0 auto;
  padding: 0 10px 0 10px;
}

.mainnav__infolist {
  display: none;
}

.mainnav__linklistitem,
.mainnav__infolistitem {
  display: table-cell;
  height: 18px;
  padding: 0 10px 0 10px;
}

.mainnav__infolistitem {
  padding: 0 5px 0 5px;
}

.mainnav__link,
.mainnav__info {
  color: $dunkelblau;
  font-family: $font_header_bold, $font_fallback;
  font-size: 18px;
  text-decoration: none;
}

.mainnav__info {
  font-family: $font_header_normal, $font_fallback;
  font-size: 12px;
  text-transform: uppercase;
}

.mainnav__link:hover {
  color: $gruen;
}

.mainnav__listitem--certs {
  display: none;
  width: 150px;
}

.mainnav__certlist {
  display: table;
  height: 100px;
  padding: 0 17.5px 0 17.5px;
  margin: 0;
}

.mainnav__certitem {
  display: table-cell;
  height: 100px;
  padding: 0 7.5px 0 7.5px;
  vertical-align: middle;
}

.mainnav__cert {
  display: block;
}

.mainnav__cert--klimaneutral {
  width: 50px;
  height: 50px;
}

.mainnav__cert--qualitaetsmanagement {
  width: 36px;
  height: 50px;
}

.accountnav {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 87px;
  margin: 0 auto;
  background-color: $accountblau;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.accountnav--admin {
  background-color: $admingrau;
}

.accountnav__list {
  display: table;
  width: 431px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px 0 20px;
}

.accountnav__listitem {
  display: table-cell;
  height: 100%;
  padding-right: 30px;
  vertical-align: middle;
  list-style-type: none;
}

.accountnav__listitem:last-child {
  padding-right: 0;
}

.accountnav__link {
  display: block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: auto 30px;
  background-position: top center;
}

.accountnav__link--mailbox {
  min-width: 35px;
  background-image: url(../../images/letter-default.svg);
}

.accountnav__link--mailbox.accountnav__link--active {
  background-image: url(../../images/letter-white.svg);
}

.accountnav__link--mailbox:hover {
  background-image: url(../../images/letter-white.svg);
  color: $weiss;
}

.accountnav__link--mailbox:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--brief-schreiben {
  min-width: 35px;
  background-image: url(../../images/icon-brief-schreiben.svg);
}

.accountnav__link--brief-schreiben.accountnav__link--active {
  background-image: url(../../images/icon-brief-schreiben-white.svg);
}

.accountnav__link--brief-schreiben:hover {
  background-image: url(../../images/icon-brief-schreiben-white.svg);
  color: $weiss;
}

.accountnav__link--brief-schreiben:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--grosse-sendungsmengen {
  min-width: 35px;
  background-image: url(../../images/grosse-sendungsmengen-icon.svg);
}

.accountnav__link--grosse-sendungsmengen.accountnav__link--active {
  background-image: url(../../images/grosse-sendungsmengen-icon-white.svg);
}

.accountnav__link--grosse-sendungsmengen:hover {
  background-image: url(../../images/grosse-sendungsmengen-icon-white.svg);
  color: $weiss;
}

.accountnav__link--grosse-sendungsmengen:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--orders {
  min-width: 25px;
  background-image: url(../../images/mail-box-default.svg);
}

.accountnav__link--orders.accountnav__link--active {
  background-image: url(../../images/mail-box-white.svg);
}

.accountnav__link--orders:hover {
  background-image: url(../../images/mail-box-white.svg);
  color: $weiss;
}

.accountnav__link--orders:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--invoices {
  min-width: 31px;
  background-image: url(../../images/copy-invert-default.svg);
}

.accountnav__link--invoices:hover {
  background-image: url(../../images/copy-invert-white.svg);
  color: $weiss;
}

.accountnav__link--invoices:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--invoices.accountnav__link--active {
  background-image: url(../../images/copy-invert-white.svg);
}

.accountnav__link--faq {
  min-width: 36px;
  background-image: url(../../images/support-faq-default.svg);
}

.accountnav__link--faq.accountnav__link--active {
  background-image: url(../../images/support-faq-white.svg);
}

.accountnav__link--faq:hover {
  background-image: url(../../images/support-faq-white.svg);
  color: $weiss;
}

.accountnav__link--faq:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--vorlagen {
  min-width: 36px;
  background-image: url(../../images/support-vorlagen-default.svg);
}

.accountnav__link--vorlagen.accountnav__link--active {
  background-image: url(../../images/support-vorlagen-white.svg);
}

.accountnav__link--vorlagen:hover {
  background-image: url(../../images/support-vorlagen-white.svg);
  color: $weiss;
}

.accountnav__link--vorlagen:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link--account {
  min-width: 28px;
  background-image: url(../../images/user-account-default.svg);
}

.accountnav__link--account.accountnav__link--active {
  background-image: url(../../images/user-account-white.svg);
}

.accountnav__link--account:hover {
  background-image: url(../../images/user-account-white.svg);
  color: $weiss;
}

.accountnav__link--user {
  min-width: 28px;
  background-image: url(../../images/user-admin-default.svg);
}

.accountnav__link--user.accountnav__link--active {
  background-image: url(../../images/user-account-white.svg);
}

.accountnav__link--user:hover {
  background-image: url(../../images/user-account-white.svg);
  color: $weiss;
}

.accountnav__link--account:hover > .accountnav__link-text {
  color: $weiss;
}

.accountnav__link-saparator {
  background-color: #82a5c7;
  border-left: 1px solid #82a5c7;
  height: 40px;
  display: inline-block;
}

.accountnav__link-text {
  font-family: $font_header_bold;
  font-size: 12px;
  line-height: 12px;
  color: $mittelblau;
  vertical-align: bottom;
}

.accountnav__link-text--admin {
  color: $grau;
}

.accountnav__link--user:hover > .accountnav__link-text,
.accountnav__link--active > .accountnav__link-text {
  color: $weiss;
}

.useraccount {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: transparent;
  overflow-y: scroll;
}

.useraccount__bracket {
  position: relative;
  height: 100%;
  min-height: 568px;
  max-height: 812px;
}

.useraccount__arrow {
  position: fixed;
  top: 0;
  left: 62px;
  z-index: 2;
  height: 50px;
  padding: 0;
  background-image: url(../../images/useraccount-triangle.svg);
  background-repeat: no-repeat;
  background-size: 20px 50px;
  background-position: left top;
}

.useraccount__logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 50px;
  padding: 0;
  background-image: url(../../images/ebrief-logo-black001.svg);
  background-repeat: no-repeat;
  background-size: 125px 21.05px;
  background-position: center center;
}

.useraccount__bubble {
  width: 100%;
  height: 100%;
  min-height: 568px;
  padding: 80px 25px 20px 25px;
  background-color: $gruen;
}

.useraccount__header {
  width: 100%;
  color: $weiss;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.useraccount__resetpassword {
  text-align: center;
  font-size: 12px;
}

.useraccount__resetpassword-link {
  font-size: 12px;
  color: $neongruen;
  text-decoration: none;
  text-transform: uppercase;
}

.useraccount__newuser {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 38%;
  background-color: rgba(0, 0, 0, 0.04);
}

.useraccount__newuser-slunt {
  height: 0;
  width: 100vw;
  position: relative;
  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    content: "";
    height: 0;
    width: 0;
    border-right: 100vw solid $gruen;
    border-bottom: 16vw solid transparent;
    border-top: 0 solid transparent;
    overflow: hidden;
  }
}

.useraccount__newuser-content {
  display: table;
  width: 100%;
  height: 100%;
  padding-top: 16%;
}

.useraccount__newuser-contentcell {
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.useraccount__newuser-header {
  margin-bottom: 15px;
  font-size: 18px;
  color: $weiss;
  text-align: center;
  text-transform: uppercase;
}

.useraccount__newuser-text {
  text-align: center;
  font-size: 18px;
}

.useraccount__newuser-link {
  position: relative;
  z-index: 2147483646;
  font-size: 18px;
  color: $neongruen;
  text-decoration: none;
}

.breadcrumb {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: $font_content_normal;
  font-size: 0;
  color: $weiss;
  text-align: left;
}

.breadcrumb__item {
  display: inline-block;
  padding-right: 3px;
  font-size: 12px;
  text-transform: uppercase;
}

.breadcrumb__link {
  color: $gruen;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .mainnav {
    height: $nav-desktop-height;
  }

  .mainnav--up {
    top: -$nav-desktop-height;
  }

  .mainnav__list,
  .accounthead__list {
    padding: 0 30px 0 30px;
  }

  .mainnav__listitem--links {
    display: table-cell;
  }

  .mainnav__listitem--menubutton {
    display: none;
  }

  .mainnav__listitem--logo,
  .mainnav__listitem--infologo {
    width: 150px;
    padding: 0;
    text-align: left;
  }

  .mainnav__listitem--leadbutton {
    width: 195px;
    height: 40px;
    text-align: right;
  }

  .mainnav__logo {
    width: 150px;
  }

  .useraccount {
    position: absolute;
    z-index: 999999;
    width: 250px;
    height: 0;
    padding-top: 10px;
    transition: height 0.5s ease;
    overflow: hidden;
  }

  .useraccount__bracket {
    height: auto;
    min-height: initial;
    max-height: initial;
  }

  .useraccount__arrow {
    position: relative;
    left: 0;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-right: 18px solid transparent;
    border-left: 18px solid transparent;
    border-bottom: 16px solid $gruen;
    background-image: none;
  }

  .useraccount__logo {
    display: none;
  }

  .useraccount__bubble {
    min-height: initial;
    padding: 20px 17px 10px 17px;
    border-top-left-radius: $box-radius;
    border-top-right-radius: $box-radius;
  }

  .useraccount__newuser {
    position: relative;
    height: 150px;
    background-color: $gruen;
    background-image: url(../../images/polygon-login-desk.svg);
    background-repeat: no-repeat;
    background-size: 250px 148px;
    background-position: center bottom;
    border-bottom-left-radius: $box-radius;
    border-bottom-right-radius: $box-radius;
  }

  .accounthead__listitem--logout {
    display: none;
  }

  .accounthead__listitem--logo,
  .accounthead__listitem--infologo {
    width: 110px;
    padding: 16px 0 16px 0;
    text-align: left;
  }

  .accounthead__listitem--account {
    display: inline-block;
  }

  .accounthead__listitem--userbutton {
    width: calc(100% - 265px);
    text-align: right;
  }

  .accounthead__listitem--basket {
    width: 135px;
    padding: 10px 0 10px 20px;
  }

  .basket {
    display: inline-block;
    width: 115px;
    height: 30px;
    border-radius: $box-radius;
    background-color: $weiss;
  }

  .anonymous-accounthead__listitem--user .accounthead__listitem_account {
    line-height: 40px;
  }

  .basket__icon {
    height: 23px;
    width: 26px;
    margin: 3px 6px 5px 10px;
    background-image: url(../../images/basket-grey.svg);
    background-size: 20px auto;
  }

  .basket__text {
    display: inline-block;
  }
}

@media screen and (min-width: 895px) {
  .mainnav__listitem--userbutton {
    width: 120px;
    height: 40px;
  }

  .accounthead__listitem--userbutton {
    padding: 5px 0;
  }

  .anonymous-accounthead__listitem--userbutton {
    padding: 10px 0 10px 20px;
  }

  .mainnav__listitem--logo,
  .mainnav__listitem--infologo {
    width: 170px;
  }

  .mainnav__logo {
    width: 170px;
  }
}

@media screen and (min-width: 824px) {
  .mainnav__linklistitem {
    padding: 0 15px 0 15px;
  }
}

@media screen and (min-width: 890px) {
  .mainnav__linklistitem {
    padding: 0 10px 0 10px;
  }
}

@media screen and (min-width: 910px) {
  .mainnav__infolist {
    display: table;
    text-wrap: nowrap;
  }
}

@media screen and (min-width: 940px) {
  .mainnav__infolistitem {
    padding: 0 10px 0 10px;
  }
}

@media screen and (min-width: 990px) {
  .mainnav__linklistitem,
  .mainnav__infolistitem {
    padding: 0 20px 0 20px;
  }

  .mainnav__infolistitem {
    padding: 0 20px 0 20px;
  }

  .mainnav__listitem--links--small .mainnav__infolistitem {
    padding: 0 10px 0 10px;
  }
}

@media screen and (min-width: 1130px) {
  .mainnav__listitem--certs {
    display: table-cell;
  }

  .mainnav__list__live {
    border-bottom: none;
  }

  .mainnav.mainnav__underline {
    border-bottom: thin solid #f1f5f9;
  }
}
