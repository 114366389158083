.notice {
  margin-top: 40px;
  padding: 20px;
  font-family: $font_content_normal;
  color: $dunkelblau;
  text-align: left;
  border-radius: $box-radius;
}

.notice p {
  margin-bottom: 20px;
}

.notice p:last-child {
  margin-bottom: 0;
}

.notice--lightgreen {
  background-color: $hellgruen;
}

.notice--modal {
  max-width: 640px;
}

.notice__title {
  font-family: $font_header_bold;
  line-height: 32px;
  text-transform: uppercase;
}

.notice__titlehint {
  display: inline-block;
  margin-right: 15px;
  padding: 0 30px 0 30px;
  color: $weiss;
  border-radius: $box-radius;
  background-color: $gruen;
}
