@mixin triangle-blue-doubleup() {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 1680px;
  height: 74px;
  background-image: url(../../images/triangle-blue-doubleup.svg);
  background-repeat: no-repeat;
  background-size: 1680px 76px;
  background-position: center bottom -2px;
}

@mixin triangle-lightgrey-doubleup() {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 1680px;
  height: 74px;
  background-image: url(../../images/triangle-lightgrey-doubleup.svg);
  background-repeat: no-repeat;
  background-size: 1680px 76px;
  background-position: center bottom -2px;
}

@mixin triangle-lightgrey-down() {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1680px;
  height: 74px;
  background-image: url(../../images/triangle-lightgrey-down.svg);
  background-repeat: no-repeat;
  background-size: 1680px 74px;
  background-position: center top;
}

@mixin triangle-blue-down() {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1680px;
  height: 74px;
  background-image: url(../../images/triangle-blue-down.svg);
  background-repeat: no-repeat;
  background-size: 1680px 74px;
  background-position: center top;
}

@mixin gradient-dark() {
  background: linear-gradient(135deg, $blau 33.3333%, $verlaufgruen 166.6666%);
  background-color: $blau;
}

@mixin gradient-light() {
  background: linear-gradient(135deg, $blaugrau 33.3333%, $hellgruen 166.6666%);
  background-color: $blaugrau;
}
