.form__block {
  display: table;
  width: 100%;
  padding-bottom: 10px;
}

.form__fieldset {
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 20px 0 10px 0;
  border: none;
  border-top: 1px solid $bordergrau;
}

.form__fieldset--mandat {
  margin-bottom: 20px;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid $bordergrau;
}

.form__fieldset--cbw {
  margin-bottom: 0;
  padding-bottom: 0;
}

.form__fieldset--reg-sbm {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 0;
}

.form__fieldset--reg-mb50 {
  margin-bottom: 40px;
}

.form__fieldset--plane {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 0 0;
  border-top: none;
}

.form__fieldset-legend {
  position: absolute;
  top: -8px;
  left: 0;
  background-color: $weiss;
  padding-right: 5px;
  font-family: $font_content_normal;
  font-size: 12px;
  color: $bordergrau;
  text-transform: uppercase;
}

.form__legend,
.form__appendinfo {
  font-family: $font_content_normal;
  font-size: 14px;
}

.form__appendinfo--right {
  text-align: right;
}

.form__legend {
  margin-bottom: 10px;
}

.form__row {
  margin-bottom: $std-padding-mobile;
  text-align: left;
  &.--right {
    padding-right: 15px;
  }
}

.form__formrow--left {
  text-align: left;
}

.form__formrow--cbw,
.form__formrow--semiwidth,
.form__formrow--mandat {
  display: table;
  width: 100%;
}

.form__formrow--cbw,
.form__formrow--semiwidth {
  margin-bottom: 20px;
}

.form__formrow--cbw > .form__row,
.form__formrow--semiwidth > .form__row {
  display: table-cell;
  vertical-align: middle;
}

.form__formrow--cbw > .form__row:first-child {
  width: 45px;
}

.form__formrow--cbw > .form__row:nth-child(2) {
  width: auto;
}

.form__formrow--semiwidth > .form__row {
  width: 50%;
}

.form__formrow--semiwidth > .form__row:first-child {
  padding-right: 7px;
}

.form__formrow--semiwidth > .form__row:nth-child(2) {
  padding-left: 7px;
}

.form__formrow--contacttime {
  position: relative;
  top: -20px;
  padding-top: 10px;
}

.form__formrow--contacttime .form__label,
.form__formrow--contacttime .form__pinradiowidget > label {
  padding-right: 30px;
}

.form__formrow--contacttime .form__pinradiowidget > label {
  font-family: $font_content_normal;
}

.form__row--last {
  margin-bottom: 0;
}

.form__row--mini {
  margin-bottom: 15px;
}

.form__row--pseudo-label {
  font-family: $font_content_normal, $font_fallback;
  font-size: 15px;
  padding-left: 0 !important;
}

.form__row--fz18 {
  font-size: 18px;
}

.form__row--w100 {
  width: 100% !important;
}

.form__row--lowerlh {
  line-height: 12px;
}

.form__label {
  display: block;
  padding-bottom: 10px;
  font-family: $font_content_bold;
  font-size: 15px;
  text-align: left;
}

.form__label--inline {
  display: inline;
  padding-bottom: 0;
  padding-right: 10px;
  font-family: $font_content_bold;
  font-size: 15px;
  text-align: left;
}

.form__label--pagination {
  display: none;
  font-family: $font_content_normal;
  font-size: 14px;
  line-height: 24px;
  color: $dunkelgrau;
}

.form__label--left {
  float: left;
  padding-bottom: 0;
}

.form__label--fontnormal {
  font-family: $font_content_normal;
}

.form__label--blue {
  color: $blau;
}

.form__label--darkblue {
  color: $dunkelblau;
}

.form__label--contacttime {
  //float: left;
  padding-bottom: 5px;
  font-family: $font_content_normal;
  line-height: $form-radio-medium-diameter;
}

.form__label-fwn {
  font-family: $font_content_normal;
}

.form__minilabel {
  display: none;
}

.form__field {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: $box-radius;
  font-family: $font_content_normal;
  font-size: 18px;
  line-height: 20px;
  background-color: $hellgrau;
  color: $blau;
}

.form__field:focus {
  outline: none;
}

.form__field--opmplaceholder::placeholder {
  font-family: $font_content_italic;
  font-size: 15px;
  line-height: 20px;
  color: $opmleadtext;
}

.form__minifield {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: $box-radius;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 20px;
}

.form__minifield--grey {
  background-color: rgba(0, 0, 0, 0.12);
  color: $weiss;
}

.form__minifield--grey::placeholder {
  opacity: 0.4;
  color: $weiss;
  font-family: $font_content_italic;
}

.form__field--textarea {
  height: 225px;
}

.form__field--textarealow {
  height: 125px;
}

.form__field--textarea-address {
  width: 90%;
  height: 8rem !important;
  line-height: 22px !important;
  padding-top: 0.8em !important;
  resize: none;
}

.form__hide {
  display: none;
}

.form__field--max240 {
  max-width: 240px;
}

.filter__field {
  //display: inline-block;
  //width: 100%;
  padding: 5px 5px 5px 10px;
  border: none;
  border-top-left-radius: $box-radius;
  border-bottom-left-radius: $box-radius;
  font-family: $font_content_normal;
  font-size: 16px;
  line-height: 16px;
  background-color: $weiss;
  color: $blau;
}

.filter__field::placeholder {
  font-family: $font_content_italic;
  font-size: 14px;
  line-height: 16px;
  color: $mittelgrau;
}

.filter__clear {
  display: block;
  width: 40px;
  height: 30px;
  border: none;
  border-top-right-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
  background-color: $weiss;
  background-image: url("../../images/commissionfilter.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
}

.filter__clear--activ {
  background-image: url("../../images/filterclear.svg");
}

.form__row--error > .form__field,
.form__table-cell--error .form__field {
  border: 1px solid $errorcolor;
  background-color: $errorbackground; // rgba(198, 62, 40, .12)
  color: $errorcolor;
}

.form__row--error > .form__label,
.form__table-cell--error .form__table-label {
  color: $errorcolor;
}

.form__select {
  display: block;
  cursor: pointer;
  font-size: 18px;
  font-family: $font_content_normal;
  font-weight: normal;
  color: $blau;
  line-height: 38px;
  padding: 0 22.5px 0 13px;
  width: 100%;
  height: 40px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $bordergrau;
  box-shadow: none;
  border-radius: $box-radius;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $weiss;
  background-image: url("../../images/select-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 11.2px top 50%, 0 0;
  background-size: 15px auto, 100%;
}

.form__select::-ms-expand {
  display: none;
}

.form__select:hover {
  border-color: $bordergrau;
}
.form__select:focus {
  border-color: $bordergrau;
  box-shadow: 0 0 1px 2px rgba(216, 216, 216, 0.7);
  box-shadow: 0 0 0 2px -moz-mac-focusring;
  color: $blau;
  outline: none;
}

.form__select option {
  font-weight: normal;
  border-color: $bordergrau;
  background-color: $weiss;
}

select:invalid {
  color: $bordergrau;
  font-size: 15px;
}

select:invalid option {
  color: $blau;
  font-size: 18px;
}

.form__select--cardtype {
  max-width: 240px;
}

.form__select--userhelp {
  height: 50px;
  line-height: 48px;
  font-size: 15px;
  padding: 0 27.5px 0 12.5px;
  background-position: right 12.5px top 51%;
}

//.form__select:disabled, .form__select[aria-disabled=true] {
//  color: red;
//  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
//  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
//}

//.form__select:disabled:hover, .form__select[aria-disabled=true] {
//  border-color: red;
//}

.form__row--error > .form__select {
  border: 1px solid $errorcolor;
  background-color: $errorbackground;
  background-image: url("../../images/select-arrow-error.svg");
}

.form__row--error > .form__select:focus {
  box-shadow: 0 0 1px 2px rgba(198, 62, 40, 0.7);
  box-shadow: 0 0 0 2px -moz-mac-focusring;
}

.form__row--error > .form__select:invalid {
  color: $errorcolor;
}

.form__row--error > .form__select:invalid {
  color: $errorcolor;
}

.form__row--error > .form__select:invalid option {
  color: $blau;
}

.form__pincheckbox {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  &.--disabled {
    opacity: 0.5;
  }
}

.form__pincheckbox--pointer {
  cursor: pointer;
}

.form__pincheckbox--oneline {
  display: flex;
  align-items: center;

  .form__pincheckbox-border {
    width: auto;
    min-width: 25px;
  }

  @media screen and (min-width: 768px) {
    .form__pincheckbox-label {
      line-height: 1.3;
    }
  }
}

.form__pincheckbox--barcodefixing {
}

.form__pincheckbox-input {
  display: none;
}

.form__pincheckbox-border {
  display: inline-block;
  border-radius: $box-radius;
  border: 1px solid $bordergrau;
  width: 25px;
  height: 25px;
  background-color: $weiss;
  vertical-align: top;
  margin: 0 7.5px 0 0;
  position: relative;
  font-family: "sans-serif";
}

.form__pincheckbox--barcodefixing .form__pincheckbox-border {
  border-color: $jobwarningcolor;
}

.form__pincheckbox--barcodefixing :checked + .form__pincheckbox-border {
  border-color: $gruen;
}

.form__row--error > .form__pincheckbox > .form__pincheckbox-border,
.form__table-cell--error .form__pincheckbox > .form__pincheckbox-border {
  border: 1px solid $errorcolor;
  background-color: $errorbackground;
}

.form__pincheckbox-label {
  display: inline-block;
  font-family: $font_content_normal;
  font-size: 15px;
  border: none;
  width: auto;
  height: auto;
  background-color: transparent;
  margin: 0;
}

.form__pincheckbox-label--colorblue {
  color: $blau;
}

.form__pincheckbox--barcodefixing .form__pincheckbox-label {
  color: $jobwarningcolor;
}

.form__pincheckbox--barcodefixing :checked ~ .form__pincheckbox-label {
  color: $gruen;
}

.form__pincheckbox :checked + .form__pincheckbox-border::after {
  content: "\f00e"; // \2714
  font-family: $font_ico;
  font-size: 22px;
  position: absolute;
  top: -1px;
  left: 0;
  color: $gruen;
}

.form__pincheckbox--white .form__pincheckbox-border {
  border-color: $weiss;
}

.form__pincheckbox--white .form__pincheckbox-label {
  color: $neongruen;
}

.form__pincheckbox--blue .form__pincheckbox-label {
  color: $blau;
}

// radio
.form__pinradiogroup {
  display: table;
  width: 100%;
}

.form__pinradio {
  height: $form-radio-medium-diameter;
}

.form__pinradiowidget {
  display: inline;
  position: relative;
  float: left;
  height: $form-radio-medium-diameter;
}

.form__pinradioinput {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999999999999999;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.form__pinradio .form__pinradiolabel,
.paymentchoices__choicelist .form__pinradiolabel {
  position: relative;
  z-index: 9;
  //display: block;
  height: $form-radio-medium-diameter;
  padding: 0 5px 0 25px;
  font-family: $font_content_bold;
  color: $dunkelblau;
  font-size: 15px;
  line-height: $form-radio-medium-diameter;
  cursor: pointer;
}

.form__pinradiocheck {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: $form-radio-medium-diameter;
  height: $form-radio-medium-diameter;
  background-image: url("../../images/form-pinradio-unchecked-medium.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.form__pinradio input[type="radio"]:checked ~ .form__pinradiocheck,
.paymentchoices__choicelist input[type="radio"]:checked ~ .form__pinradiocheck {
  background-image: url("../../images/form-pinradio-checked-medium.svg");
}

.form__pinradiowidget:last-child .form__pinradiolabel {
  padding-right: 0;
}

.form__pinradio--pointer .form__pinradiowidget .form__pinradiolabel {
  cursor: pointer;
}

.form__pinradio--register .form__pinradiowidget .form__pinradiolabel {
  font-stretch: ultra-condensed;
  padding: 0 3px 0 23px;
}

.form__pinradiowidget .form__pinradiocheck.has_error {
  background-image: url("../../images/form-pinradio-error-medium.svg");
}

.form__error-wrapper {
  text-align: left;
}

.form__error-wrapper--center {
  text-align: center;
}

.form__errors {
  margin-top: $std-padding-mobile;
  padding-left: 20px;
  background-image: url(../../images/warning-errored.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: left center;
  color: $blau;
  &.--top {
    margin-bottom: $std-padding-mobile;
  }
}

.form__error-wrapper--center > .form__errors,
.form__table-row--pcc-error > .form__errors {
  display: inline-block;
}

.form__table-row--pcc-error > .form__errors {
  margin: 0 auto;
  margin-top: 10px;
}

.form__errorlist {
  margin: 0;
  padding: 0;
}

.form__erroritem {
  list-style-type: none;
  margin-top: $std-padding-mobile;
}

.form__errorlist--top .form__erroritem {
  margin-top: 0;
  margin-bottom: $std-padding-mobile;
}

.form__errormessage {
  display: inline-block;
  padding-left: 20px;
  background-image: url(../../images/warning-errored.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: left center;
  font-family: $font_content_normal;
  font-size: 15px;
}

.form__errorbubble {
  width: 100%;
  margin: 0;
  padding: 15px 0 15px 0;
  text-align: center;
}

.form__errorbubble-item {
  list-style-type: none;
  margin-bottom: 15px;
}

.form__errorbubble-item:last-child {
  margin-bottom: 0;
}

.form__errorbubble-item > .form__errors {
  display: inline-block;
  margin: 0 auto;
}

.form__info {
  padding-bottom: 15px;
  color: $errorcolor;
}

.form__select--modalcontrol {
  width: 240px;
}

.form__select--pagination {
  display: inline-block;
  width: auto;
  min-width: 60px;
  height: 24px;
  padding: 0 22.5px 0 10px;
  color: $dunkelgrau;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  border: 1px solid $dunkelgrau; // $bordergrau
  background-image: url("../../images/select-pagination.svg");
  background-repeat: no-repeat;
  background-position: right 7.5px center;
  background-size: 9.53307px 7.2px;
}

.form__select--pagination:focus {
  border-color: $bordergrau;
  box-shadow: none;
  color: $blau;
  outline: none;
}

.form__table {
  display: table;
  width: 100%;
  max-width: 580px;
  padding: 0;
  margin: 0;
  text-align: left;
}

.form__table--action {
  margin-top: 20px;
}

.form__table-row {
  display: table-row;
  list-style-type: none;
}

.form__table-cell {
  display: table-cell;
  width: 100%;
  padding-bottom: 15px;
}

.form__table-label {
  display: block;
  padding-bottom: 5px;
  font-family: $font_content_normal;
  font-size: 15px;
  color: $dunkelblau;
}

.form__table-cell--halfwidth {
  display: block;
  width: 100%;
}

.form__table-cell--halfwidth:first-child {
  padding-bottom: 15px;
}

.form__table-cell--ba2pcwrapper {
  padding-top: 15px;
  text-align: center;
}

.form__table-cell--plz > .form__field {
  width: 70px;
}

//jquery.dd.js
.ddcommon {
  position: relative;
  display: -moz-inline-stack;
  zoom: 1;
  display: inline-block;
  *display: inline;
  cursor: default;
}

.ddcommon ul {
  padding: 0;
  margin: 0;
}

.ddcommon ul li {
  list-style-type: none;
}

.dd {
  font-size: 15px;
  font-family: $font_content_normal;
  font-weight: normal;
  color: $gruen;
  line-height: 38px;
  padding: 0 1.4em 0 0.8em;
  height: 40px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $gruen;
  border-radius: $box-radius;
  background-color: $weiss;
}

.dd .divider {
  display: none;
}

.dd .ddArrow {
  display: inline-block;
  position: absolute;
  right: 0.7em;
  width: 15px;
  height: 38px;
  background-image: url("../../images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 0 top 50%;
  background-size: 15px auto;
}

.dd .ddTitle .ddTitleText {
  display: inline-block;
  width: 100%;
  height: 38px;
  line-height: 38px;
  padding: 0 20px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// overrides ms
._msddli_ {
  height: fit-content !important;
}

.dd .ddTitle .ddTitleText .ddTitleText {
  padding: 0;
}

.dd .ddTitle .ddTitleText img {
  padding-right: 20px;
  vertical-align: middle;
}

.dd .ddChild {
  width: 100%;
  border: 1px solid $bordergrau;
  border-top-color: $gruen;
  background-color: #fff;
  left: 0;
}

.dd .ddChild li {
  display: inline-block;
  width: 100%;
  height: 38px;
  line-height: 38px;
  padding: 0 20px 0 0.8em;
  color: $blau;
  background-color: #fff;
  border-bottom: 1px solid $bordergrau;
}

.dd .ddChild li.hover {
  background-color: $hellgrau;
  color: $gruen;
}
.dd .ddChild li img {
  padding-right: 20px;
  vertical-align: middle;
}

.dd .ddChild li.selected {
  background-color: $hellgrau;
  color: $gruen;
}

.form__splitter {
  display: table;
  width: 100%;
}

.form__splitter-left,
.form__splitter-right {
  display: table-row;
}

.form__buttonrow {
  display: table;
  width: 100%;
}

.form__buttoncell {
  display: table-cell;
  width: 50%;
}

.form__buttoncell--left {
  padding-right: 10px;
}

.form__buttoncell--left button {
  float: right;
}

.form__buttoncell--right {
  padding-left: 10px;
}

.form__buttoncell--right button {
  float: left;
}

#fast-privacy-form {
  text-align: left;
}

#fast-privacy-btn {
  color: $blau;
}

@media screen and (min-width: 580px) {
  .payment__choiceheader-mobileline {
    display: inline;
  }

  .form__table {
    margin: 0 auto;
  }

  .form__table--action {
    margin-top: 20px;
  }

  .form__table-halfwidth {
    display: table;
    width: 100%;
  }

  .form__table-cell--halfwidth {
    display: table-cell;
    width: 50%;
    padding-right: 10px;
    padding-bottom: 0;
  }

  .form__table-cell--halfwidth:first-child {
    padding-bottom: 0;
  }

  .form__table-cell--halfwidth:last-child {
    padding-left: 10px;
    padding-right: 0;
  }

  .form__splitter--580 > .form__splitter-left,
  .form__splitter--580 > .form__splitter-right {
    display: table-cell;
    width: 50%;
  }

  .form__splitter--580 > .form__splitter-left {
    padding-right: 15px;
  }

  .form__splitter--580 > .form__splitter-right {
    padding-left: 15px;
  }

  .form__row {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (min-width: 328px) {
  .form__pinradio--register .form__pinradiowidget .form__pinradiolabel {
    font-stretch: normal;
    padding: 0 5px 0 25px;
  }
}

@media screen and (min-width: 385px) {
  .form__label--pagination {
    display: inline;
  }
  .form__field--textarea-address {
    width: 100%;
    padding-right: 0;
  }

  .form__validation_error {
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .form__fieldset {
    margin-top: 20px;
    padding: 30px 0 10px 0;
  }

  .form__fieldset--mandat {
    margin-bottom: 20px;
    padding: 30px 0 30px 0;
    border-bottom: 1px solid $bordergrau;
  }

  .form__fieldset--plane {
    margin-top: 0;
    padding: 0 0 0 0;
  }

  .form__fieldset--reg-sbm {
    padding-top: 50px;
  }

  .form__row {
    margin-bottom: $std-padding-desktop;
  }

  .form__formrow {
    display: table;
    width: 100%;
    margin-bottom: $std-padding-desktop;
  }

  .form__fieldset--plane > .form__formrow {
    margin-bottom: 25px;
  }

  .form__formrow--cbw {
    margin-bottom: 0;
  }

  .form__formrow--semiwidth {
    width: calc(50% - 15px);
  }

  .form__formrow--last {
    margin-bottom: 0;
  }

  .form__formrow--w-auto {
    width: auto;
  }

  .form__row--deskleft {
    padding-left: 0 !important;
    text-align: left;
  }

  .form__formrow > .form__row {
    display: table-cell;
    //vertical-align: middle;
    width: 50%;
    margin-bottom: 0;
  }

  .form__formrow > .form__row.form__wide {
    width: 100%;
    margin-bottom: 25px;
  }

  .form__formrow--w-auto > .form__row {
    width: auto;
  }

  .form__formrow > .form__row:first-child {
    padding-right: 15px;
  }

  .form__formrow > .form__row:last-child {
    padding-left: 15px;
  }

  .form__formrow > .form__row:only-child {
    padding-left: 0;
  }

  .form__fieldset--plane > .form__formrow > .form__row:first-child {
    padding-right: 0;
  }

  .form__fieldset--plane > .form__formrow > .form__row:last-child {
    padding-left: 0;
  }

  .form__label--fz18 {
    font-size: 18px;
  }

  .form__pincheckbox--fz18 .form__pincheckbox-label {
    font-size: 18px;
  }

  .form__errors {
    margin-top: $std-padding-desktop;
    &.--top {
      margin-bottom: $std-padding-mobile;
    }
  }

  .form__pinradio--enlarge {
    height: $form-radio-large-diameter;
  }

  .form__pinradio--enlarge .form__pinradiowidget {
    height: $form-radio-large-diameter;
  }

  .form__pinradio--enlarge .form__pinradiowidget .form__pinradiolabel,
  .form__pinradio--register .form__pinradiowidget .form__pinradiolabel {
    height: $form-radio-large-diameter;
    padding: 0 15px 0 35px;
    //padding-left: 35px;
    //margin-right: 15px;
    font-size: 18px;
    line-height: $form-radio-large-diameter;
  }

  .form__pinradio--enlarge .form__pinradiocheck {
    width: $form-radio-large-diameter;
    height: $form-radio-large-diameter;
    background-image: url("../../images/form-pinradio-unchecked-large.svg");
  }

  .form__pinradio--enlarge input[type="radio"]:checked ~ .form__pinradiocheck {
    background-image: url("../../images/form-pinradio-checked-large.svg");
  }

  .form__pinradio--enlarge
    .form__pinradiowidget
    .form__pinradiocheck.has_error {
    background-image: url("../../images/form-pinradio-error-large.svg");
  }

  .payment__choicecontent {
    padding: $std-padding-desktop;
  }

  .form__select--userhelp {
    height: 54px;
    line-height: 52px;
    font-size: 18px;
    padding: 0 37.5px 0 20px;
    background-position: right 22.5px top 51%;
  }
}

.form__formrow--single > .form__row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
