.adminfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background-color: $admingrau;
}

.adminfooter__linklist {
  padding: 0 $std-padding-mobile 0 $std-padding-mobile;
  margin: 0 auto;
  margin-top: 1px;
  text-align: center;
  font-size: 0;
  height: 35px;
}

.adminfooter__listitem {
  display: inline-block;
  list-style-type: none;
  padding-right: $std-padding-mobile;
}

.adminfooter__listitem:last-child {
  padding-right: 0;
}

.adminfooter__listitem_link {
  font-family: $font_header_normal;
  font-size: 12px;
  line-height: 34px;
  color: $weiss;
}

.adminfooter__listitem_link--desk {
  display: none;
}

.admin__header {
  font-size: 25px;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.admin__userbox {
  width: 100%;
}

.admin__userbox_search {
  margin: 35px 0 35px 0;
  text-align: center;
}

.admin__userbox_searchlabel {
  display: block;
  width: 100%;
  padding-bottom: 10px;
  font-family: $font_content_bold;
  font-size: 15px;
  text-align: center;
  color: $dunkelblau;
}

.admin__userbox_searchfield {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: $box-radius;
  font-family: $font_content_normal;
  font-size: 18px;
  line-height: 20px;
  background-color: $weiss;
  color: $blau;
}

.admin__userbox_inner {
  padding: 30px;
  border-radius: $box-radius;
  background-color: $weiss;
}

.user__list {
  padding: 0;
}

.user__list_item {
  width: 100%;
  padding: 10px;
  list-style-type: none;
  margin-bottom: 25px;
  border-radius: $box-radius;
  background-color: $weiss;
  color: $blau;
}

.user__list_head {
  display: table;
  width: 100%;
  padding: 5px 0 15px 0;
}

.user__list_headitem {
  display: table-cell;
  width: 50%;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  vertical-align: middle;
}

.user__list_headitem--debitornr {
  font-family: $font_header_bold;
}

.admin__savemessage {
  display: inline-block;
  height: 21px;
  padding-left: 7.5px;
  font-family: $font_header_normal;
  color: $rot;
  font-size: 15px;
  line-height: 21px;
}

.user__list_headitem--edit {
  text-align: right;
}
.jobedit,
.useredit {
  display: block;
  float: right;
  width: 21px;
  height: 21px;
  background-image: url(../../images/admin-edit-user.svg);
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: center center;
  cursor: pointer;
}

.user__list_table {
  display: table;
  width: 100%;
  padding: 0;
}

.user__list_row {
  display: table-row;
  padding: 0;
}

.user__list_cell {
  display: table-cell;
  padding-right: 10px;
  font-family: $font_content_normal;
  font-size: 15px;
  color: $blau;
  text-align: left;
  vertical-align: middle;
}

.user__list_cell:last-child {
  padding-right: 0;
}

.user__list_cell--label {
  padding-bottom: 5px;
  font-family: $font_content_bold;
}

.user__list_cell--value {
  padding-bottom: 15px;
}

.user__list_cell--center {
  text-align: center;
}

.user__list_table--col5 .user__list_cell {
  width: 20%;
}

.user__list_table--col11 .user__list_cell {
  width: 9.090909%;
}

.user__list_table--data {
  margin-top: 15px;
}

.boolean2visual {
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: center center;
}

.boolean2visual--true {
  background-image: url(../../images/boolean2visual-true.svg);
}

.boolean2visual--false {
  background-image: url(../../images/boolean2visual-false.svg);
}

.boolean2visual--action {
  cursor: pointer;
}

.user__list_clickinfo {
  font-size: 13px;
}

.user__edit_debitor {
  text-align: left;
  margin-bottom: 25px;
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 18px;
  color: $blau;
}

.user__list_tokendurability {
  text-align: left;
  font-size: 15px;
  margin-bottom: 15px;
}

.user__list_durabilityplus {
  display: inline-block;
  margin-left: 10px;
  color: $gruen;
  cursor: pointer;
}

.admin__modal_error {
  padding: 25px 0 25px 0;
  color: $rot;
}

// autocomplete
.autocomplete-suggestions {
  border: 1px solid $dunkelblau;
  border-bottom-left-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
  background: #fff;
  overflow: auto;
  font-family: $font_content_normal;
  color: $blau;
}

.autocomplete-suggestion {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: $hellgrau;
  cursor: pointer;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: $gruen;
}

.autocomplete-group {
  padding: 5px 10px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}

.admin__loader {
  width: 100%;
  min-width: 260px;
  padding: 30px 0 30px 0;
  text-align: center;
}

.user__list_pagination {
  display: table-row;
  padding: 0;
}

.user__list_pagination_item,
.admin-paginator__list_item,
.admin-filter__list_item {
  display: inline-block;
  list-style-type: none;
  border: 1px solid $grau;
  border-radius: 5px;
  margin-right: 10px;
  font-family: $font_content_normal;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background: $grau;
  }
  > span,
  a {
    display: block;
    padding: 10px 15px;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.pagination_item--inactive {
  background: $grau;
  cursor: initial;
}

.pagination_item--active,
.admin-filter__list_item--active,
.admin-paginator__list_item--active {
  background: $gruen;
  color: $weiss;
  > a {
    color: $weiss;
  }
  &:hover {
    background: $gruen;
  }
}

.admin-filter__list {
  width: 70%;
  text-align: left;
  float: left;
}

.admin-paginator__list {
  width: 30%;
  text-align: right;
  float: left;
}

.admin-config__list {
  width: 100%;
  display: inline-block;
}

.admin__jobsbox_search {
  margin-bottom: 3em;

  .button {
    display: inline-block;
    padding: 10px 25px;
  }
}

.admin__jobsbox_searchlabel {
  display: block;
  width: 100%;
  padding-bottom: 10px;
  font-family: SourceSansProBold;
  font-size: 15px;
  text-align: center;
  color: #022856;
}

.admin__jobsbox_searchfield {
  width: 40%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-family: SourceSansPro;
  font-size: 18px;
  line-height: 20px;
  background-color: #fff;
  color: #315171;
}

.admin__jobsbox__item {
  width: 100%;
  padding: 10px;
  list-style-type: none;
  margin-bottom: 25px;
  border-radius: 5px;
  background-color: #fff;
  color: #315171;
  text-align: left;

  .title {
    font-family: FiraSansCondensedSemiBold;
    font-size: 18px;
    line-height: 21px;
    padding: 25px 0 0 25px;
  }

  .label {
    padding-bottom: 5px;
    font-family: SourceSansProBold;
    display: block;
  }

  .job__field {
    display: inline-block;
    padding: 25px;
  }

  .admin__jobsbox__control {
    padding: 25px;
  }

  .button {
    display: inline-block;
    padding: 10px 25px;
  }
}

.admin__jobsbox__jobproperties_form,
.admin__jobsbox__errorstatuscontrol_form,
.admin__jobsbox__jobownerchange_form {
  width: 280px;
  background-color: #fff;
  padding: 1em;
  margin: 1em;
}

.admin__jobsbox__form__title {
  margin-bottom: 1em;
}

.admin__jobsbox__control_section {
  text-align: left;
  padding: 1.5em;

  .admin__jobsbox__form {
    display: inline-block;
  }
}

.admin__jobsbox__form button {
  padding: 0.75em 1em;
}

.ico-filter {
  display: block;
  float: right;
  width: 15px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: center center;
  cursor: pointer;
  margin-left: 10px;
}

.ico-asc {
  background-image: url(../../images/caret-up.svg);
}

.ico-desc {
  background-image: url(../../images/caret-down.svg);
}

.admin__config_label {
  font: $font_content_normal;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: $dunkelblau;
}

.na-text {
  color: $dunkelgrau;
}

.impersonate-user-button {
  width: 290px !important;
}

@media screen and (min-width: 768px) {
  .admin__loader {
    min-width: 380px;
  }
}
