.footergroup_header {
  font-family: $font_header_bold, $font_fallback;
}

.footergroup_logo {
  display: block;
  width: 100px;
  height: auto;
  margin: 0 auto;
}

.footergroup_linklistwrapper {
  display: table;
  max-width: 265px;
  margin: 0 auto;
}

.footergroup_linklist {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.footergroup_linklist--first {
  margin-right: 30px;
}

.footergroup_linklist--second {
  margin-left: 30px;
}

.footergroup_linklistitem {
  padding-bottom: 10px;
}

.footergroup_linklistitem:last-child {
  padding-bottom: 0;
}

.footergroup_link {
  text-decoration: none;
  font-family: $font_header_normal, $font_fallback;
  font-size: 15px;
  font-weight: normal;
  color: $dunkelgrau;
}

.paymentinfo {
  display: table;
  width: 100%;
  padding: 0 20px;
  max-width: 320px;
  margin: 0 auto;
}

.accountfooter__payment--desktop .paymentinfo {
  max-width: 100%;
}

.paymentinfo_row {
  display: table; // table-row
  padding: 0;
  margin: 0 auto;
}

.accountfooter__payment--desktop .paymentinfo_row .paymentinfo_item {
  vertical-align: middle;
  padding: 2px;
}

.accountfooter__payment--desktop
  .paymentinfo_row
  .paymentinfo_item
  .footergroup_header {
  margin-bottom: 0;
  font-size: 14px;
  white-space: nowrap;
}

.paymentinfo_row:last-child .paymentinfo_item {
  padding-bottom: 0;
}

.paymentinfo_item {
  display: table-cell;
  padding-bottom: 10px;
}

.paymentinfo_item--center {
  padding-left: 10px;
  padding-right: 10px;
}

.paymentinfo_item--right {
  padding-left: 10px;
}

.paymentinfo_logo {
  display: block;
  max-width: 100%;
  height: auto;
}

.paymentinfo__item_offset {
  padding-left: 50px;
}

@media screen and (min-width: 360px) {
  .paymentinfo {
    max-width: 360px;
  }
}

@media screen and (min-width: 768px) {
  .footergroup_linklistitem {
    width: 150px;
  }

  .footergroup_header {
    font-size: 18px;
    line-height: 25px;
    text-align: left;
  }

  .footergroup_logo {
    width: 145px;
  }

  .footergroup_linklist {
    float: none;
  }

  .footergroup_linklist--first {
    margin-right: 0;
  }

  .footergroup_linklist--second {
    margin-top: 10px;
    margin-left: 0;
  }

  .footergroup_link {
    transition: padding-left 0.5s;
  }

  .footergroup_link:hover {
    padding-left: 15px;
    color: $gruen;
  }

  .paymentinfo_row {
    display: table-row;
  }
}
