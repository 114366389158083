.jobelements__header {
  font-family: $font_header_bold;
  font-size: 25px;
  line-height: 20px;
  color: $dunkelblau;
  margin-top: 30px;
  margin-bottom: 30px;
}

.jobelements__header--textleft {
  text-align: left;
}

.jobelements__header--commission {
  margin: 0;
  line-height: 30px;
}

.jobelements__filterout {
  margin-top: 60px;
  font-family: $font_content_semibold;
  color: $errorcolor;
}

.jobelement {
  margin-bottom: 20px;
}

.jobelement:last-child {
  margin-bottom: 0;
}

.jobelement__body {
  position: relative;
  padding-bottom: 20px;
  border: 1px solid $bordergrau;
  border-radius: $box-radius;
  background-color: $weiss;
  overflow-y: hidden;
}

.jobelement__body--success {
  .jobelement__namesubline-info span:first-child {
    font-size: 26px;
    font-family: $font_content_bold;
  }

  .jobelement__namesubline-info span:last-child {
    font-size: 16px;
    color: $dunkelgrau;
    font-family: $font_content_normal;
    display: inline-block;
  }
}

.jobelement__tracking {
  width: calc(100vw - 42px);
  max-width: 1078px;
  margin-top: 20px;
  padding: 20px 0 0 20px; // 16px 0 0 20px
  border-top: 1px solid $bordergrau;
  text-align: left;
}

.jobelement__trackingtogglewrapper {
  width: 100%;
}

.jobelement__trackingtoggle {
  display: inline-block;
  font-family: $font_content_bold;
  font-size: 15px;
  //line-height: 25px;
  color: $dunkelblau;
  text-align: left;
  text-transform: uppercase;
  padding-right: 16px;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;
  cursor: pointer;
  background-image: url(../../images/trackinghidden.svg);
}

.jobelement__trackingtoggle--tablevisibel {
  background-image: url(../../images/trackingvisibel.svg);
}

.jobelement__trackingcontentwrapper {
  width: 100%;
  //height: auto;
  padding-right: 20px;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.jobelement__trackingcontent,
.jobelement__trackingpagination {
  width: auto;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.jobelement__trackingcontent {
  width: 100%;
}

.jobelement__trackingcontent--visibel {
  display: table;
  //max-height: 5000000px;
  transition: max-height 0.25s ease-in;
  padding: 6px 0 6px 0;
  border-bottom: 1px solid $dunkelgrau;
}

.jobelement__trackingpagination--visibel {
  display: table;
  width: 100%;
  padding: 20px 15px 0 0;
  max-height: 500000px;
  transition: max-height 0.25s ease-in;
}

.jobelement__trackingpagination--ists {
  padding-top: 0;
}

.jobelement__trackingtable {
  width: 100%;
  border-collapse: collapse;
  font-family: $font_content_normal;
  font-size: 14px;
  text-align: left;
}

.jobelement__trackingheadcell {
  padding: 0;
  height: 25px;
  line-height: 24px;
  color: $dunkelgrau;
  font-weight: normal;
  text-transform: uppercase;
}

.jobelement__trackingheadinner {
  display: block;
  width: 100%;
  height: 100%;
  padding-right: 24px;
  border-bottom: 1px solid $dunkelgrau;
}

.jobelement__trackingheadcell:last-child .jobelement__trackingheadinner {
  padding-right: 0;
}

.jobelement__trackingtable tbody {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

.jobelement__trackingtablecell {
  padding: 0 24px 0 0;
  height: 32px;
  vertical-align: middle;
  line-height: 32px;
  color: $dunkelblau;
  white-space: nowrap;
}

.jobelement__trackingtablecell:last-child {
  padding-right: 0;
}

.jobelement__trackingtablecell--status,
.jobelement__trackingtablecell--shipmentnr {
  width: 150px;
}

.jobelement__trackingtablecell--bold {
  font-family: $font_content_bold;
}

.jobelement__trackingtablecell--c-committed,
.jobelement__trackingtablecell--c-printed,
.jobelement__trackingtablecell--c-recorded {
  color: $dunkelblau;
}

.jobelement__trackingtablecell--c-indelivery,
.jobelement__trackingtablecell--c-delivered {
  color: $jobsuccesscolor;
}

.jobelement__trackingtablecell--c-notdelivered,
.jobelement__trackingtablecell--error {
  color: $joberrorcolor;
}

.jobelement__trackingtablecell--hidden {
  display: none;
}

.jobelement__shipmentnrtable {
  display: table;
  height: 32px;
  padding: 0;
  margin: 0;
}

.jobelement__shipmentnrcell {
  display: table-cell;
  height: 32px;
  vertical-align: middle;
}

.jobelement__shipmentnrcell--action {
  padding-left: 8px;
}

.jobelement__shipmentnrcell--withtooltip {
  position: relative;
}

.tooltip__contentwrapper {
  position: absolute;
  top: -37.5px;
  left: 0;
  z-index: 999999999999999999999999999999;
  width: 260px;
  padding: 2px 6px 2px 6px;
  background-color: $weiss; // $grau
  color: $blau;
  text-align: center;
  border-radius: $box-radius;
  border: 1px solid $blau;
}

.jobelement__trackingcopy {
  position: absolute;
  top: 7px;
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  background-image: url(../../images/copyshipmentnr.svg);
}

.jobelement__trackingcopy--success {
  cursor: default;
  background-image: url(../../images/copyshipmentnrsuccess.svg);
}

.jobelement__copylegend {
  height: 30px;
  padding: 0 5px 0 22px;
  font-family: $font_content_italic;
  font-size: 14px;
  line-height: 30px;
  color: $dunkelgrau;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto;
  background-image: url(../../images/copyshipmentnr-grey.svg);
}

.jobelement__trackingpaginationbody {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}

.jobelement__trackingpaginationitem {
  display: table-cell;
  width: 25%;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
}

.jobelement__trackingpaginationitem--previous {
  background-position: left center;
  background-image: url(../../images/trackingprevious.svg);
}

.jobelement__trackingpaginationitem--all {
  width: 50%;
  font-family: $font_content_normal;
  font-size: 14px;
  line-height: 24px;
  color: $dunkelgrau;
  text-align: center;
}

.jobelement__trackingpaginationitem--paginext,
.jobelement__trackingpaginationitem--info,
.jobelement__trackingpaginationitem--pagiprev {
  height: 24px;
  width: auto;
  vertical-align: middle;
}

.jobelement__trackingpaginationitem--pagiprev {
  width: 56px;
  padding: 0 16px 0 16px;
  cursor: default;
}

.jobelement__trackingpaginationitem--paginext {
  width: 40px;
  padding-left: 16px;
  cursor: default;
}

.jobelement__trackingpaginationitem--next {
  background-position: right center;
  background-image: url(../../images/trackingnext.svg);
}

.jobelement__trackingpaginationitem--selectps {
  height: 24px;
  width: auto;
  vertical-align: middle;
  cursor: default;
}

.jobelement__trackingpaginationitem--info {
  //padding: 0 16px 0 16px;
  font-family: $font_content_normal;
  color: $dunkelgrau;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  cursor: default;
}

.jobelement__trackingpaginationaction {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
}

.jobelement__trackingpaginationaction--previous {
  background-image: url(../../images/trackingpagiprev.svg);
}

.jobelement__trackingpaginationaction--previous-disabled {
  background-image: url(../../images/trackingpagiprev-disabled.svg);
  cursor: default;
}

.jobelement__trackingpaginationaction--next {
  background-image: url(../../images/trackingpaginext.svg);
}

.jobelement__trackingpaginationaction--next-disabled {
  background-image: url(../../images/trackingpaginext-disabled.svg);
  cursor: default;
}

.jobelement__header {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 90px;
  margin: 0;
  padding: 20px 0 20px 20px;
  text-align: left;
  border-top-left-radius: $box-radius;
  border-top-right-radius: $box-radius;
  background-repeat: no-repeat;
  background-position: center center;
}

.jobelement__header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 200px;
  width: 25px; // 40px
  height: 90px;
  display: none;
  background-color: transparent;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 25px 90px;
  background-position: center center;
}

.jobelement__header--overdue {
  background-color: $joberrorbgcolor;
  background-image: url(../../images/exclamation-tringle-invoice-overdue.svg);
  background-size: auto 105px;
}

.jobelement__header--uncleared {
  background-color: $jobwarningbgcolor;
  background-image: url(../../images/euro-invoice-uncleared.svg);
  background-size: auto 105px;
}

.jobelement__header--cleared {
  background-color: $jobsuccessbgcolor;
  background-image: url(../../images/tick-invoice-cleared.svg);
  background-size: auto 121px;
}

.jobelement__header--committed,
.jobelement__header--c-committed {
  background-color: $jobprocessbgcolor;
  background-image: url(../../images/send-mail-commission-committed.svg);
  background-size: auto 60px;
}

.jobelement__header--printed,
.jobelement__header--c-printed,
.jobelement__header--c-recorded {
  background-color: $jobwarningbgcolor;
  background-image: url(../../images/letter-commission-printed.svg);
  background-size: auto 105px;
}

.jobelement__header--delivered,
.jobelement__header--c-delivered,
.jobelement__header--c-indelivery {
  background-color: $jobsuccessbgcolor;
  background-image: url(../../images/tick-invoice-cleared.svg);
  background-repeat: no-repeat;
  background-size: auto 121px;
  background-position: center center;
}

.jobelement__header--error,
.jobelement__header--c-notdelivered {
  background-color: $joberrorbgcolor;
  background-image: url(../../images/exclamation-tringle-invoice-overdue.svg);
  background-size: auto 105px;

  * {
    color: $joberrorcolor;
  }

  i.icofont-exclamation-tringle {
    position: absolute;
    top: 0;
    right: -25px;
    z-index: 2;
    display: none;
  }

  i.icofont-trash {
    color: $weiss;
  }
}

.jobelement__header--in-process {
  background-color: $jobprocessbgcolor;
  background-image: url(../../images/loading-process.svg);
  background-size: auto 105px;

  *:not(.jobelement__mobileaction-list) {
    color: $jobprocesscolor;
  }

  i.icofont-ui-laoding {
    position: absolute;
    top: 0;
    right: -25px;
    z-index: 2;
    display: none;
  }
}

.jobelement__header--success {
  background-color: $jobsuccessbgcolor;
  background-image: url(../../images/tick-invoice-cleared.svg);
  background-repeat: no-repeat;
  background-size: auto 121px;
  background-position: center center;

  i.icofont-tick-mark {
    position: absolute;
    top: 0;
    right: -25px;
    display: none;
  }
}

.jobelement__header--warning {
  background-color: $jobwarningbgcolor;
  background-image: url(../../images/exclamation-tringle-invoice-overdue.svg);
  background-size: auto 105px;

  * {
    color: $jobwarningcolor;
  }

  i.icofont-question-circle {
    position: absolute;
    top: 0;
    right: -25px;
    z-index: 2;
    display: none;
  }

  .icofont-ui-search {
    color: $weiss;
  }
}

.jobelement__header--overdue * {
  color: $joberrorcolor;
}

.jobelement__header--uncleared * {
  color: $jobwarningcolor;
}

.jobelement__header--cleared * {
  color: $jobsuccesscolor;
}

.jobelement__header--committed *,
.jobelement__header--c-committed * {
  color: $jobprocesscolor;
}

.jobelement__header--printed *,
.jobelement__header--c-printed *,
.jobelement__header--c-recorded * {
  color: $jobwarningcolor;
}

.jobelement__header--delivered *,
.jobelement__header--c-delivered *,
.jobelement__header--c-indelivery {
  color: $jobsuccesscolor;
}

/*.jobelement__header--error * {
  color: $joberrorcolor;
}*/

.jobelement__header--cleared::after,
.jobelement__header--success::after,
.jobelement__header--delivered::after,
.jobelement__header--c-delivered::after,
.jobelement__header--c-indelivery::after {
  background-image: url(../../images/triangle-element-success.svg);
}

.jobelement__header--committed::after,
.jobelement__header--c-committed::after,
.jobelement__header--in-process::after {
  background-image: url(../../images/triangle-element-process.svg);
}

.jobelement__header--printed::after,
.jobelement__header--c-printed::after,
.jobelement__header--c-recorded::after,
.jobelement__header--warning::after,
.jobelement__header--uncleared::after {
  background-image: url(../../images/triangle-element-warning.svg);
}

.jobelement__header--error::after,
.jobelement__header--overdue::after,
.jobelement__header--notdelivered::after {
  background-image: url(../../images/triangle-element-error.svg);
}

.jobelement__name {
  height: 20px;
  width: calc(100% - 80px);
  font-family: $font_content_bold;
  font-size: 15px;
  line-height: 20px;
  color: $dunkelblau;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.jobelement__namesubline {
  height: 30px;
  padding-top: 8px;
  margin: 0;
}

.jobelement__namesubline-info {
  font-family: $font_content_semibold;
  font-size: 25px;
  line-height: 25px;
}

.jobelement__namesubline-info--commission {
  font-size: 20px;
}

.jobelement__name i {
  display: none;
}

.cart__articles,
.mailbox__block {
  .jobelement__name {
    color: $gruen;
    font-size: 18px;
    overflow: visible;
    position: relative;
    width: max-content;
    max-width: 140px;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .jobelement__namesubline-info {
    font-size: 15px;
    line-height: 1;
    font-family: $font_content_normal;
    a {
      color: $gruen;
    }
  }
}

//Twig Filter "commissionStatusText"
.filter__cst-desk {
  display: none;
}

.jobelement__namesubline-list {
  height: 22px;
  font-size: 0;
  padding: 0;
}

.jobelement__namesubline-listitem {
  list-style-type: none;
  display: inline-block;
  height: 22px;
}

.jobelement__mobileaction-list {
  position: absolute;
  top: 25px;
  right: 20px;
  padding: 0;
}

.jobelement__mobileaction-listitem,
.jobelement__deskaction-listitem {
  list-style-type: none;
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 24px;
  border: 1px solid $hellgrau; // grau sieht besser aus, aber wejens der Einheitlichkeit ...
  border-radius: $box-radius;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding-top: 7px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-position: center center;
  background-color: $hellgrau;
}

.jobelement__mobileaction-listitem:first-child,
.jobelement__deskaction-listitem:first-child {
  margin-left: 0;
}

.jobelement__mobileaction-listitem--download,
.jobelement__deskaction-listitem--download {
  background-size: 20px auto;
  background-position: center center;
  background-image: url(../../images/invoice-deskaction-download.svg);
}

.jobelement__mobileaction-listitem--close,
.jobelement__deskaction-listitem--close {
  color: transparent;
  background-color: $closegraubg;
  border: 1px solid $closegraubg;
  background-size: 26px auto;
  background-position: right 5px bottom 5px;
  background-image: url(../../images/job-deskaction-close-default.svg);
}

.jobelement__deskaction-listitem--close:hover {
  background-color: $closerotbg;
  border: 1px solid $closerotbg;
  background-image: url(../../images/job-deskaction-close-hover.svg);
}

.jobelement__mobileaction-listitem--show,
.jobelement__deskaction-listitem--show {
  background-size: 19px auto;
  background-position: center center;
  background-image: url(../../images/invoice-deskaction-show.svg);
}

.jobelement__mobileaction-listitem--basket,
.jobelement__deskaction-listitem--basket {
  background-color: $jobsuccesscolor;
  color: transparent;
  border: 1px solid $jobsuccesscolor;
  background-size: 26px auto;
  background-position: right 5px bottom 5px;
  background-image: url(../../images/job-deskaction-basket.svg);
}

.jobelement__mobileaction-listitem--refresh,
.jobelement__deskaction-listitem--refresh {
  background-color: $weiss;
  color: $jobprocesscolor;
  border: 1px solid $bordergrau;
  background-size: auto;
  background-position: center center;
  background-image: url(../../images/job-deskaction-refresh.svg);
}

.jobelement__mobileaction-listitem--inspect,
.jobelement__deskaction-listitem--inspect {
  background-color: $jobwarningcolor;
  color: $weiss;
  border: 1px solid $jobwarningcolor;
}

.jobelement__mobileaction-listitem--inspect-success,
.jobelement__deskaction-listitem--inspect-success {
  background-color: $hellgrau;
  color: $gruen;
  border: 1px solid $hellgrau;
}

.jobelement__mobileaction-listitem--edit,
.jobelement__deskaction-listitem--edit {
  background-color: $hellgrau;
  color: $jobsuccesscolor;
  border: 1px solid $hellgrau;
}

.jobelement__mobileaction-listitem--delete,
.jobelement__deskaction-listitem--delete {
  background-color: $joberrorcolor;
  color: $weiss;
  border: 1px solid $joberrorcolor;
}

.jobelement__mobileaction-listitem--invoicedownload {
  display: none;
}

.jobelement__action-link {
  display: block;
  width: 100%;
  height: 100%;
}

.jobelement__control {
  width: 100%;
  height: 40px;
  padding: 20px 20px 0 20px; // $std-padding-mobile;
  margin: 0;
  font-size: 0;
  text-align: left;
}

.jobelement__control--upload-time {
  min-width: 115px;
  .jobelement__control-value {
    font-size: 14px;
  }
}

.jobelement__control-header {
  display: inline-block;
  width: 165px;
  padding-right: 35px;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 25px;
  color: $dunkelgrau;
  text-align: left;
  vertical-align: top;
  text-transform: uppercase;
}

.jobelement__control-header--commission {
  width: 158px;
  padding-right: 28px;
}

.jobelement__control--mailbox {
  > .jobelement__control-header {
    width: 110px;
  }
}

.jobelement__control-value {
  display: inline-block;
  margin: 0;
  font-family: $font_content_bold;
  font-size: 15px;
  line-height: 25px;
  color: $jobpricecolor;
}

.jobelement__deskaction-list {
  display: none;
  //width: 120px;
  height: 90px;
  float: right;
  padding: 25px 20px 25px 20px;
  margin: 0;
  font-size: 0;
  text-align: right;
}

.jobelement__empty {
  margin-top: 60px;
  font-family: $font_content_semibold;
  color: $blau;
}

.jobelement__label {
  margin-bottom: 7.5px;
  padding-left: 2.5px;
  text-align: left;
  font-family: $font_content_normal;
  font-size: 15px;
  color: $dunkelgrau;
  text-transform: uppercase;
}

.jobelement__label-commissionid {
  display: none;
}

// in process options [none]
.jobelement__body--in-process .jobelement__mobileaction-listitem--delete,
.jobelement__body--in-process .jobelement__mobileaction-listitem--inspect,
.jobelement__body--in-process
  .jobelement__mobileaction-listitem--inspect-success,
.jobelement__body--in-process .jobelement__mobileaction-listitem--basket,
.jobelement__body--in-process .jobelement__mobileaction-listitem--edit,
.jobelement__body--in-process .jobelement__deskaction-listitem--delete,
.jobelement__body--in-process .jobelement__deskaction-listitem--inspect,
.jobelement__body--in-process .jobelement__deskaction-listitem--inspect-success,
.jobelement__body--in-process .jobelement__deskaction-listitem--basket,
.jobelement__body--in-process .jobelement__deskaction-listitem--edit {
  display: none;
}

// warning options [edit, search]
.jobelement__body--warning .jobelement__mobileaction-listitem--basket,
.jobelement__body--warning .jobelement__mobileaction-listitem--refresh,
.jobelement__body--warning .jobelement__mobileaction-listitem--delete,
.jobelement__body--warning .jobelement__mobileaction-listitem--inspect-success,
.jobelement__body--warning .jobelement__deskaction-listitem--basket,
.jobelement__body--warning .jobelement__deskaction-listitem--refresh,
.jobelement__body--warning .jobelement__deskaction-listitem--delete,
.jobelement__body--warning .jobelement__deskaction-listitem--inspect-success {
  display: none;
}

// error options [trash]
.jobelement__body--error .jobelement__mobileaction-listitem--inspect,
.jobelement__body--error .jobelement__mobileaction-listitem--inspect-success,
.jobelement__body--error .jobelement__mobileaction-listitem--basket,
.jobelement__body--error .jobelement__mobileaction-listitem--refresh,
.jobelement__body--error .jobelement__mobileaction-listitem--edit,
.jobelement__body--error .jobelement__deskaction-listitem--inspect,
.jobelement__body--error .jobelement__deskaction-listitem--inspect-success,
.jobelement__body--error .jobelement__deskaction-listitem--basket,
.jobelement__body--error .jobelement__deskaction-listitem--refresh,
.jobelement__body--error .jobelement__deskaction-listitem--edit {
  display: none;
}

// success options [edit, basket]
.jobelement__body--success .jobelement__mobileaction-listitem--delete,
.jobelement__body--success .jobelement__mobileaction-listitem--refresh,
.jobelement__body--success .jobelement__mobileaction-listitem--inspect,
.jobelement__body--success .jobelement__deskaction-listitem--delete,
.jobelement__body--success .jobelement__deskaction-listitem--refresh,
.jobelement__body--success .jobelement__deskaction-listitem--inspect {
  display: none;
}

// in process fallback options
//.jobelement__deskaction-listitem--refresh,
//.jobelement__mobileaction-listitem--refresh {
//  display: none;
//}

.jobelement__body {
  width: 100%;
  padding-bottom: 20px;
  overflow-y: hidden;
  position: relative;
}

.jobelement__body--in-process {
  dt:not(.jobelement__name),
  dd:not(.jobelement__namesubline) {
    opacity: 0.4;
  }
}

.jobelement__control--edit-mobile {
  position: absolute;
  top: 80px;
}

.jobelement__parameter,
.jobelement__process-id {
  display: none;
}

.jobelement__name > i {
  display: none;
}

.jobelement__control-value-colortype,
.jobelement__control-value-printtype,
.jobelement__control-value-shipmenttype,
.jobelement__control-value-registered {
  display: inline-block;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto auto;
}

.jobelement__control-value-registered {
  width: 20px;
  background-image: url(../../images/info-registered.png);
}

.jobelement__control-value-colortype,
.jobelement__control-value-shipmenttype {
  margin-right: 10px;
}

.jobelement__control-value-colortype--color {
  width: 23px;
  background-image: url(../../images/info-farbig.png);
}

.jobelement__control-value-colortype--blackwhite {
  width: 23px;
  background-image: url(../../images/info-sw.png);
}

.jobelement__control-value-printtype--simplex {
  width: 17px;
  background-image: url(../../images/info-simplex.png);
}

.jobelement__control-value-printtype--duplex {
  width: 17px;
  background-image: url(../../images/info-duplex.png);
}

.jobelement__control-value-shipmenttype--st {
  width: 20px;
  background-image: url(../../images/info-shipment-st.png);
}

.jobelement__control-value-shipmenttype--ee {
  width: 20px;
  background-image: url(../../images/info-shipment-ee.png);
}

.jobelement__control-value-shipmenttype--ee-warning {
  width: 20px;
  background-image: url(../../images/info-shipment-ee-warning.png);
}

.accordionslide-enter-active {
  transition: 300ms ease-out;
}

.accordionslide-leave-active {
  transition: 300ms ease-out;
}

.accordionslide-enter-to,
.accordionslide-leave {
  max-height: 449px;
  overflow: hidden;
}

.accordionslide-enter,
.accordionslide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.commission {
  //position: relative;
  display: table;
  width: 100%;
  //min-height: 200px;
}

.commission__loader,
.commission__content {
  //position: absolute;
  //top: 0;
  width: 100%;
}

.commission__loader {
  display: table;
  height: 300px;
  background-image: url(../../images/loading-ball-150px.svg);
  background-repeat: no-repeat;
  background-size: 150px 150px;
  background-position: center center;
}

.commission__head {
  display: table;
  width: 100%;
  margin: 25px 0 25px 0;
  padding: 0;
}

.commission__headcell {
  display: table-cell;
  height: 30px;
  vertical-align: middle;
}

//.commission__headcell--filter {
//  text-align: right;
//}

.commission__headfilter {
  display: table;
  height: 30px;
  float: right;
}

.commission__headfiltercell {
  display: table-cell;
  height: 30px;
  vertical-align: top;
}

.fade1-enter-active {
  transition: opacity 0.5s;
}

.fade1-leave-active {
  transition: opacity 0.5s;
}

.fade1-enter,
.modalfade-leave-to {
  opacity: 0;
}

.fade2-enter-active {
  transition: opacity 1s;
}

.fade2-leave-active {
  transition: opacity 0.5s;
}

.fade2-enter,
.fade2-leave-to {
  opacity: 0;
}

.fade3-enter-active {
  transition: opacity 0.5s;
}

.fade3-leave-active {
  transition: opacity 0.25s;
}

.fade3-enter,
.fade3-leave-to {
  opacity: 0;
}

.fade4-enter-active {
  transition: opacity 0.5s;
}

.fade4-leave-active {
  transition: opacity 0.25s;
}

.fade4-enter,
.fade4-leave-to {
  opacity: 0;
}

@media screen and (min-width: 340px) {
  .jobelement__control-header--commission {
    width: 165px;
    padding-right: 35px;
  }

  .jobelement__mobileaction-listitem--invoicedownload {
    display: inline-block;
  }
}

@media screen and (min-width: 420px) {
  .filter__cst-mobile {
    display: none;
  }

  .filter__cst-desk {
    display: inline;
  }

  .cart__articles,
  .mailbox__block {
    .jobelement__name {
      max-width: 300px;
    }
  }

  .jobelement__trackingpaginationbody {
    float: right;
    width: auto;
  }

  .jobelement__trackingpaginationitem--previous {
    width: 24px;
    //background-position: center center;
  }

  .jobelement__trackingpaginationitem--all {
    width: auto;
    padding: 0 24px 0 24px;
  }

  .jobelement__trackingpaginationitem--next {
    width: 24px;
    //background-position: right center;
  }
}

@media screen and (min-width: 768px) {
  .jobelement__tracking {
    width: calc(100vw - 62px);
  }
}

@media screen and (min-width: 783px) {
  // 768 // 783

  .jobelements__header {
    text-align: left;
  }

  .jobelement__body {
    padding-bottom: 0;
  }

  .jobelement__header {
    float: left;
    border-top-left-radius: $box-radius;
    border-top-right-radius: 0;
    border-bottom-left-radius: $box-radius;
  }

  .jobelement__header::after {
    display: block;
  }

  .jobelement__name {
    width: 100%;
  }

  .jobelement__namesubline-info:not(.jobelement__namesubline-info--commission) {
    font-family: $font_content_normal;
  }

  .jobelement__mobileaction-list {
    display: none;
  }

  .jobelement__control {
    display: inline-block;
    width: auto;
    vertical-align: top;
    height: 90px;
    padding: 20px 6px;
  }

  .jobelement__control--pricebrutto,
  .jobelement__control--invoicesdate {
    padding-left: 28px;
  }

  .jobelement__control--commissionid,
  .jobelement__control--mailboxid,
  .jobelement__control--cartid,
  .jobelement__control--invoiceid {
    display: none;
  }

  .jobelement__control-header,
  .jobelement__control-value {
    width: auto;
    height: 25px;
    display: block;
    padding-right: 0;
    text-align: center;
  }

  .jobelement__control--mailbox,
  .jobelement__control--cart {
    position: relative;
    left: 27.5px;
    padding: 20px 5px;
    > .jobelement__control-header {
      width: auto;
    }
  }

  .jobelement__control--commission {
    position: relative;
    left: 13px; // 15
    padding: 20px 5px;
    > .jobelement__control-header {
      width: auto;
    }
  }

  .jobelement__control--cart {
    left: 10px;
    padding: 20px 10px;
  }

  .jobelement__deskaction-list {
    display: inline-block;
    padding-left: 9px;

    &.jobelement__deskaction-list--mailbox {
      min-width: 200px;
    }
    &.jobelement__deskaction-list--cart {
      padding-left: 9px;
    }
  }

  //.jobelement__header--commission {
  //  width: 280px;
  //}

  //.jobelement__header--commission::after {
  //  left: 280px;
  //}

  .jobelement__header--invoice,
  .jobelement__header--mailbox,
  .jobelement__header--cart {
    width: 225px;
  }

  .jobelement__header--commission {
    width: 210px;
  }

  .jobelement__header--invoice::after,
  .jobelement__header--mailbox::after,
  .jobelement__header--cart::after {
    left: 225px;
  }

  .jobelement__header--commission::after {
    left: 210px;
  }

  .jobelement__label-commissionid {
    display: inline;
  }

  .jobelement__control--commissionid {
    display: none;
  }

  .cart__articles,
  .mailbox__block {
    .jobelement__name {
      max-width: 180px;
    }
  }

  .jobelement__header--success i.icofont-tick-mark {
    display: inline-block;
  }

  .jobelement__header--warning i.icofont-question-circle {
    display: inline-block;
  }

  .jobelement__header--in-process i.icofont-ui-laoding {
    display: inline-block;
  }

  .jobelement__header--error i.icofont-exclamation-tringle {
    display: inline-block;
  }

  .jobelement__tracking {
    margin-top: 0;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 803px) {
  .jobelement__control--mailbox {
    padding: 20px 7.5px;
  }
}

@media screen and (min-width: 810px) {
  .jobelement__namesubline-info {
    font-family: $font_content_semibold;
  }

  .jobelement__control {
    padding: 20px 10px;
  }

  .jobelement__control--mailbox {
    padding: 20px 7.5px;
  }

  .jobelement__control--commission {
    padding: 20px 5px;
  }

  .jobelement__control--cart {
    padding: 20px 20px; // 20px 15px; !!!
  }

  .jobelement__control--pricebrutto,
  .jobelement__control--invoicesdate {
    padding-left: 35px;
  }

  .jobelement__deskaction-list {
    padding-left: 10px;
  }

  .jobelement__header--commission {
    width: 225px; // 290px
  }

  .jobelement__header--commission::after {
    left: 225px; // 290px
  }

  .jobelement__control--commission {
    left: 15px;
  }
}

@media screen and (min-width: 823px) {
  .jobelement__control--mailbox {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 843px) {
  .jobelement__control--mailbox {
    padding: 20px 12.5px;
  }
}

@media screen and (min-width: 850px) {
  .jobelement__control {
    padding: 20px 15px;
  }

  .jobelement__control--cart {
    padding: 20px 25px;
  }

  .jobelement__control--mailbox {
    padding: 20px 12.5px;
  }

  .jobelement__control--commission {
    padding: 20px 7.5px;
  }

  .jobelement__control--pricebrutto {
    padding-left: 35px;
  }

  .jobelement__deskaction-list {
    padding-left: 15px;
  }

  .jobelement__deskaction-list--cart {
    padding-left: 0;
  }
}

@media screen and (min-width: 863px) {
  .jobelement__control--mailbox {
    padding: 20px 15px;
  }
}

@media screen and (min-width: 900px) {
  .jobelement__control {
    padding: 20px 20px;
  }

  .jobelement__control--commission {
    padding: 20px 7.5px;
  }

  .jobelement__control--cart {
    padding: 20px 25px;
  }

  .jobelement__control--pricebrutto,
  .jobelement__control--invoicesdate {
    padding-left: 45px;
  }

  .jobelement__deskaction-list {
    padding-left: 20px;
  }

  .jobelement__deskaction-list--cart {
    padding-left: 0;
  }
}

@media screen and (min-width: 903px) {
  .jobelement__control--mailbox {
    padding: 20px 20px;
  }
}

@media screen and (min-width: 930px) {
  .jobelement__control--commission {
    padding: 20px 5px;
    left: 17.5px;
  }

  .jobelement__label-commissionid {
    display: none;
  }

  .jobelement__control--commissionid {
    display: inline-block;
  }
}

@media screen and (min-width: 943px) {
  .jobelement__control--mailbox {
    padding: 20px 25px;
  }
}

@media screen and (min-width: 965px) {
  //.jobelement__control--commission,
  .jobelement__control--cartid {
    padding: 20px 10px;
  }

  .jobelement__control--mailbox {
    padding: 20px 7.5px;
  }

  .jobelement__control--mailboxid {
    display: inline-block;
  }

  //.jobelement__control--commissionid,
  .jobelement__control--cartid,
  .jobelement__control--invoiceid {
    display: inline-block;
    padding-left: 35px;
  }

  .jobelement__control--pricebrutto,
  .jobelement__deskaction-list:not(.jobelement__deskaction-list--commission) {
    padding-left: 10px;
  }

  .jobelement__deskaction-list--cart {
    padding-left: 0;
  }

  //.jobelement__label-commissionid {
  //  display: none;
  //}
}

@media screen and (min-width: 984px) {
  .jobelement__control--mailbox {
    padding: 20px 10px;
  }

  .jobelement__control--commission {
    padding: 20px 7.5px;
  }
}

@media screen and (min-width: 1020px) {
  .jobelement__header--invoice,
  .jobelement__header--commission,
  .jobelement__header--mailbox,
  .jobelement__header--cart {
    width: 250px;
  }

  .jobelement__header--invoice::after,
  .jobelement__header--commission::after,
  .jobelement__header--mailbox::after,
  .jobelement__header--cart::after {
    left: 250px;
  }

  .jobelement__control--mailbox {
    padding: 20px 13.5px;
  }

  //.jobelement__control--commissionid,
  .jobelement__control--cartid,
  .jobelement__control--invoicesdate {
    padding-left: 40px;
  }

  .jobelement__control--pricebrutto {
    padding-left: 15px;
  }

  .jobelement__deskaction-list:not(.jobelement__deskaction-list--commission) {
    padding-left: 15px;
  }

  .cart__articles,
  .mailbox__block {
    .jobelement__name {
      max-width: 200px;
    }
  }
}

//@media screen and (min-width: 1030px) {
//  .jobelement__control--cart {
//    padding: 20px 25px; // 20px 5px !!!
//  }
//}

@media screen and (min-width: 1034px) {
  .jobelement__control--mailbox {
    padding: 20px 15px;
  }
}

@media screen and (min-width: 1070px) {
  .jobelement__control--commission {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 1080px) {
  //.jobelement__control--commission {
  //  padding: 20px 10px;
  //}

  //.jobelement__control--commissionid,
  .jobelement__control--cartid {
    padding-left: 45px;
  }

  .jobelement__control--pricebrutto {
    padding-left: 20px;
  }

  .jobelement__control--commission {
    padding: 20px 12.5px;
  }

  .jobelement__deskaction-list {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1084px) {
  .jobelement__control--mailbox {
    padding: 20px 20px;
  }
}

//@media screen and (min-width: 1088px) {
//  .jobelement__control--cart {
//    padding: 20px 25px; // 20px 10px !!!
//  }
//}

//@media screen and (min-width: 1090px) {
//  .jobelement__header--commission {
//    width: 300px;
//  }
//
//  .jobelement__header--commission::after {
//    left: 300px;
//  }
//}

@media screen and (min-width: 1100px) {
  .jobelement__namesubline-info {
    font-family: $font_content_bold;
  }

  //.jobelement__header--commission {
  //  width: 310px;
  //}

  //.jobelement__header--commission::after {
  //  left: 310px;
  //}
}

@media screen and (min-width: 1120px) {
  .jobelement__control--mailbox {
    padding: 20px 22.5px;
  }

  .jobelement__control--cart {
    left: 15px; // left: 12.5px;
    padding: 20px 5px;
  }
}

@media screen and (min-width: 1135px) {
  .jobelement__control--mailbox {
    padding: 20px 24px;
  }

  .jobelement__control--commission {
    padding: 20px 15px;
  }
}
