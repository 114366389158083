// Farben
$weiss: #fff;
$schwarz: #000;
$dunkelblau: #022856;
$blau: #315171;
$accountblau: #345678;
$mittelblau: #82a5c7;

$hellblau: #adc4db;
$rot: #df3b2a;
$errorcolor: #c63e28;
$errorbackground: #faeae8;
$dunkelgruen: #8ab21d;
$verlaufgruen: #95c23d;
$gruen: #96c11f;
$hellgruen: #e4f0ce;
$neongruen: #eaffb2;
$graugruen: #e7ece6;
$grauweiss: #f9f9f9;
$hellgrau: #f4f4f4;
$grau: #e8e8e8;
$mittelgrau: #d7d7d7;
$dunkelgrau: #b6b6b6;
$bordergrau: #d8d8d8;
$blaugrau: #ccd5db;
$graublau: #e4ebf3;
$dunkelgraublau: #6d7e91;
$dashedborder: #e1e1e1;
$admingrau: #8f8f8f;
$closegraubg: #d6d4d4;
$opmleadtext: #919ba6;
$opmleadcircle: #d9e0e8;
$closerotbg: #dd726c;
$pomreference: #6a8bac;

$jobprocessbgcolor: #e7edf7;
$jobwarningbgcolor: #fff6de;
$joberrorbgcolor: #faeae8;
$jobsuccessbgcolor: #f4fdda;
$jobprocesscolor: #022855;
$jobwarningcolor: #e4a600;
$joberrorcolor: #c63e28;
$jobsuccesscolor: #96c11f;
$jobpricecolor: #022856;

$eyecatcherorange: #e4a600;

// Fonts
$font_fallback: "sans-serif";
$font_header_normal: "FiraSansCondensed";
$font_header_bold: "FiraSansCondensedSemiBold";
$font_content_normal: "SourceSansPro";
$font_content_italic: "SourceSansProItalic";
$font_content_semibold: "SourceSansProSemiBold";
$font_content_bold: "SourceSansProBold";
$font_ico: "IcoFont";

// Standard Padding
$std-padding-mobile: 20px;
$std-padding-desktop: 30px;

// Standard Radius Rundungen
$box-radius: 5px;

//Navigation
$nav-mobile-height: 50px;
$nav-desktop-height: 100px;

//Form
$form-radio-medium-diameter: 20px;
$form-radio-large-diameter: 25px;
