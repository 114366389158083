//Before
.before {
  display: none;
  min-width: 320px;
}

.text-original {
  text-transform: none !important;
}

// Grauer Bereich mit grünen Häckchen
.topinfo {
  width: 100%;
  height: 30px;
  background-color: $grau;
  text-align: center;
}

.topinfo__list {
  display: table;
  width: 100%;
  max-width: 1000px;
  height: 30px;
  padding: 0;
  margin: 0 auto;
}

.topinfo__listitem {
  display: table-cell;
  height: 30px;
  vertical-align: middle;
  color: $blau;
  font-family: $font_header_normal, $font_fallback;
  font-size: 12px;
  text-transform: uppercase;
}

.topinfo__tickmark {
  font-size: 14px;
  color: $gruen;
  padding-right: 3px;
}

.topinfo__bold {
  font-family: $font_header_bold, $font_fallback !important;
}

// Main
.main {
  min-width: 320px;
  transition: margin-left 0.5s;
}

.content--totalheight {
  height: calc(100vh - 173px);
}

// Blöcke
.block {
  position: relative;
  max-width: 1680px;
  margin: 0 auto;
  overflow: hidden;
}

.block--wide {
  background-color: #f4f4f4;
  padding-bottom: 15px;
}

.block--plain-white {
  background-color: #ffffff;
}

.block--blue-blue {
  background-color: $blau;
}

.block--plain-lightgrey {
  background-color: $hellgrau;
}

.block--plain-slate {
  background-color: $graublau;
}

.block--plain-gray {
  background-color: #d8e0e8;
}

.block--gradient-dark {
  @include gradient-dark();
}

.block--gradient-light {
  @include gradient-light();
}

.block--eyecatcher-orange {
  background-color: $eyecatcherorange;
}

.block--circle-circle {
  background-image: url(../../images/block1-kreise.svg);
  background-repeat: no-repeat;
  background-size: 445px auto;
  background-position: center top 35px;
}

.block--bottom-lightgrey,
.block--circle-circle {
  padding-bottom: 60px;
}

.block--triangle-lightgrey-bottom::after {
  position: relative;
  z-index: 1;
  @include triangle-lightgrey-doubleup();
}

.block--triangle-blue-bottom::after {
  position: relative;
  z-index: 1;
  @include triangle-blue-doubleup();
}

.block--triangle-lightgrey-top {
  padding-top: 74px;
}

.block--triangle-lightgrey-top::before {
  @include triangle-lightgrey-down();
}

.block--triangle-blue-top {
  padding-top: 74px;
}

.block--triangle-blue-top::before {
  @include triangle-blue-down();
}

.block--shadowuntil-1120 {
  box-shadow: 0 0 50px 6px rgba(0, 0, 0, 0.1);
}

.block__content {
  max-width: 1140px;
  padding: $std-padding-mobile;
  margin: 0 auto;
  text-align: center;
}

.block__content--totalheight {
  height: calc(100vh - 173px);
}

.block__wrapper {
  background-color: $hellgrau;
}

.block__wrapped-background {
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
}

.block__wrapped-background--login,
.block__wrapped-background--registration {
  height: 290px;
}

.block__wrapped-background--contact {
  height: 250px;
}

.block__wrapped-background--faq {
  height: 270px;
}

.block__wrapped-background--imprint {
  height: 250px;
}

.block__wrapped-background--privacy {
  height: 250px;
}

.block__wrapped-background--gradient-dark {
  @include gradient-dark();
}

.block__wrapped-background--triangle-lightgrey-bottom {
  padding-bottom: 60px;
}

.block__wrapped-background--triangle-lightgrey-bottom::after {
  @include triangle-lightgrey-doubleup();
}

.block__wrapped-content {
  position: relative;
  z-index: 101;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  padding: 15px $std-padding-mobile 70px $std-padding-mobile;
  text-align: center;
  margin: 0 auto;
}

.block__header {
  margin-top: 40px;
  margin-bottom: 50px;
  font-family: $font_header_bold;
  font-size: 30px;
  color: $weiss;
}

.block__header--login {
  margin-top: 60px;
}

.block__header--registration {
  margin-bottom: 32.5px;
}

.block__header--faq {
  margin-top: 35px;
  margin-bottom: 8px;
}

.block__header--imprint {
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 46px;
}

.block__header--contact {
  line-height: 22px;
  padding-top: 2px;
  margin-top: 38px;
  margin-bottom: 48px;
}

.block__header--privacy {
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 46px;
}

.block__descr {
  font-size: 13px;
  color: $hellblau;
  margin-bottom: 40px;
}

.block__descr--nfm {
  display: none;
}

.block__desrc--highlight {
  font-family: $font_content_bold;
  color: $weiss;
}

.login,
.registration {
  min-height: 290px;
  background-image: url(../../images/login-background.svg);
  background-repeat: no-repeat;
  background-size: auto 235px;
  background-position: center 10px;
}

.login {
  padding-bottom: 70px;
}

.faq {
  min-height: 270px;
  background-image: url(../../images/faq-background.svg);
  background-repeat: no-repeat;
  background-size: auto 185px;
  background-position: center -10px;
}

.imprint {
  min-height: 250px;
  background-image: url(../../images/imprint-background.svg);
  background-repeat: no-repeat;
  background-size: auto 117px;
  background-position: center 42px;
}

.contact {
  min-height: 250px;
  background-image: url(../../images/contact-background.svg);
  background-repeat: no-repeat;
  background-size: auto 195px;
  background-position: center -25px;
}

.contact__teaser-first {
  color: $hellblau;
  font-size: 25px;
  line-height: 21px;
  margin-bottom: 20px;
  display: none;
}

.contact__teaser-second {
  color: $weiss;
  font-family: $font_content_bold;
  font-size: 25px;
  line-height: 21px;
  margin-bottom: 80px;
  display: none;
}

.privacy {
  min-height: 250px;
  background-image: url(../../images/imprint-background.svg);
  background-repeat: no-repeat;
  background-size: auto 117px;
  background-position: center 42px;
}

.privacy__list {
  font-size: 15px;
  line-height: 18px;
  font-family: $font_content_normal;
  text-align: left;
}

.cboxcontainer {
  width: 100%;
}

// -> 768px
//.cboxcontainer__child {
//
//}

.content__teaser__pinsign {
  width: 90%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  background: rgb(206, 242, 113);
  background: linear-gradient(
    90deg,
    rgba(206, 242, 113, 0.5) 0%,
    rgba(125, 163, 19, 0.5) 95%
  );
  padding: 20px 25px;
  border-radius: 16px;
  margin-top: 50px;
  position: relative;
}

.teaser__pinsign__logo {
  max-width: 200px;
}

.teaser__pinsign__picture {
  width: 100%;
  height: auto;
  max-width: 350px;
}

.teaser__pinsign__text {
  font-size: 18px;
  line-height: 26px;
  color: #315171;
  margin-top: 30px;
  margin-bottom: 30px;
}

.teaser__pinsign__ctawrapper {
  max-width: 460px;
  margin-top: 30px;
}

.content__teaser__pinsign .cta--large .cta__text {
  font-size: 16px;
}

.cbox {
  width: 100%;
  padding: 35px 20px 10px 20px;
  background-color: $weiss;
  border-radius: $box-radius;
  color: $blau;
  margin: 0 auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.cbox--p-std p {
  text-align: left;
  margin-bottom: 15px;
  line-height: 18px;
}

.cbox--mw500200 {
  max-width: 500px;
}

.cbox--mw500 {
  max-width: 500px;
}

.cbox--mw750 {
  max-width: 750px;
}

.cbox--mw810 {
  max-width: 810px;
}

.cbox--person {
  text-align: left;
  margin-bottom: 20px;

  p {
    font-size: 15px;
  }
}

.cbox--contact-left,
.cbox--contact-right {
  padding-bottom: 35px;
}

.cbox--contact-right {
  margin-top: 50px;
}

.cbox__header,
.cbox__subheader,
.cbox__subsubheader {
  margin-bottom: 35px;
  font-family: $font_header_bold;
  padding-top: 2px;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
}

.cbox__subheader {
  text-transform: uppercase;
  margin-top: 35px;
}

.cbox__subsubheader {
  text-transform: uppercase;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 17px;
  line-height: 19px;
}

.login__resetpassword.form__row {
  text-align: center;
}

.login__resetpassword-link {
  font-size: 15px;
  color: $gruen;
  text-decoration: none;
}

.bc__form,
.pc__form {
  display: none;
}

.triangle--negative-lightgrey {
  fill: $hellgrau;
  stroke: $hellgrau;
  stroke-width: 0;
}

.registeredletterinfo {
  padding: 0 $std-padding-mobile 0 $std-padding-mobile;
}

.teaser {
  padding-top: 50px;
  padding-bottom: 20px;
}

.teaser__header {
  font-size: 30px;
  line-height: 35px;
  color: $weiss;
  margin-bottom: 30px;
}

.teaser__header--bold {
  font-family: $font_header_bold, $font_fallback;
  font-size: 29px;
}

.eyecatcher__text {
  color: $weiss;
  font-size: 19px;
  line-height: 48px;
}

.eyecatcher__text--long {
  display: none;
}

.teaser__first {
  color: $hellblau;
  font-size: 13px;
  line-height: 22px;
}

.teaser__ctawrapper,
.contact__ctawrapper {
  position: relative;
  z-index: 9999;
  width: 260px;
  height: 168px;
  margin: 0 auto;
  margin-top: 20px;
  padding-top: 115px;
  background-image: url(../../images/teaser.svg);
  background-repeat: no-repeat;
  background-size: 210px 118px;
  background-position: center top;
}

.contact__ctawrapper {
  width: 240px;
}

.cockpit {
  padding-top: 32px; // + 8px from line-height
  padding-bottom: 20px;
}

.cockpit__header {
  max-width: 342px;
  font-size: 29px;
  line-height: 35px;
  color: $dunkelblau;
  margin: 0 auto;
  margin-bottom: 27px; // + 8px from line-height
}

.cockpit__header--bold {
  font-family: $font_header_bold, $font_fallback;
}

.cockpit__header--inner {
  white-space: nowrap;
}

.cockpit__info {
  display: table;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  padding-top: 240px;
  background-image: url(../../images/info-opm-cockpit.png);
  background-repeat: no-repeat;
  background-size: 252px 203px;
  background-position: center top;
}

.cockpit__inforow {
  display: table-row;
  list-style-type: none;
  padding: 0;
}

.cockpit__infocell {
  display: table-cell;
  padding: 78px 5px 30px 5px; // 78px 5px 35px 5px
  background-repeat: no-repeat;
  background-size: 68px 68px;
  background-position: center top;
}

.cockpit__infocell--discount {
  background-image: url(../../images/opm-lead-discount.svg);
}

.cockpit__infocell--efficiency {
  background-image: url(../../images/opm-lead-efficiency.svg);
}

.cockpit__infocell--paperless {
  background-image: url(../../images/opm-lead-paperless.svg);
}

.cockpit__infocell--capacities {
  background-image: url(../../images/opm-lead-capacities.svg);
}

.cockpit__info__austria .cockpit__infocell {
  display: inline-block;
  width: 45%;
}

.cockpit__infocell_header {
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: $dunkelblau;
  word-spacing: 1680px;
  //padding-top: 78px;
  margin-bottom: 10px;
}

.cockpit__infocell_text {
  max-width: 140px;
  margin: 0 auto;
  font-family: $font_content_normal;
  font-size: 14px;
  line-height: 20px;
  color: $opmleadtext;
}

.cockpit__down {
  display: none;
  position: relative;
  z-index: 9999;
  width: 36px;
  height: 12px;
  margin: 0 auto;
  background-image: url(../../images/opm-lead-curved-down.svg);
  background-repeat: no-repeat;
  background-size: 36px 12px;
  background-position: center center;
}

.comparison {
  padding-top: 30px; // + 8px from line-height
  padding-bottom: 20px;
}

.comparison__prelude {
  display: none;
  font-family: $font_content_semibold, $font_fallback;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 20px;
}

.comparison__header {
  font-family: $font_header_normal, $font_fallback;
  font-size: 18px; // 25px
  line-height: 25px; // 37px
  color: $weiss;
  margin-bottom: 32px; // + 8px from line-height
}

.comparison__header--first,
.comparison__header--second {
  display: block;
}

.comparison__header--bold {
  font-family: $font_header_bold, $font_fallback;
}

.comparison__contrllist {
  width: 320px;
  font-size: 0;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 20px 0 20px;
}

.comparison__contrl {
  display: inline-block;
  list-style-type: none;
  width: 100px;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  text-align: center;
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 46px;
  color: $graublau;
  background-color: $dunkelblau;
  border-radius: $box-radius;
}

.comparison__contrl:first-child {
  margin-left: 0;
}

.comparison__contrl--active {
  color: $dunkelblau;
  background-color: $graublau;
}

.comparison__itemlist {
  position: relative;
  display: table;
  width: 600px;
  height: 525px;
  margin: 0 auto;
  padding: 0;
}

.comparison__item_sublist--advantages {
  height: 576px;
}

.comparison__item_sublist--needs {
  height: 240px;
}

.comparison__item {
  display: table-cell;
  width: 300px;
  padding: 0 5px 0 5px;
}

.comparison__iteminner {
  width: 100%;
  height: 100%;
  padding: 12px 20px 35px 20px;
  font-family: $font_content_normal;
  color: $blau;
  background-color: $weiss;
  border-radius: $box-radius;
  opacity: 0.93;
}

.comparison__item--active > .comparison__iteminner {
  opacity: 1;
}

.comparison__tooltipwrapper {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 0;
}

.comparison__itemheader {
  height: 45px;
  font-family: $font_content_bold;
  font-size: 67px;
  line-height: 43px;
  color: $dunkelblau;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 20px;
}

.comparison__itemadvice {
  display: block;
  height: 88px;
  overflow: visible;
  font-size: 15px;
  line-height: 22px;
}

.comparison__itemadvice-bold {
  font-family: $font_content_bold;
}

.comparison__itemadvice-block {
  display: block;
}

.comparison__itemsubheader {
  background-color: $graublau;
  border-radius: $box-radius;
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 40px;
  color: $dunkelblau;
  text-transform: uppercase;
  margin-top: 20px; // 25px ???
  margin-bottom: 20px; // 25px ???
}

.comparison__item_sublist {
  padding: 0;
}

.comparison__item_sublistitem {
  list-style-type: none;
  padding-left: 28px;
  background-image: url(../../images/comparison-tick-mark-small.svg);
  background-repeat: no-repeat;
  background-size: 23px 22px;
  background-position: left top;
  text-align: left;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
}

.comparison__item_sublist--needs .comparison__item_sublistitem {
  background-image: url(../../images/comparison-arrow-right-small.svg);
}

.comparison__item_subsublist {
  padding: 0;
}

.comparison__item_subsublistitem {
  list-style-type: none;
  padding-left: 8px;
  background-image: url(../../images/comparison-subsublistitem-dot-small.svg);
  background-repeat: no-repeat;
  background-size: 3px 20px;
  background-position: left top;
  color: $opmleadtext;
  font-size: 13px;
  line-height: 20px;
}

.references {
  padding-top: 20px; // + 5px from line-height
  padding-bottom: 60px;
}

.references__header {
  font-family: $font_header_normal, $font_fallback;
  font-size: 25px; // 25px || 18px
  line-height: 37px; // 37px || 25px
  color: $pomreference;
  margin-top: 0;
  margin-bottom: 32px; // + 8px from line-height
  text-transform: uppercase;
}

.references__header--first,
.references__header--second {
  display: block;
}

.references__header--bold {
  font-family: $font_header_bold, $font_fallback;
}

.references__listwrapper {
  padding-left: 23px;
  padding-right: 23px;
}

//.references__item {
//}

.references__image {
  margin: 0 auto;
}

.testimonials__listwrapper {
  padding-left: 70px;
  padding-right: 70px;
  height: auto;
  background-color: #ffffff;
  margin-top: 70px;
  margin-bottom: 50px;
  border-radius: 6px;
}

.testimonials__listwrapper--short {
  margin-top: 30px;
}

.testimonials__item {
  font-family: SourceSansPro, "sans-serif";
  font-size: 17px;
  color: #315171;
  background-color: #ffffff;
  padding-top: 25px;
  padding-bottom: 20px;
}

.testimonials__item--image {
  float: none;
  margin-right: auto;
  margin-left: auto;
  max-width: 200px;
}

.testimonials__item--text {
  margin-left: auto;
  font-family: SourceSansProItalic, "italic";
  padding: 25px 5px 5px 5px;
  text-align: left;
}

.testimonials__item--text .quote {
  position: relative;
}

.testimonials__item--text .quote::before {
  color: #bbb;
  content: "„";
  position: absolute;
  top: -70px;
  left: 0;
  font-size: 65px;
  font-family: SourceSansPro;
}

.quote::before {
  transform: scale(-1,-1);
}

.testimonials__item--author {
  margin-left: auto;
  display: block;
  text-align: right;
  font-style: normal;
  margin-top: 20px;
}

.testimonials__list .slick-prev {
  left: -50px;
}

.testimonials__list .slick-next {
  right: -50px;
}

.testimonials__list .slick-prev::before,
.testimonials__list .slick-next::before {
  color: #171756;
}

.leadform {
  padding-top: 40px; // + 5px from line-height
}

.leadform__header {
  font-family: $font_header_normal, $font_fallback;
  font-size: 22px;
  line-height: 29px;
  color: $dunkelblau;
  margin-top: 0;
  margin-bottom: 13px; // + 8px from line-height
}

.leadform__header--first,
.leadform__header--second {
  display: block;
}

.leadform__header--bold {
  font-family: $font_header_bold, $font_fallback;
}

.leadform__header--text {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.companyinfo {
  padding: 40px auto;
}

.companyinfo__header{
  font-family: $font_header_normal, $font_fallback;
  font-size: 22px;
  line-height: 29px;
  color: $dunkelblau;
  margin-top: 0;
  margin-bottom: 13px; // + 8px from line-height
}

.companyinfo__header--bold {
  font-family: $font_header_bold, $font_fallback;
}

.companyinfo__header--text {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.leadform__secondheader {
  font-family: $font_header_normal, $font_fallback;
  font-size: 16px;
  line-height: 21px;
  color: $dunkelgraublau;
  margin-top: 5px;
  margin-bottom: 32px; // + 8px from line-height
  text-transform: uppercase;
}

.leadform__secondheader--first {
  display: block;
}

.leadform__secondheader--bold {
  font-family: $font_header_bold, $font_fallback;
}

.leadform__backlinkwrapper {
  padding-top: 25px;
}

.leadform__person__image {
  width: 100%;
}

.leadform__person__description,
.leadform__person__contact {
  margin: 25px 0;
}

.leadform__person__testimonial {
  font-style: italic;
}

p.leadform__person__phone {
  font-size: 21px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 5px;
}

.leadform__person__link {
  display: inline-block;
  margin: 25px 10px 0 0;
}

.error404__ctawrapper {
  width: 260px;
  height: 247px;
  margin: 0 auto;
  margin-top: 20px;
  padding-top: 197px;
  background-image: url(../../images/error404.svg);
  background-repeat: no-repeat;
  background-size: auto 197px;
  background-position: center top;
}

.sparpo {
  padding-top: 65px;
  padding-bottom: 65px;
}

.sparpo__bpmvalue {
  color: $dunkelblau;
  font-family: $font_header_bold;
  font-size: 50px;
}

.sparpo__bpmlabel {
  display: block;
  color: $dunkelblau;
  font-size: 20px;
}

.sparpo__rangewrapper {
  position: relative;
  top: 10px;
  margin-top: 20px;
  height: 22px;
  overflow: visible;
}

.sparpo__resulttable {
  display: table;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  margin-top: 35px;
  padding: $std-padding-mobile 15px $std-padding-mobile $std-padding-mobile;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: $box-radius;
}

.sparpo__resultrow {
  display: table-row;
}

.sparpo__resultcell {
  display: table-cell;
  vertical-align: bottom;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.sparpo__resultrow:first-child .sparpo__resultcell {
  padding-top: 0;
}

.sparpo__resultrow:last-child .sparpo__resultcell {
  padding-bottom: 0;
}

.sparpo__resultcell--label {
  text-align: left;
  text-transform: uppercase;
  font-family: $font_content_normal;
  color: $blau;
  font-size: 12px;
  line-height: 12px;
}

.sparpo__format--versandlabel {
  position: relative;
  top: -2px;
}

.sparpo__format--pinlabel {
  position: relative;
  top: -5px;
}

.sparpo__resultcell--versand,
.sparpo__resultcell--pin {
  text-align: right;
}

.sparpo__resultcell--versand {
  font-size: 18px;
  line-height: 18px;
  font-family: $font_content_bold;
  color: $blau;
}

.sparpo__format--strikethrough {
  display: inline-block;
  float: right;
  position: relative;
  padding: 0 10px 0 10px;
  line-height: 18px;
}

.sparpo__format--strikethrough::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: $rot;
  transform: rotate(-5deg);
}

.sparpo__resultcell--pin {
  padding-right: 10px;
  font-size: 30px;
  line-height: 24px;
  font-family: $font_header_bold;
  color: $gruen;
}

.sparpo__format--fzeurosep {
  font-size: 18px;
}

.sparpo__arrowup {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid $weiss;
  margin: 0 auto;
  margin-top: 20px;
}

.sparpo__detailtable {
  display: table;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  padding: 0 30px 30px 30px;
  background-color: $weiss;
  border: 0 solid $weiss;
  border-bottom-left-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
}

.sparpo__detailtable-caption {
  display: table-caption;
  background-color: $weiss;
  border: 0 solid $weiss;
  border-top-left-radius: $box-radius;
  border-top-right-radius: $box-radius;
  margin: 0;
  padding: 30px;
  font-size: 18px;
  font-family: $font_header_bold;
  text-transform: uppercase;
  color: $dunkelblau;
  text-align: center;
}

.sparpo__detailrow {
  display: table-row;
  width: 100%;
  margin: 0;
  padding: 0;
}

.sparpo__detailcell {
  display: table-cell;
  width: 50%;
  font-size: 18px;
  font-family: $font_header_bold;
  color: $gruen;
  text-align: center;
  vertical-align: middle;
}

.sparpo__detailbox {
  display: inline-block;
  height: 78px;
  padding-left: 25px;
  margin: 0 auto;
  text-align: left;
  background-repeat: no-repeat;
  background-size: auto 58px;
  background-position-x: left;
  background-position-y: top;
  overflow: visible;
}

.sparpo__detailbox--kosten {
  background-image: url(../../images/euro-true.svg);
  padding-right: 20px;
  padding-bottom: 20px;
}

.sparpo__detailbox--arbeitszeit {
  background-image: url(../../images/sand-clock.svg);
  padding-left: 20px;
  padding-bottom: 20px;
}

.sparpo__detailbox--druckertinte {
  padding-right: 20px;
  padding-top: 20px;
  background-image: url(../../images/blood-drop.svg);
  background-position-x: 7px;
  background-position-y: 20px;
}

.sparpo__detailbox--co2 {
  padding-left: 20px;
  padding-top: 20px;
  background-image: url(../../images/leaf.svg);
  background-position-x: 2px;
  background-position-y: 20px;
}

.sparpo__detaillabel {
  display: block;
  margin-top: 8px;
  font-size: 13px;
  line-height: 22px;
  font-family: $font_content_normal;
  color: $blau;
}

.sparpo__detailvalue {
  display: inline-block;
  width: 80px;
}

.sparpo__grundlage {
  max-width: 810px;
  margin: 0 auto;
  margin-top: 10px;
  color: $dunkelgrau;
  font-size: 9px;
  line-height: 10px;
}

.steps {
  padding-top: 65px;
  padding-bottom: 65px;
}

.steps__header {
  color: $weiss;
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 30px;
  line-height: 30px;
}

.steps__first {
  color: $hellgrau;
  font-size: 18px;
  padding-bottom: 10px;
}

.steps__boxwrapper {
  position: relative;
  max-width: 280px;
  margin: 0 auto;
  margin-top: 50px;
}

.steps__boxwrapper--step1 {
  padding-top: 10px;
}

.steps__boxwrapper--step2 {
  padding-top: 10px;
}

.steps__boxwrapper--step3 {
  padding-top: 25px;
}

.steps__headerbox {
  position: relative;
  width: 100%;
  height: 175px;
  overflow: visible;
  background-color: $hellgrau;
  border-top-left-radius: $box-radius;
  border-top-right-radius: $box-radius;
}

.steps__boxshape {
  position: relative;
  width: 100%;
  height: 175px;
  overflow: visible;
}

.steps__boximage {
  position: relative;
}

.steps__boximage--step1 {
  top: -10px;
}

.steps__boximage--step2 {
  top: -10px;
}

.steps__boximage--step3 {
  top: -25px;
  width: 114px;
}

.steps__boxtriangle {
  position: absolute;
  bottom: 0;
  width: 280px;
  height: 75px;
  background-color: transparent;
  background-image: url(../../images/steps-boxtriangle.svg);
  background-repeat: no-repeat;
  background-size: 360px 96px;
  background-position: right bottom;
}

.steps__textbox {
  position: relative;
  width: 100%;
  padding: 10px 20px 30px 20px;
  background-color: $weiss;
  border-bottom-left-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
}

.steps__textbox-header {
  margin-top: 0;
  margin-bottom: 20px;
  color: $dunkelblau;
  font-family: $font_header_bold;
  font-size: 18px;
  text-transform: uppercase;
}

.steps__textbox-text {
  color: $blau;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 22px;
}

.steps__boxwrapper:hover .steps__textbox-header {
  transform: scale(1.1);
}

.benefits {
  padding-top: 65px;
  padding-bottom: 21px;
}

.benefits__header {
  color: $dunkelblau;
  margin-bottom: 40px;
  margin-top: 0;
  font-size: 30px;
  line-height: 30px;
}

.benefits__ihre {
  font-family: $font_header_normal;
}

.benefits__listwrapper {
  position: relative;
  width: 100%;
  height: 310px;
}

.benefits__list {
  position: absolute;
  z-index: 1;
  width: 100%;
  font-size: 0;
  margin: 0;
  padding: 0;
}

.benefits__item {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 310px;
  list-style-type: none;
  margin: auto;
  padding: 0;
  font-size: 15px;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.benefits__itemicon {
  display: block;
  width: 165px;
  height: 165px;
  background-color: $weiss;
  border: 15px solid $hellgrau;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px auto;
  margin: 0 auto;
}

.benefits__itemicon--cost {
  background-image: url(../../images/benefits__itemicon--cost.svg);
}

.benefits__itemicon--dsgvo {
  background-image: url(../../images/benefits__itemicon--dsgvo.svg);
}

.benefits__itemicon--easy {
  background-image: url(../../images/benefits__itemicon--easy.svg);
}

.benefits__itemicon--fast {
  background-image: url(../../images/benefits__itemicon--fast.svg);
}

.benefits__itemicon--co2 {
  background-image: url(../../images/benefits__itemicon--co2.png); // benefits__itemicon--co2.svg
}

.benefits__itemheader {
  font-family: $font_header_bold;
  font-size: 18px;
  color: $dunkelblau;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 20px;
}

.benefits__itemtext {
  max-width: 250px;
  margin: 0 auto;
  color: $blau;
  line-height: 23px;
}

.benefits__deskcontent {
  display: none;
}

.benefits__contrl {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 310px;
  font-size: 0;
  margin: 0;
  padding: 0;
}

.benefits__contrlleft,
.benefits__contrlright {
  display: inline-block;
  width: 50%;
  height: 310px;
}

.benefits__dots {
  display: none;
  font-size: 0;
  margin: 0 auto;
  margin-top: 40px;
  padding: 0;
}

.benefits__dot {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0 3px 0 3px;
  background-color: $mittelgrau;
  border: 2px solid $hellgrau;
  border-radius: 50%;
  transition: background-color 0.6s ease, border-color 0.6s ease;
  cursor: pointer;
}

.benefits__dot:hover,
.benefits__dot--active {
  background-color: $gruen;
  border-color: $gruen;
}

.online_schreiben__icon--address_book {
  background-image: url(../../images/address_book.svg);
}

.online_schreiben__icon--documents {
  background-image: url(../../images/documents.svg);
}

.online_schreiben__icon--icloud_sync {
  background-image: url(../../images/iCloud_sync.svg);
}

.online_schreiben__icon--personal_header {
  background-image: url(../../images/personal_header.svg);
}

.online_schreiben__icon--ready_for_ebrief {
  background-image: url(../../images/ready_for_eBrief.svg);
}

.online_schreiben__icon {
  display: block;
  width: 110px; //165px;
  height: 110px; //165px;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 60px auto; //80
  margin: auto;
}

.online_schreiben__icon--wrapper {
  width: 130px; // 200px;
  height: 130px; // 200px;
  margin: auto;
  display: flex;
}

.online_schreiben__icon {
  &:hover {
    width: 125px; //185px
    height: 125px; //185px
    background-size: 80px auto; // 110px
  }
}

.online_schreiben__icon.online_schreiben__icon--address_book,
.online_schreiben__icon.online_schreiben__icon--personal_header {
  background-size: 40px auto;
  &:hover {
    background-size: 60px auto;
  }
}

.online_schreiben__icon.online_schreiben__icon--icloud_sync {
  background-size: 45px auto;
  &:hover {
    background-size: 60px auto;
  }
}

.online_schreiben__list_item__text.help__info {
  text-align: center;
  font-size: 15px;

  strong {
    font-family: SourceSansProSemiBold, "sans-serif";
  }
}

.online_schreiben__list_item {
  display: inline-block;
  width: 170px; //280
  height: 150px; //280
  list-style-type: none;
  margin-bottom: 40px;
}

.online_schreiben__list_item.three {
  width: 100%;
}

.online_schreiben__content {
  padding-top: 15px;

  .content__footer_text {
    text-align: center;
    font-size: 15px;
  }
}

.comparison__item_sublist__head {
  padding: 5px;
  background-color: #e4ebf3;
  border-radius: 6px;
  font-style: normal;
  text-transform: none;
  margin-top: 40px;

  strong {
    font-family: SourceSansProSemiBold, "sans-serif";
  }
}

.grosse_sendungsmengen__header.help__header {
  text-transform: none;
  text-align: center;
}

.letsgo__ctawrapper.grosse_sendungsmengen__letsgo {
  margin-top: 50px;
}

.help__content.round {
  border-radius: 12px;
}

.help__content.grosse_sendung__content {
  padding: 40px;
}

.models {
  padding: 90px 0 90px 0;
  overflow: hidden;
}

.models__header {
  color: $weiss;
  margin-top: 0;
  margin-bottom: 65px;
  padding-left: $std-padding-mobile;
  padding-right: $std-padding-mobile;
  font-size: 30px;
  line-height: 30px;
}

.models__contrllist {
  width: 320px;
  font-size: 0;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 20px 0 20px;
}

.models__contrl {
  display: inline-block;
  list-style-type: none;
  width: 84px;
  margin: 0;
  margin-left: 14px;
  padding: 0;
  text-align: center;
  font-family: $font_content_normal;
  font-size: 11px;
  line-height: 16px;
  color: $hellblau;
}

.models__contrl:first-child {
  margin-left: 0;
}

.models__contrl--active {
  color: $weiss;
}

.models__contrl-quantum {
  display: block;
  font-family: $font_content_bold;
  font-size: 18px;
}

.models__itemlist {
  position: relative;
  display: table;
  width: 860px;
  height: 525px;
  margin: 0 auto;
  padding: 0;
}

.models__item {
  display: table-cell;
  width: 280px;
  height: 525px;
  font-family: $font_content_normal;
  color: $blau;
}

.models__item--center {
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
}

.models__itemheader {
  display: none;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: $font_content_normal;
  font-size: 21px;
  line-height: 21px;
  color: $hellblau;
}

.models__item_content {
  position: relative;
  width: 280px;
  padding: 30px 10px 30px 10px;
  background-color: $weiss;
  background-image: url(../../images/polygon-model-mobile.svg);
  background-repeat: no-repeat;
  background-size: 280px 170px;
  background-position: center top;
  border-radius: $box-radius;
  font-size: 15px;
}

.models__item_content--kleine,
.models__item_content--mittlere {
  background-image: url(../../images/polygon-model-mobile.svg);
  background-size: 280px 170px;
}

.models__item_content--kleine {
  height: 656px; // 450px // 477
}

.models__item_content--mittlere {
  height: 741px; // 485px ... 535 // 562
}

.models__item_content--hohe {
  height: 826px; // 620px // 647
  background-image: url(../../images/polygon-model-mobile-extended.svg);
  background-size: 280px 200px;
}

.models__descrheader {
  height: 22px;
  color: $blau;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  vertical-align: baseline;
  margin-bottom: 15px;
}

.models__descrheader--double {
  height: 32px;
  margin-bottom: 20px;
}

.models__descrheader_quantum {
  height: 25px;
  font-size: 25px;
  line-height: 25px;
}

.models__itemdescr {
  color: $blau;
  line-height: 20px;
}

.models__item_bottombox {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 250px;
}

.models__features_listheader {
  margin-bottom: 15px;
  text-align: left;
  font-family: $font_header_bold;
  color: $blau;
  line-height: 12px;
}

.models__features_list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  line-height: 22px;
}

.models__innerlist_item {
  padding-left: 28px;
  background-image: url(../../images/models-tick-mark-small.svg);
  background-repeat: no-repeat;
  background-size: 23px 20px;
  background-position: left center;
  margin-bottom: 5px;
}

.models__innerlist_item:last-child {
  height: 22px;
  margin-bottom: 0;
}

.models__innerlist_item--notick {
  padding-left: 0;
  background-image: none;
}

.models__innerlist_item--lchauto:last-child {
  height: auto;
}

.models_innerlist_item--bold {
  font-family: $font_content_bold;
}

.models__itemitem-green {
  font-family: $font_content_normal;
  font-size: 12px;
  color: $gruen;
}

.models__features_listseparator {
  height: 1px;
  margin-top: 12px;
  margin-bottom: 12px;
  opacity: 0;
  background-color: #000000;
}

.models__item_cta {
  width: 220px;
  float: left;
  margin-top: 35px;
}

.models__button_deskcontent {
  display: none;
}

.prices {
  padding: 90px 0 90px 0;
  overflow: hidden;
}

.prices__header {
  color: $dunkelblau;
  margin-top: 0;
  margin-bottom: 45px;
  font-size: 30px;
  line-height: 30px;
}

.prices__customcontrol_list {
  width: 100%;
  max-width: 590px;
  height: 110px;
  margin: 0 auto;
  padding: 0 20px 0 20px;
  font-size: 0;
}

.prices__customcontrol_item {
  display: inline-block;
  width: calc(50% - 10px);
  height: 110px;
  margin: 0 10px 0 10px;
  font-size: 18px;
  line-height: 20px;
  font-family: $font_header_bold;
  color: $dunkelblau;
}

.prices__customcontrol_item:first-child {
  margin-left: 0;
}

.prices__customcontrol_item:last-child {
  margin-right: 0;
}

.prices__customcontrol_button {
  display: table;
  width: 100%;
  height: 95px;
  padding: 17px 21px 21px 21px;
  background-color: $graugruen;
  border-radius: $box-radius;
  cursor: pointer;
}

.prices__customcontrol_buttoncell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.prices__customcontrol_textbreak {
  display: block;
  text-transform: uppercase;
}

.prices__customcontrol_textbreak--privat {
  padding-left: 16.5px;
  padding-right: 16.5px;
}

.prices__customcontrol_textmwst {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  line-height: 12px;
  font-family: $font_header_normal;
}

.prices__customcontrol_arrowdown {
  display: none;
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 15px solid $blau;
  margin: 0 auto;
}

.prices__customcontrol_item:hover > .prices__customcontrol_button {
  background-color: $blau;
  color: $weiss;
}

.prices__customcontrol_item:hover > .prices__customcontrol_arrowdown {
  display: block;
}

.prices__customcontrol_item--active > .prices__customcontrol_button {
  background-color: $blau;
  color: $weiss;
}

.prices__customcontrol_item--active > .prices__customcontrol_arrowdown {
  display: block;
}

.prices__formatcontrol_list {
  width: 280px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 0;
  font-size: 0;
}

.prices__formatcontrol_item {
  display: inline-block;
  width: 33.3333%;
  font-family: $font_content_bold;
  font-size: 18px;
  line-height: 18px;
  color: $weiss;
}

.prices__formatcontrol_item:first-child {
  text-align: left;
}

.prices__formatcontrol_item:nth-child(2) {
  text-align: center;
}

.prices__formatcontrol_item:last-child {
  text-align: right;
}

.prices__formatcontrol_item--active {
  color: $blau;
}

.prices__itemlist {
  position: relative;
  display: table;
  width: 860px;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 0;
}

.prices__item {
  display: table-cell;
  width: 280px;
  height: 444px; // 360px
  vertical-align: middle;
  font-family: $font_content_normal;
  color: $blau;
}

.prices__item--center {
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
}

.prices__item_content {
  width: 280px;
  height: 424px; // 340px
  padding: 25px 40px 0 40px;
  background-color: $weiss;
  border-radius: $box-radius;
  box-shadow: 0 1px 80px 6px rgba(0, 0, 0, 0.1);
}

.prices__item--center .prices__item_content {
  height: 444px; // 360px
  padding: 35px 40px 10px 40px;
}

.prices__item_header {
  margin-bottom: 20px;
  font-family: $font_header_bold;
  font-size: 30px;
  line-height: 30px;
  color: $dunkelblau;
  text-transform: uppercase;
}

.prices__item_trenner {
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.08;
  background-color: $schwarz;
  border: none;
}

.prices__item_pages {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 25px;
  font-family: $font_content_bold;
}

.prices__item_maxpages {
  display: block;
  font-family: $font_content_normal;
  font-size: 16px;
}

.prices__item_format {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}

.prices__item_price {
  height: 112px; // height: 94px, 120px = 26px button space, 112px = 18px button space
  padding-top: 27px;
  font-size: 50px;
  line-height: 37px;
  font-family: $font_header_bold;
  color: $verlaufgruen;
  background-repeat: no-repeat;
  background-size: 159px 120px;
  background-position: center center;
}

.prices__item_pricefrom {
  font-size: 21px;
}

.zzgl-druck {
  display: block;
  margin-top: 10px;
  font-family: $font_content_normal;
  font-size: 13px;
  line-height: 13px;
  color: $dunkelgrau;
}

.prices__item_price--dinlang {
  background-image: url(../../images/format-din-lang.svg);
}

.prices__item_price--dinc4 {
  background-image: url(../../images/format-din-C4.svg);
}

.prices__info {
  margin-top: 10px;
  font-size: 9px;
  color: $dunkelgrau;
}

.prices__item_registeredletter {
  height: 92px;
}

.prices__item_registeredletter_content {
  display: block;
  font-size: 15px;
  line-height: 21px;
}

.prices__ioffer_box {
  width: 280px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 30px 20px 30px 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: $box-radius;
}

.prices__ioffer_header {
  margin-bottom: 20px;
  font-family: $font_header_bold;
  font-size: 18px;
  color: $dunkelblau;
  text-transform: uppercase;
}

.prices__ioffer_text {
  font-size: 13px;
  line-height: 18px;
  color: $blau;
  margin-bottom: 15px;
}

.prices__ioffer_linkbox {
  padding: 0 35px 0 35px;
}

.prices__ioffer_link {
  font-size: 15px;
  color: $gruen;
  text-decoration: none;
}

.prices__ioffer_link--desk {
  display: none;
}

.letsgo {
  padding-top: 90px;
  padding-bottom: 90px;
}

.letsgo__header {
  color: $dunkelblau;
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 30px;
  line-height: 30px;
}

.letsgo__text {
  color: $blau;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 25px;
}

.prices__ioffer_line {
  display: block;
}

.letsgo__ctawrapper,
.default__ctawrapper,
.benefits__ctawrapper {
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 35px;
}

.default__ctawrapper {
  margin-top: 35px;
  margin-bottom: 0;
}

.cockpit__wrapper {
  max-width: 240px;
  margin-top: 25px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.block--austria--lead {
  margin-bottom: 25px;
}

.cockpit__header__pre_header {
  font-size: 16px;
  line-height: 22px;
  color: #022856;
  font-family: FiraSansCondensed, "sans-serif";
  margin-top: 20px;
  text-align: center;
}

.cockpit__austria__header {
  font-size: 25px;
}

.cockpit__ad_block__number {
  width: 305px;
  height: 165px;
  display: block;
  background-color: #96c11e;
  color: #002658;
  text-align: center;
  font-family: FiraSansCondensedSemiBold, "sans-serif";
  padding-top: 17px;
  font-size: 96px;
  margin-right: auto;
  margin-left: auto;

  sup {
    top: -3.01em;
    font-size: 20px;
    text-transform: uppercase;
  }
}

.cockpit__info.cockpit__info__austria {
  padding-top: 5px;
  background-image: none;
}

.cockpit__ad_block__header {
  display: block;
  padding: 15px;
  width: 333px;
  background-color: #002658;
  color: #96c11e;
  text-align: center;
  border-radius: 6px;
  transform: rotate(-3deg);
  margin-top: -15px;
  margin-right: auto;
  margin-left: auto;
  font-family: FiraSansCondensed, "sans-serif";
  font-size: 18px;
}

.cockpit__ad_block__text {
  display: block;
  width: 355px;
  transform: rotate(-3deg);
  padding: 2px;
  font-size: 11px;
  color: #979797;
  text-align: center;
  font-family: SourceSansPro;
  text-transform: uppercase;
  margin-right: auto;
  margin-left: auto;
}

.cockpit__infoline {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
  padding: 15px;
}

.cockpit__infolinecell {
  display: inline-block;
  background-image: url(../../images/active-list.png);
  background-size: 37px 20px;
  padding-left: 42px;
  background-repeat: no-repeat;
  line-height: 21px;
}

.cockpit__infolinecell:not(:first-child) {
  display: none;
}

.cockpit__infolinecell_text {
  font-family: SourceSansPro;
  color: #315171;
  font-size: 13px;
}

.infolinecell--text--bold {
  font-family: FiraSansCondensedSemiBold, "sans-serif";
}

.cockpit__ad_list {
  margin: 15px 0 0 0;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 430px;
}

.cockpit__ad_list__block {
  margin-top: 40px;
  border-top: 2px solid rgba(2, 40, 86, 0.25);
}

.cockpit__ad_list_text__wrapper {
  margin-top: -12px;
}

.cockpit__ad_list__item {
  font-family: FiraSansCondensedSemiBold;
  line-height: 24px;
  text-transform: uppercase;
  color: #022856;
  word-spacing: 1680px;
  margin-bottom: 5px;
  background-image: url(../../images/comparison-tick-mark-large.svg);
  padding-left: 32px;
  background-repeat: no-repeat;
  background-position: 0 3px;
  line-height: 25px;
  margin-bottom: 5px;
  background-size: 20px 16px;
  font-size: 18px;
  display: inline-block;
  width: 45%;
  text-align: left;
}

.cockpit__ad_list_text {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  font-family: FiraSansCondensed, "sans-serif";
  color: #022856;
  padding: 5px 10px;
  background-color: #fff;
}

.benefits__ctawrapper {
  margin-top: 55px;
  margin-bottom: 0;
}

.letsgo__certlist {
  display: table;
  height: 70px;
  padding: 0;
  margin: 0 auto;
  margin-top: 35px;
}

.letsgo__certitem {
  display: table-cell;
  width: 74px;
  height: 74px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.mainnav__cert-klimaneutral {
  width: 70px;
  height: 70px;
}

.mainnav__cert-qualitaetsmanagement {
  width: 50px;
  height: 70px;
}

.block--footer {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: $grau;
}

.block__footergroup {
  padding-top: 20px;
  padding-bottom: 20px;
}

.block__header_desk {
  display: none;
}

.agb__paragraph {
  text-align: left;
  line-height: 18px;
  margin-bottom: 15px;
}

.agb__header {
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 10px;
}

.agb__download {
  margin-bottom: 20px;
  text-align: left;
}

.agb__download > .cta {
  position: relative;
  z-index: 2;
  margin-left: 45px;
}

.agb__download-text--desk {
  display: none;
}

.agb__download_pdficon {
  position: relative;
  top: 10px;
  z-index: 1;
  display: block;
  width: 60px;
  height: 75px;
  background-image: url(../../images/file-pdf.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
}

.faq__list {
  width: 100%;
  margin: 0;
  padding: 0;
  color: $blau;
}

.faq__list.block {
  margin-bottom: 50px;
}

.faq__listitem {
  list-style-type: none;
  border: 1px solid $bordergrau;
  border-radius: $box-radius;
  padding: 15px 12.5px 15px 12.5px;
  margin-bottom: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.faq__listitem:last-child {
  margin-bottom: 0;
}

.faq__listitem--visibel {
  background: linear-gradient(135deg, $blaugrau 33.3333%, $hellgruen 166.6666%);
  background-color: $blaugrau;
}

.faq__listitem_question {
  display: table;
  width: 100%;
  cursor: pointer;
}

.faq__listitem_question-header {
  display: table-cell;
  width: calc(100% - 18px);
  margin: 0;
  padding: 0 10px 0 0;
  text-align: left;
  vertical-align: middle;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 20px;
  color: $dunkelblau;
  text-transform: none;
}

.faq__listitem_question-header--visibel {
  font-family: $font_content_bold;
}

.faq__listitem_question-action {
  display: table-cell;
  width: 18px;
  text-align: left;
  vertical-align: middle;
}

.faq__listitem_question-icon {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../../images/faq-is-closed.svg);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center center;
}

.faq__listitem_question-icon--open {
  background-image: url(../../images/faq-is-open.svg);
}

.faq__listitem_answer {
  font-family: SourceSansPro;
  display: none;
  height: 0;
  padding-top: 20px;
  text-align: left;
  line-height: 18px;
  -webkit-transition: height 2s;
  transition: height 2s;
}

.faq__listitem_answer p {
  font-size: 13px;
  margin-bottom: 13px;
}

.faq__listitem_answer p:last-child {
  margin-bottom: 0;
}

.faq__listitem_answer .faq_jump {
  cursor: pointer;
}

.faq__listitem_answer--visibel {
  display: block;
  height: auto;
  -webkit-transition: height 2s;
  transition: height 2s;
}

.faq__afterheader {
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 24px;
  color: $dunkelblau;
  text-transform: uppercase;
  margin-top: 47.5px;
  margin-bottom: 20px;
}

.faq__afterinfo {
  font-size: 15px;
  line-height: 22px;
  color: $dunkelblau;
  padding-bottom: 40px;
}

.faq__afterinfo-mobileline {
  display: block;
}

.imprint {
  min-height: 250px;
  background-image: url(../../images/imprint-background.svg);
  background-repeat: no-repeat;
  background-size: auto 117px;
  background-position: center 42px;
}

.imprint__list {
  width: 100%;
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 1px solid $bordergrau;
  font-family: $font_content_normal;
  font-size: 0;
  line-height: 23px;
  color: $blau;
}

.imprint__list:last-of-type {
  padding-bottom: 10px;
  margin-bottom: 0;
  border-bottom: 0;
}

.imprint__listitem_term,
.imprint__listitem_description {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-size: 15px;
  text-align: left;
}

.imprint__listitem_description:last-of-type {
  margin-bottom: 0;
}

.imprint__listitem_term {
  font-family: $font_content_bold;
}

.imprint__listitem_description {
  margin-bottom: 30px;
}

.p--notactivated {
  margin-top: 10px;
  font-size: 15px;
}

.recaptcha-error {
  color: $rot;
  font-family: $font_content_normal;
  text-align: left;
  margin-bottom: 20px;
  display: none;
}

.contact-success {
  text-align: left;
  font-family: $font_content_normal;
  margin-bottom: 20px;
  display: none;
  color: $gruen;
}

.grecaptcha-badge {
  z-index: 999;
}

.flexdescrlist {
  width: 100%;
  font-family: $font_content_normal;
  font-size: 0;
  line-height: 23px;
  color: $blau;
}

.flexdescrlist--bottomborder {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid $bordergrau;
}

.flexdescrlist--bottomborder:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.flexdescrlist--imprint:last-of-type {
  padding-bottom: 10px;
}

.flexdescrlist__term,
.flexdescrlist__description {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-size: 15px;
  text-align: left;
}

.flexdescrlist__description:last-of-type {
  margin-bottom: 0;
}

.flexdescrlist__term {
  font-family: $font_content_bold;
}

.flexdescrlist__description {
  margin-bottom: 30px;
}

.infobadge {
  position: relative;
  top: -1px;
  left: 10px;
  width: 46px;
  height: 22px;
  padding: 0;
  border: none;
  border-radius: 3px;
  background-color: $eyecatcherorange;
  font-family: $font_content_normal;
  font-size: 12px;
  line-height: 22px;
  color: $weiss;
  text-align: center;
}

.infobadge--prices {
  display: inline;
  top: 0;
  left: 0;
  margin-bottom: 5px;
}

.infobadge--campaigntitle {
  display: inline;
  top: 0;
  left: 0;
  width: 56px;
  height: 28px;
  border-radius: 3px;
  font-size: 15px;
  line-height: 28px;
}

.text--content-bold {
  font-family: $font_content_bold;
}

.text--content-semibold {
  font-family: $font_content_semibold;
}

.text--content-normal {
  font-family: $font_content_normal;
}

.text--content-bold-darkblue {
  font-family: $font_content_bold;
  color: $dunkelblau;
}

.text--content-semibold-darkblue {
  font-family: $font_content_semibold;
  color: $dunkelblau;
}

.text--content-underline {
  text-decoration: underline;
}

.text--header-bold {
  font-family: $font_header_bold;
}

.text--header-normalized {
  font-family: $font_header_normal;
  text-transform: none;
}

.text--underline {
  text-decoration: underline;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--wihte {
  color: $weiss;
}

.text--green {
  color: $gruen;
}

.text--content-green-semibold {
  color: $gruen;
  font-family: $font_content_semibold;
}

.text--mobile-line,
.text--block {
  display: block;
}

.text--size-sm90 {
  font-size: 90%;
}

.deactivate-button {
  opacity: 0.4 !important;
}

.display--none {
  display: none;
}

.img--block-resp {
  display: block;
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 380px) {
  .cockpit__header--normal {
    white-space: nowrap;
  }

  .cockpit__header--bold {
    white-space: nowrap;
  }

  .cockpit__infocell {
    padding: 78px 10px 30px 10px; // 78px 5px 35px 5px
  }
}

@media screen and (min-width: 390px) {
  .flexdescrlist__term {
    width: 110px;
    margin-bottom: 30px;
  }

  .flexdescrlist__description {
    width: calc(100% - 110px);
  }

  .flexdescrlist__term:last-of-type {
    margin-bottom: 0;
  }

  .teaser__ctawrapper {
    //position: relative;
    //z-index: 9999;
    width: 350px; // 260px
    height: 173px; // 168px
    //margin: 0 auto;
    //margin-top: 20px;
    padding-top: 123px; // 115px
    background-image: url(../../images/teaser-stoerer-202203-350.png);
    //background-repeat: no-repeat;
    background-size: 350px 123px; // background-size: 210px 118px
    //background-position: center top;
  }
}

@media screen and (min-width: 450px) {
  .form__label--contacttime {
    float: left;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 475px) {
  .sparpo__detailrow {
    width: 50%;
    display: inline-block;
    float: left;
  }

  .sparpo__detailcell {
    width: 50%;
    display: inline-block;
    float: left;
  }

  .sparpo__detailbox {
    height: 58px;
  }

  .sparpo__detailbox--kosten {
    padding: 0 0 0 25px;
  }

  .sparpo__detailbox--arbeitszeit {
    padding: 0 0 0 25px;
  }

  .sparpo__detailbox--druckertinte {
    padding: 0 0 0 25px;
    background-position-y: 0;
  }

  .sparpo__detailbox--co2 {
    padding: 0 0 0 25px;
    background-position-y: 0;
  }

  .prices__ioffer_line {
    display: inline;
  }

  .block__header--contact {
    margin-bottom: 46px;
  }

  .content__teaser__pinsign {
    padding: 40px 45px;
  }

  .teaser__pinsign__text {
    font-size: 26px;
    line-height: 32px;
  }

  .content__teaser__pinsign .cta--large .cta__text {
    font-size: 22px;
  }
}

@media screen and (min-width: 564px) {
  .eyecatcher__text {
    font-size: 21px;
  }

  .eyecatcher__text--short {
    display: none;
  }

  .eyecatcher__text--long {
    display: block;
  }
}

@media screen and (min-width: 630px) {
  .imprint__listitem_term {
    width: 35%;
    margin-bottom: 30px;
  }

  .imprint__listitem_description {
    width: 65%;
  }

  .imprint__listitem_term:last-of-type {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 640px) {
  .comparison__header {
    margin-bottom: 52px; // + 8px from line-height
  }

  .comparison__contrllist {
    display: none;
  }

  .comparison__itemlist {
    position: relative;
    width: 100%;
    max-width: 610px;
  }

  .comparison__item {
    display: table-cell;
    width: 50%;
    padding: 0;
  }

  .comparison__iteminner {
    float: left;
    width: 280px;
  }

  .comparison__item:last-child > .comparison__iteminner {
    float: right;
  }

  .comparison__iteminner:hover {
    opacity: 1;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .cbox--person {
    margin-top: 20px;
    overflow: hidden;
    padding-top: 20px;
  }

  .cbox--person .leadform__person__contact {
    margin-top: 0;
  }

  .cbox--person .leadform__person__image {
    width: 35%;
    float: left;
  }

  .cbox--person .leadform__person__conatct_section {
    width: 60%;
    float: right;
  }
}

@media screen and (min-width: 768px) {
  .before {
    display: block;
  }

  //  .main {
  //    padding-top: $nav-desktop-height;
  //  }

  //  .main--sticky {
  //    padding-top: 100px;
  //  }

  //  .topinfo {
  //    display: block;
  //  }

  .block__content {
    padding: $std-padding-desktop;
  }

  .block__wrapped-background--login,
  .block__wrapped-background--registration,
  .block__wrapped-background--contact,
  .block__wrapped-background--faq,
  .block__wrapped-background--imprint,
  .block__wrapped-background--privacy {
    height: 510px;
  }

  .block__header {
    margin-top: 70px; // 40px
    font-size: 50px; // 30px
  }

  .block__header--login {
    margin-top: 85px;
    margin-bottom: 90px;
  }

  .block__header--faq {
    margin-top: 57.5px;
    margin-bottom: 22.5px;
  }

  .block__header--registration {
    line-height: 36px;
    margin-top: 85px;
    margin-bottom: 100px;
  }

  .block__descr {
    font-size: 25px;
    margin-bottom: 115px;
  }

  .block__header--imprint {
    line-height: 36px;
    margin-top: 70px;
    margin-bottom: 85px;
  }

  .block__header--contact {
    line-height: 36px;
    margin-top: 70px;
    margin-bottom: 40px;
  }

  .block__header--contact-success {
    line-height: 36px;
    margin-top: 90px;
    margin-bottom: 80px;
  }

  .block__header--privacy {
    line-height: 36px;
    margin-top: 70px;
    margin-bottom: 85px;
  }

  .block__descr--nfm {
    display: block;
  }

  .block__wrapped-content {
    padding: 15px $std-padding-desktop 70px $std-padding-desktop;
  }

  .login,
  .registration {
    min-height: 510px;
    background-size: auto 390px;
    background-position: center -35px;
  }

  .login {
    padding-bottom: 350px;
  }

  .contact {
    min-height: 510px;
    background-size: auto 440px;
    background-position: center -60px;
  }

  .contact__teaser-first,
  .contact__teaser-second {
    display: block;
  }

  .faq {
    min-height: 510px;
    background-size: auto 355px;
    background-position: center -15px;
  }

  .imprint {
    min-height: 250px;
    background-size: auto 270px;
    background-position: center 46px;
  }

  .imprint__listitem_term,
  .imprint__listitem_description {
    font-size: 18px;
  }

  .privacy {
    min-height: 250px;
    background-size: auto 270px;
    background-position: center 46px;
  }

  .privacy__list {
    font-size: 18px;
    line-height: 23px;
  }

  .cboxcontainer {
    display: table;
  }

  .cboxcontainer__child {
    display: table-cell;
  }

  .cboxcontainer__child--contact-left {
    width: 340px;
    padding-right: 30px;
  }

  .cboxcontainer__child--contact-right {
    width: calc(100% - 340px);
  }

  .cbox {
    padding: 60px;
    margin-top: 0;
  }

  .cbox--person {
    padding: 15px;
    height: 655px;
    margin-bottom: 20px;
  }

  .cbox--contact-left,
  .cbox--contact-right {
    padding: 60px 40px 60px 40px;
  }

  .cbox--p-std p {
    line-height: 23px;
  }

  .cbox__account {
    padding: 20px;
  }

  .cbox__wrapper {
    display: flex;
    justify-content: center;

    .cbox {
      margin-left: 0;
      margin-right: 0;
    }

    .cbox--person {
      margin-right: 20px;
    }
  }

  .cbox--mw500200 {
    max-width: 270px;
  }

  .cbox__header,
  .cbox__subheader,
  .cbox__subsubheader {
    margin-top: 0;
    margin-bottom: 40px;
    padding-top: 3px;
    font-size: 25px;
    line-height: 27px;
  }

  .cbox__subheader {
    margin-top: 40px;
  }

  .cbox__subsubheader {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 23px;
    line-height: 25px;
  }

  .cboxes {
    display: table;
  }

  .cboxes > .cbox {
    display: table-cell;
  }

  .cbox--data {
    width: 360px;
    margin-right: 30px;
  }

  .cbox--form {
    width: calc(100% - 390px);
  }

  .registeredletterinfo {
    padding: 0 $std-padding-desktop 0 $std-padding-desktop;
  }

  .teaser {
    padding-top: 70px;
    padding-bottom: 30px; // + 60 vom block
  }

  .teaser__header {
    font-size: 50px;
    line-height: 55px;
  }

  .teaser__header--bold {
    font-size: 50px;
  }

  //.eyecatcher__text {
  //  font-size: 21px;
  //  line-height: 48px;
  //}

  .teaser__first {
    font-size: 25px;
    line-height: 35px;
  }

  //.teaser__ctawrapper,
  .contact__ctawrapper {
    width: 360px;
    height: 230px;
    margin: 0 auto;
    margin-top: 20px;
    padding-top: 169px;
    background-size: 318.175px 170px;
  }

  .teaser__ctawrapper {
    //position: relative;
    //z-index: 9999;
    width: 504px; // 360px
    height: 237px; // 230px
    //margin: 0 auto;
    //margin-top: 20px;
    padding-top: 177px; // 169px
    background-image: url(../../images/teaser-stoerer-202203-504.png);
    //background-repeat: no-repeat;
    background-size: 504px 177px; // background-size: 210px 118px
    //background-position: center top;
  }

  .block--austria-background {
    background-image: url(../../images/Grafik_2x.png);
    background-position: right 70px top 90px;
    background-repeat: no-repeat;
    background-size: 270px;
  }

  .block--austria--lead {
    text-align: left;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    padding-bottom: 60px;
  }

  .cockpit__header__pre_header {
    font-size: 22px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #022856;
    font-family: FiraSansCondensed, "sans-serif";
  }

  .cockpit__ad_block_wrapper {
    display: flex;
  }

  .cockpit__ad_list_text__wrapper {
    margin-top: 12px;
  }

  .cockpit__ad_block__number {
    width: 355px;
    height: 165px;
    display: block;
    background-color: #96c11e;
    color: #002658;
    text-align: center;
    font-family: FiraSansCondensedSemiBold, "sans-serif";
    padding-top: 17px;
    font-size: 106px;

    sup {
      top: -3.01em;
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  .cockpit__ad_block__header {
    display: block;
    padding: 15px;
    width: 380px;
    background-color: #002658;
    color: #96c11e;
    text-align: center;
    border-radius: 6px;
    transform: rotate(-3deg);
    margin-top: -15px;
    font-family: FiraSansCondensed, "sans-serif";
    font-size: 18px;
  }

  .cockpit__ad_block__text {
    display: block;
    width: 330px;
    transform: rotate(-3deg);
    padding: 2px;
    font-size: 11px;
    color: #979797;
    text-align: center;
    font-family: SourceSansPro;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
  }

  .cockpit__ad_list__block {
    display: inline-block;
    margin-left: 55px;
    margin-top: 10px;
    border-top: none;
  }

  .cockpit__ad_block {
    display: inline-block;
  }

  .cockpit__header.cockpit__austria__header {
    margin-left: 10%;
    margin-top: 50px;
    max-width: 420px;

    .cockpit__header--inner {
      white-space: normal;
    }
  }

  .cockpit__ad_block {
    margin-left: 10%;
  }

  .cockpit__ad_list {
    margin: 15px 0 0 0;
    padding: 0;
    list-style: none;
    width: auto;
    max-width: none;
  }

  .cockpit__ad_list__item {
    font-family: FiraSansCondensedSemiBold;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    color: #022856;
    word-spacing: 1680px;
    margin-bottom: 5px;
    background-image: url(../../images/comparison-tick-mark-large.svg);
    background-size: 27px 23px;
    padding-left: 32px;
    background-repeat: no-repeat;
    background-position: 0 0;
    line-height: 25px;
    margin-bottom: 12px;
    display: block;
    width: auto;
    text-align: left;
  }

  .cockpit__ad_list_text {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    font-family: FiraSansCondensed, "sans-serif";
    color: #022856;
    padding: 0;
    background-color: transparent;
  }

  .infolinecell--text--bold {
    font-family: FiraSansCondensedSemiBold, "sans-serif";
  }

  .cockpit__ad_cta__block {
    text-align: right;
  }

  .cockpit {
    padding-top: 55px; // + 15px from line-height
  }

  .cockpit__header {
    max-width: 588px;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 40px; // + 15px from line-height
  }

  .cockpit__header__double {
    margin-top: 5px;
  }

  .cockpit__info {
    max-width: 600px;
    margin-bottom: 45px;
    padding-top: 350px;
    background-size: 382px 307px;
  }

  .cockpit__infocell {
    width: 265px;
    padding: 13px 10px 30px 85px; // 78px 5px 35px 5px
    background-position: left top;
  }

  .cockpit__infocell--left {
    width: 335px;
    padding-right: 80px; // 35px
  }

  .cockpit__infocell--right {
  }

  .cockpit__infocell_header {
    text-align: left;
  }

  .cockpit__infocell_text {
    max-width: 180px;
    margin: 0;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
  }

  .cockpit__infolinecell_text {
    font-size: 16px;
  }

  .cockpit__down {
    display: table;
    top: 10px;
  }

  .comparison__header {
    font-size: 25px;
    line-height: 37px;
    margin-bottom: 60px; // + 10px from line-height
  }

  .grosse_sendungsmengen__content__comparison {
    margin-left: 15%;
  }

  .references {
    padding-bottom: 100px;
  }

  .references--home {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .references__header {
    margin-top: 20px; // + 8px from line-height
    //line-height: 28px;
  }

  .references__header--first,
  .references__header--second {
    display: inline;
  }

  .leadform {
    padding-top: 72px;
  }

  .leadform__header {
    font-size: 50px;
    line-height: 60px;
  }

  .leadform__secondheader {
    font-size: 18px;
    line-height: 37px;
    margin-bottom: 72px;
  }

  .companyinfo {
    padding: 8rem;
  }

  .companyinfo__header{
    font-size: 50px;
    line-height: 60px;
  }
  .companyinfo > .help__info {
    text-align: center;
    font-size: 20px !important;
    line-height: 25px !important;
  }

  .online_schreiben__icon {
    width: 140px;
    height: 140px;
    background-size: 70px auto;
  }

  .online_schreiben__icon--wrapper {
    width: 200px;
    height: 200px;
  }

  .online_schreiben__icon {
    &:hover {
      width: 165px;
      height: 165px;
      background-size: 90px auto;
    }
  }

  .online_schreiben__icon.online_schreiben__icon--address_book,
  .online_schreiben__icon.online_schreiben__icon--personal_header {
    background-size: 55px auto;
    &:hover {
      background-size: 80px auto;
    }
  }

  .online_schreiben__icon.online_schreiben__icon--icloud_sync {
    background-size: 45px auto;
    &:hover {
      background-size: 65px auto;
    }
  }

  .online_schreiben__list_item {
    width: 280px;
    height: 280px;
  }

  --end-- .sparpo,
  .steps {
    padding-bottom: 90px;
  }

  .sparpo__bpmvalue {
    font-size: 80px;
  }

  .sparpo__bpmlabel {
    font-size: 24px;
  }

  .sparpo__resulttable {
    margin-top: 50px;
  }

  .sparpo__format--versandlabel {
    position: relative;
    top: -3px;
  }

  .sparpo__format--pinlabel {
    position: relative;
    top: -10px;
  }

  .sparpo__resultcell--versand {
    font-size: 25px;
    line-height: 25px;
  }

  .sparpo__format--strikethrough {
    line-height: 25px;
  }

  .sparpo__resultcell--pin {
    font-size: 50px;
    line-height: 40px;
  }

  .sparpo__format--fzeurosep {
    font-size: 25px;
  }

  .sparpo__detailtable {
    padding: 0 35px 35px 35px;
  }

  .sparpo__detailtable-caption {
    padding: 35px;
    font-size: 25px;
  }

  .sparpo__detailcell {
    font-size: 24px;
  }

  .sparpo__detailbox {
    height: 88px;
    padding-left: 35px;
    background-size: auto 88px;
  }

  .sparpo__detaillabel {
    margin-top: 17px;
    font-size: 15px;
    line-height: 28px;
  }

  .sparpo__grundlage {
    font-size: 12px;
    line-height: 15px;
  }

  .steps {
    padding-top: 135px;
    padding-bottom: 135px;
  }

  .steps__header {
    margin-bottom: 25px;
    font-size: 50px;
    line-height: 50px;
  }

  .steps__first {
    font-size: 25px;
  }

  .steps__boxwrapper {
    max-width: 360px;
    height: 350px;
  }

  .steps__boxwrapper--step1 {
    height: 368px;
  }

  .steps__boxwrapper--step2 {
    height: 375px;
  }

  .steps__boxwrapper--step3 {
    height: 400px;
  }

  .steps__headerbox {
    height: 178px;
  }

  .steps__boxshape {
    height: 178px;
  }

  .steps__boxtriangle {
    width: 360px;
    height: 96px;
  }

  .steps__textbox {
    padding: 14px 30px 35px 30px;
  }

  .steps__textbox-header {
    font-size: 25px;
  }

  .steps__textbox-text {
    font-size: 18px;
    line-height: 25px;
  }

  .benefits {
    padding-top: 110px;
    padding-bottom: 55px; // 110px
  }

  .benefits__header {
    margin-bottom: 90px;
    font-size: 50px;
    line-height: 50px;
  }

  .models {
    padding: 125px $std-padding-desktop 160px $std-padding-desktop;
  }

  .models__header {
    margin-bottom: 70px;
    font-size: 50px;
    line-height: 50px;
  }

  .models__itemdescr {
    font-size: 15px;
  }

  .prices {
    padding: 120px 0 120px 0;
  }

  .prices__header {
    margin-bottom: 70px;
    font-size: 50px;
    line-height: 50px;
  }

  .prices__info {
    font-size: 12px;
  }

  .letsgo {
    padding-top: 105px;
    padding-bottom: 90px;
  }

  .letsgo__header {
    margin-bottom: 30px;
  }

  .letsgo__text {
    margin-bottom: 45px;
    font-size: 25px;
    line-height: 27px;
  }

  .block__footergroupwrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  .block__footergroup {
    display: inline-block;
  }

  //.footergroup_linklistitem {
  //  width: 150px;
  //}

  //.footergroup_header {
  //  font-size: 18px;
  //  line-height: 25px;
  //  text-align: left;
  //}

  .block__footergroup--pininfo {
    float: right;
  }

  //.footergroup_logo {
  //  width: 145px;
  //}

  .block__footergroup--links {
    float: left;
  }

  //.footergroup_linklist {
  //  float: none;
  //}

  //.footergroup_linklist--first {
  //  margin-right: 0;
  //}

  //.footergroup_linklist--second {
  //  margin-top: 10px;
  //  margin-left: 0;
  //}

  //.footergroup_link {
  //  transition: padding-left .5s;
  //}

  //.footergroup_link:hover {
  //  padding-left: 15px;
  //  color: $gruen;
  //}

  .block--triangle-negative-lightgrey-circle-circle {
    background-size: 1680px 75.001px, 570px 428px;
  }

  .block--circle-circle {
    background-size: 525px auto;
    background-position: center top 157px;
  }

  .block__header_desk {
    display: block;
    line-height: 60px;
  }

  .block__header_mobile {
    display: none;
  }

  .agb__paragraph {
    text-align: left;
    line-height: 21px;
    margin-bottom: 20px;
  }

  .agb__header {
    font-size: 21px;
    line-height: 21px;
  }

  .agb__download {
    margin-bottom: 0;
  }

  .agb__download-text--desk {
    display: inline;
  }

  .faq__listitem {
    padding: 20px;
  }

  .faq__listitem_question-header {
    padding: 0 15px 0 0;
    font-size: 18px;
    line-height: 22px;
  }

  .faq__listitem_answer {
    line-height: 18px;
    font-size: 15px;
  }

  .faq__listitem_answer ul {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .faq__listitem_answer li,
  .faq__listitem_answer p {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .faq__afterheader {
    font-size: 25px;
    line-height: 22px;
    margin-top: 75px;
  }

  .faq__afterinfo {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 15px;
  }

  .faq__afterinfo-mobileline {
    display: inline;
  }
}

@media screen and (min-width: 790px) {
  .cbox--mw385 {
    width: 43%;
    float: left;
  }

  .cbox--mw680 {
    width: 53% !important;
    margin-left: 30px;
    float: left;
  }

  .online_schreiben__list_item.three {
    width: 280px;
  }
}

@media screen and (min-width: 848px) {
  .cboxcontainer__child--contact-left {
    width: 380px;
  }

  .cboxcontainer__child--contact-right {
    width: calc(100% - 380px);
  }

  .cbox--contact-left,
  .cbox--contact-right {
    padding: 60px;
  }
}

@media screen and (min-width: 870px) {
  .models__item {
    vertical-align: bottom;
  }

  .prices__item_content {
    height: 444px; // 360px
    padding: 35px 40px 10px 40px;
  }
}

@media screen and (min-width: 899px) {
  .block--circle-circle {
    background-position: center top 125px;
  }
}

@media screen and (min-width: 920px) {
  .models__item--center {
    width: 320px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 960px) {
  .steps__stepboxes {
    height: 388px;
    margin-top: 50px;
    font-size: 0;
  }

  .steps__boxwrapper {
    display: inline-block;
    max-width: 280px;
    margin-top: 0;
    margin-right: 30px;
  }

  .steps__boxwrapper:last-child {
    margin-right: 0;
  }

  .steps__boxwrapper--step1 {
    height: 331px;
    padding-top: 18px;
  }

  .steps__boxwrapper--step2 {
    height: 338px;
    padding-top: 23px;
  }

  .steps__boxwrapper--step3 {
    height: 363px;
    padding-top: 50px;
  }

  .steps__headerbox {
    height: 167px;
  }

  .steps__boxshape {
    height: 167px;
  }

  .steps__boximage--step1 {
    top: -18px;
    width: auto;
  }

  .steps__boximage--step2 {
    top: -23px;
    width: auto;
  }

  .steps__boximage--step3 {
    top: -50px;
    width: auto;
  }

  .steps__boxtriangle {
    width: 280px;
    height: 75px;
  }

  .steps__textbox {
    padding: 10px 20px 30px 20px;
  }

  .steps__textbox-header {
    font-size: 18px;
  }

  .steps__textbox-text {
    font-size: 15px;
    line-height: 22px;
  }

  .benefits__listwrapper {
    height: 415px;
  }

  .benefits__contrl {
    display: none;
  }

  .benefits__list {
    height: 415px;
  }

  .benefits__item {
    width: 180px;
    height: 415px;
    font-size: 18px;
  }

  .benefits__item:hover .benefits__itemicon {
    border-color: $weiss;
    background-size: 110px auto;
  }

  .benefits__itemheader {
    font-size: 25px;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .benefits__itemtext {
    max-width: 150px;
    font-size: 18px;
    line-height: 23px;
  }

  .benefits__ihre {
    font-family: $font_header_bold;
  }

  .benefits__deskline {
    display: block;
  }

  .benefits__deskcontent {
    display: inline;
  }

  .benefits__mobilecontent {
    display: none;
  }

  .cockpit__info__austria .cockpit__infocell {
    display: table-cell;
    width: auto;
  }

  .models__itemlist {
    width: 900px;
    height: 641px;
  }

  .models__item {
    height: 641px;
  }

  .models__itemheader {
    display: block;
  }

  .models__itemheader-quantum {
    font-family: $font_content_bold;
  }

  .models__item--mittlere {
    width: 340px;
    padding: 0 30px 0 30px;
  }

  .models__item_content {
    background-color: transparent;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  .models__item_content--kleine {
    height: 609px; // 477px
    background-image: url(../../images/polygon-model-tab-kleine.svg);
    background-size: 280px 609px; // background-size: 280px 477px
  }

  .models__item:hover .models__item_content--kleine {
    background-image: url(../../images/polygon-model-tab-kleine-hover.svg);
  }

  .models__item_content--mittlere {
    height: 699px; // 567px
    background-image: url(../../images/polygon-model-tab-mittlere.svg);
    background-size: 280px 699px; // background-size: 280px 567px
  }

  .models__item:hover .models__item_content--mittlere {
    background-image: url(../../images/polygon-model-tab-mittlere-hover.svg);
  }

  .models__item_content--hohe {
    height: 789px; // 657px
    background-image: url(../../images/polygon-model-tab-hohe.svg);
    background-size: 280px 789px; // background-size: 280px 657px
  }

  .models__item:hover .models__item_content--hohe {
    background-image: url(../../images/polygon-model-tab-hohe-hover.svg);
  }

  .models__descrheader,
  .models__itemdescr,
  .models__features_listheader,
  .models__item_bottombox {
    color: $weiss;
  }

  .models__item:hover .models__itemheader {
    color: $weiss;
  }

  .models__item:hover .models__descrheader,
  .models__item:hover .models__itemdescr,
  .models__item:hover .models__features_listheader,
  .models__item:hover .models__item_bottombox {
    color: $blau;
  }

  .teaser__pinsign__picture {
    position: absolute;
    right: 50px;
    top: 50px;
    max-width: 350px;
  }

  .content__teaser__pinsign {
    width: 100%;
    max-width: 1000px;
  }

  .prices {
    padding: 120px 0 120px 0;
  }

  .prices__itemlist {
    padding: 50px 20px 50px 20px;
    background-color: #f0f0f0;
    border-radius: $box-radius;
    box-shadow: 0 0 50px 6px rgba(0, 0, 0, 0.1);
  }

  .prices__item {
    padding: 10px;
  }

  .prices__item_content {
    width: 260px;
    padding: 35px 30px 10px 30px;
    background-color: #f0f0f0;
    border: 1px solid $mittelgrau;
    box-shadow: none;
  }

  .prices__item:hover {
    padding: 0;
  }

  .prices__item:hover .prices__item_content {
    width: 280px;
    height: 464px; // 380px
    padding: 25px 20px 0 20px;
    background-color: $weiss;
    border: 11px solid $weiss;
    box-shadow: 0 1px 80px 6px rgba(0, 0, 0, 0.1);
  }

  .prices__item--center {
    width: 340px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .prices__item--center:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .prices__item:hover .prices__item_header {
    font-size: 33px;
    line-height: 33px;
  }

  .prices__item:hover .prices__item_pages {
    font-size: 20px;
    line-height: 27px;
  }

  .prices__item:hover .prices__item_maxpages {
    font-size: 18px;
  }

  .prices__item:hover .prices__item_format {
    font-size: 18px;
  }

  .prices__item:hover .prices__item_price {
    height: 132px;
    padding-top: 33px;
    font-size: 55px;
    line-height: 42px;
    background-size: 175px 132px;
  }

  .prices__item_pricefrom {
    font-size: 23px;
  }

  .prices__item:hover .zzgl-druck {
    font-size: 15px;
    line-height: 15px;
  }

  .prices__ioffer_box {
    width: 100%;
    max-width: 830px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 40px 30px 40px 30px;
  }

  .prices__ioffer_header {
    font-size: 25px;
  }

  .prices__ioffer_text {
    font-size: 15px;
    line-height: 21px;
    color: $blau;
    margin-bottom: 20px;
  }

  .prices__ioffer_linkbox {
    padding: 0;
  }

  .prices__ioffer_link {
    font-size: 18px;
    color: $gruen;
    text-decoration: none;
  }

  .prices__ioffer_link--desk {
    display: inline;
  }

  .infobadge {
    top: -1px;
  }
}

@media screen and (min-width: 1036px) {
  .prices__itemlist {
    max-width: 976px;
    padding: 50px 39px 50px 39px;
  }

  .prices__item {
    padding: 13px;
  }

  .prices__item_content {
    width: 266px;
    padding: 35px 30px 10px 30px;
    background-color: #f0f0f0;
    border: 1px solid $mittelgrau;
    box-shadow: none;
  }

  .prices__item:hover {
    padding: 0;
  }

  .prices__item:hover .prices__item_content {
    width: 292px;
    height: 470px; // 386px
    border: 14px solid $weiss;
  }

  .prices__item--center {
    width: 340px;
    padding-left: 23px;
    padding-right: 23px;
  }

  .prices__item--center:hover {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 1057px) {
  .cockpit__header {
    max-width: 100%;
  }

  .cockpit__header--normal {
    letter-spacing: -2px;
  }

  .cockpit__info {
    max-width: 997px;
    padding-top: 5px;
    padding-right: 442px;
    background-size: 382px 307px;
    background-position: right center;
  }

  .cockpit__info.cockpit__info__austria {
    padding-top: 5px;
    padding-right: 5px;
    background-image: none;
  }
}

@media screen and (min-width: 1091px) {
  .block--circle-circle {
    background-position: center top 70px;
  }
}

@media screen and (min-width: 1100px) {
  .comparison__header--first,
  .comparison__header--second {
    display: inline;
  }

  .comparison__itemlist {
    max-width: 970px;
  }

  .comparison__iteminner {
    width: 460px;
  }

  .comparison__iteminner {
    padding: 15px 35px 35px 35px;
  }

  .comparison__prelude {
    display: block;
  }

  .comparison__itemheader {
    margin-bottom: 30px;
  }

  .comparison__itemadvice {
    height: 75px;
    font-size: 18px;
    line-height: 25px;
  }

  .comparison__itemsubheader {
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .comparison__item_sublist--advantages {
    height: 477px;
  }

  .comparison__item_sublist--needs {
    height: 226px;
  }

  .comparison__item_sublistitem {
    background-image: url(../../images/comparison-tick-mark-large.svg);
    background-size: 23px 23px;
    font-family: $font_content_semibold, $font_fallback;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0;
    margin-bottom: 25px;
  }

  .comparison__item_sublist--needs .comparison__item_sublistitem {
    background-image: url(../../images/comparison-arrow-right-large.svg);
  }

  .comparison__item_subsublist {
    margin-top: 10px;
  }

  .comparison__item_subsublistitem {
    padding-left: 9px;
    background-image: url(../../images/comparison-subsublistitem-dot-large.svg);
    background-size: 4px 22px;
    font-family: $font_content_normal, $font_fallback;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0;
  }
}

@media screen and (min-width: 1120px) {
  .block--shadowuntil-1120 {
    box-shadow: none;
  }
}

@media screen and (min-width: 1200px) {
  .models__itemlist {
    width: 1140px;
    height: 790px;
  }

  .models__item {
    width: 360px;
  }

  .models__item_content {
    width: 360px;
    padding: 40px 10px 0 10px;
    font-size: 18px;
  }

  .models__itemheader {
    font-size: 25px;
  }

  .models__item--mittlere {
    width: 420px;
  }

  .models__item_content--kleine {
    height: 735px; // 567px
    background-image: url(../../images/polygon-model-desk-kleine.svg);
    background-size: 360px 735px; // background-size: 360px 567px;
  }

  .models__item:hover .models__item_content--kleine {
    background-image: url(../../images/polygon-model-desk-kleine-hover.svg);
  }

  .models__item_content--mittlere {
    height: 840px; // 672px
    background-image: url(../../images/polygon-model-desk-mittlere.svg);
    background-size: 360px 840px; // background-size: 360px 672px;
  }

  .models__item:hover .models__item_content--mittlere {
    background-image: url(../../images/polygon-model-desk-mittlere-hover.svg);
  }

  .models__item_content--hohe {
    height: 945px; // 777px
    background-image: url(../../images/polygon-model-desk-hohe.svg);
    background-size: 360px 945px; // background-size: 360px 777px;
  }

  .models__item:hover .models__item_content--hohe {
    background-image: url(../../images/polygon-model-desk-hohe-hover.svg);
  }

  .models__itemheader {
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 25px;
  }

  .models__descrheader {
    height: 29px;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .models__descrheader.models__descrheader--double {
    height: 50px;
    margin-bottom: 15px;
  }

  .models__descrheader_quantum {
    height: 29px;
    font-size: 37px;
    line-height: 29px;
  }

  .models__itemdescr {
    font-size: 18px;
    line-height: 25px;
  }

  .models__item_bottombox {
    width: 300px;
    bottom: 5px;
  }

  .models__features_listheader {
    margin-bottom: 20px;
    padding: 0 10px 0 10px;
    font-size: 18px;
    line-height: 15px;
  }

  .models__features_list {
    margin-bottom: 0;
    padding: 0 10px 0 10px;
  }

  .models__features_listseparator {
    margin-top: 30px;
    margin-bottom: 30px;
    opacity: 0.08;
  }

  .models__itemitem-green {
    font-size: 18px;
  }

  .models__item_cta {
    width: 300px;
    margin-top: 35px;
    margin-bottom: 15px;
    padding: 0 10px 0 10px;
  }

  .models__button_deskcontent {
    display: inline;
  }

  .block__header_desk {
    display: inline;
    line-height: 36px;
  }

  .leadform__header--first,
  .leadform__header--second {
    display: inline;
  }

  .leadform__secondheader--first {
    display: inline;
  }

  .infobadge {
    top: -2px;
  }

  .testimonials__listwrapper {
    height: 215px;
  }

  .testimonials__item {
    padding-bottom: 0;
    padding-top: 0;
  }

  .testimonials__item--image {
    float: left;
    max-width: 200px;
    margin-top: 65px;
  }

  .testimonials__item--text {
    height: 180px;
    text-align: left;
    vertical-align: middle;
    display: table-cell;
    padding: 0 0 0 50px;
  }

  .testimonials__item--text .quote::before {
    top: -30px;
    left: -30px;
  }

  .testimonials__item--author {
    margin-left: 200px;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .single .testimonials__item--text {
    padding: 0 0 0 70px;
    font-size: 20px;
  }

  .single .testimonials__item--text .quote::before {
    top: -100px;
    left: -70px;
    font-size: 120px;
  }

  .text--submittals-semibold {
    font-weight: bold;
  }
}

@media screen and (min-width: 1260px) {
  .steps__stepboxes {
    height: 425px;
  }

  .steps__boxwrapper {
    max-width: 340px;
    height: 350px;
  }

  .smaller .steps__boxwrapper {
    max-width: 280px;
    overflow: hidden;
  }

  .steps__boxwrapper--step1 {
    height: 368px;
  }

  .steps__boxwrapper--step2 {
    height: 375px;
  }

  .steps__boxwrapper--step3 {
    height: 400px;
  }

  .cockpit__infolinecell {
    display: inline-block;
    margin-left: 25px;
    margin-right: 25px;
    background-image: url(../../images/active-list.png);
    background-size: 37px 20px;
    padding-left: 42px;
    background-repeat: no-repeat;
    line-height: 21px;
  }

  .block--austria-background {
    background-position: right bottom 100px;
    background-size: 450px;
    max-width: 1240px;
    padding-left: 40px;
  }

  .cockpit__infolinecell:not(:first-child) {
    display: inline-block;
  }

  .cockpit__wrapper {
    margin-right: 30px;
  }

  .cockpit__ad_list__block {
    margin-left: 15px;
  }

  .cockpit__header__pre_header {
    text-align: left;
  }

  .cockpit__header.cockpit__austria__header {
    margin-left: 0;
    margin-top: 0;
    max-width: none;
  }

  .cockpit__ad_block {
    margin-left: 0;
  }

  .block--austria--lead {
    padding-bottom: 50px;
    margin-top: 40px;
  }

  .steps__headerbox {
    height: 178px;
  }

  .steps__boxshape {
    height: 178px;
  }

  .steps__boxtriangle {
    width: 340px;
    height: 96px;
  }

  .steps__textbox {
    padding: 14px 30px 35px 30px;
  }

  .steps__textbox-header {
    font-size: 25px;
  }

  .smaller .steps__textbox-header {
    font-size: 20px;
  }

  .steps__textbox-text {
    font-size: 18px;
    line-height: 25px;
  }

  .comparison__itemlist {
    max-width: 1020px;
  }

  .comparison__iteminner {
    width: 460px;
  }
}

@media screen and (min-width: 1680px) {
  .mainnav {
    left: calc((100% - 1680px) / 2);
  }
}
