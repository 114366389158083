.form__button {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  font-family: $font_header_bold;
  text-transform: uppercase;
  line-height: 38px;
  border-radius: $box-radius;
  border: none;
  cursor: pointer;
}

.form__button--green {
  background-image: linear-gradient(to bottom right, $gruen, $dunkelgruen);
  color: $weiss;
  font-size: 18px;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
}

.form__button--green:hover {
  background-image: linear-gradient(to bottom right, $dunkelgruen, $gruen);
}

.form__button--red {
  background-image: linear-gradient(to bottom right, $rot, $errorcolor);
  color: $weiss;
  font-size: 18px;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
}

.form__button--whiteandgreen {
  background-color: $weiss;
  color: $gruen;
}

.form__button--register {
  min-width: 226px;
  max-width: 340px;
  margin: 0 auto;
}

.form__button--addresssubmit {
  width: 270px;
  margin: 0 auto;
}

.form__button--contact {
  width: 240px;
  margin-top: 35px;
}

.form__button--pointer {
  cursor: pointer;
}

.form__button--cellbutton {
  width: 100%;
  max-width: 226px;
}

.form__button--login {
  width: 165px;
}

.form__button--pwreset {
  min-width: 276px;
  max-width: 340px;
}

.form__button--paymentsubmit {
  //margin: 0 auto;
  //max-width: 250px;
}

.form__button--paymentchoice {
  margin: 0 auto;
  max-width: 240px;
}

.userbutton {
  display: inline-block;
  width: 22px;
  color: $gruen;
  font-size: 22px;
  cursor: pointer;
}

.leadbutton {
  display: inline-block;
  width: 140px;
  height: 30px;
  padding: 0 10px 0 10px;
  background-image: linear-gradient(to bottom right, $gruen, $dunkelgruen);
  border-radius: $box-radius;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
  color: $weiss;
  font-family: $font_header_bold, $font_fallback;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.leadbutton:hover {
  background-image: linear-gradient(to bottom right, $dunkelgruen, $gruen);
}

.userbutton_text {
  display: none;
  padding-left: 5px;
  color: $weiss;
  font-family: $font_header_bold, $font_fallback;
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
}

.cta {
  display: block;
  background-image: linear-gradient(to bottom right, $gruen, $dunkelgruen);
  border-radius: $box-radius;
  color: $weiss;
  text-decoration: none;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.cta:hover {
  background-image: linear-gradient(to bottom right, $dunkelgruen, $gruen);
}

.cta--orange {
  background-image: linear-gradient(to bottom right, #e4a600, #e4a600);
}

.cta.cta--orange:hover {
  background-image: linear-gradient(to bottom right, #e4a600, #f0bb2b);
}

.cta__wrapper,
.form__button_wrapper {
  display: table;
  height: 38px;
  margin: 0 auto;
}

.cta__icon,
.form__button_icon {
  display: table-cell;
  padding-right: 10px;
  vertical-align: middle;
  font-family: $font_ico !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -7px;
  text-indent: -5px;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.cta__text,
.form__button_text {
  display: table-cell;
  vertical-align: middle;
  font-family: $font_header_normal, $font_fallback;
  font-size: 18px;
  line-height: 38px;
  text-transform: uppercase;
}

.cta--large .cta__wrapper {
  height: 50px;
}

.cta--medium .cta__wrapper {
  height: 40px;
  .cta__text {
    line-height: 40px;
  }
}

.cta--large .cta__icon {
  display: none;
  font-size: 24px;
}

.cta--large .cta__text {
  font-size: 22px;
  line-height: 50px;
}

.cta--modify .cta__icon {
  display: none;
}

.cta--bold {
  font-family: $font_header_bold, $font_fallback;
}

.cta--success {
  width: 190px;
  margin: 0 auto;
}

.cta--register {
  width: 280px;
  margin-right: auto;
  margin-left: auto;
}

.cta__text--register {
  margin-top: 45px;
  display: inline-block;
  margin-bottom: 15px;
  font-family: SourceSansPro;
  font-size: 15px;
  color: #315171;
}

.cta__icon--success {
  width: 20px;
  height: 40px;
  background-image: url(../../images/plus-success.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.cta__text--success {
  padding-left: 12.5px;
}

.cta--download {
  width: 160px;
  margin: 0 auto;
}

.cta--left {
  margin: 0;
}

.cta__icon--download {
  width: 22px;
  height: 40px;
  background-image: url(../../images/cta-download-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.cta__icon--download--blue {
  width: 40px;
  height: 40px;
  background-image: url(../../images/download.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.help__content_submittalboxcell
  .cta--download--doc:hover
  .cta__icon--download--blue,
.cta__icon--download--blue:hover {
  background-image: url(../../images/download-hover.svg);
}

.cta__text--download {
  padding-left: 12.5px;
}

.cta--pomcomparison {
  background-image: none;
  color: $gruen;
  text-shadow: none;
  //cursor: pointer;
  border: 2px solid $gruen;
}

.cta--pomcomparison--orange {
  color: #e4a600;
  border: 2px solid #e4a600;
}

.cta--pomcomparison:hover {
  background-image: none;
}

.cta--pomcomparison .cta__wrapper {
  height: 34px;
}

.cta--pomcomparison .cta__text {
  line-height: 34px;
}

.cta--maxw360-auto {
  max-width: 360px;
  margin: 0 auto;
}

.cta--maxw280-auto {
  max-width: 280px;
  margin: 0 auto;
}

.comparison__item--active .cta--pomcomparison {
  background-image: linear-gradient(to bottom right, $gruen, $dunkelgruen);
  color: $weiss;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
  border: none;
}

.comparison__item--active .cta--pomcomparison.cta--pomcomparison--orange {
  background-image: linear-gradient(to bottom right, #e4a600, #e4a600);
}

.comparison__item--active .cta--pomcomparison .cta__wrapper {
  height: 38px;
}

.comparison__item--active .cta--pomcomparison .cta__text {
  line-height: 38px;
}

@media screen and (min-width: 640px) {
  .comparison__iteminner:hover .cta--pomcomparison {
    background-image: linear-gradient(to bottom right, $gruen, $dunkelgruen);
    color: $weiss;
    text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
    border: none;
  }

  .comparison__iteminner:hover .cta--pomcomparison.cta--pomcomparison--orange {
    background-image: linear-gradient(to bottom right, #e4a600, #e4a600);
  }

  .comparison__iteminner:hover .cta--pomcomparison .cta__wrapper {
    height: 38px;
  }

  .comparison__iteminner:hover .cta--pomcomparison .cta__text {
    line-height: 38px;
  }
}

@media screen and (min-width: 768px) {
  .leadbutton {
    width: 195px;
    height: 40px;
    padding: 0 15px 0 15px;
    font-size: 18px;
    line-height: 40px;
  }

  .cta--modify .cta__icon {
    display: table-cell;
  }

  .cta--large .cta__text {
    font-size: 25px;
    line-height: 60px;
  }

  .form__button--register {
    width: 260px;
  }

  .cta--download {
    width: 248px;
  }
}

@media screen and (min-width: 895px) {
  .userbutton {
    width: 120px;
    height: 40px;
    padding: 0 20px 0 20px;
    background-image: linear-gradient(to bottom right, $gruen, $dunkelgruen);
    border-radius: $box-radius;
    color: #fff;
    font-size: 18px;
    text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
  }

  .anonymous-userbutton {
    height: 30px;
  }

  .userbutton:hover {
    background-image: linear-gradient(to bottom right, $dunkelgruen, $gruen);
  }

  .userbutton_text {
    display: inline-block;
  }

  .anonymous-userbutton_text {
    line-height: 30px;
  }
}

@media screen and (min-width: 1100px) {
  .cta--pomcomparison .cta__wrapper {
    height: 56px;
  }

  .cta--pomcomparison .cta__text {
    line-height: 56px;
    font-size: 25px;
  }

  .comparison__iteminner:hover .cta--pomcomparison .cta__wrapper {
    height: 60px;
  }

  .comparison__iteminner:hover .cta--pomcomparison .cta__text {
    line-height: 60px;
  }
}
