.modalfade-enter-active {
  transition: opacity 0.5s;
}

.modalfade-leave-active {
  transition: opacity 0.5s;
}

.modalfade-enter,
.modalfade-leave-to {
  opacity: 0;
}

.refreshfade-enter-active {
  transition: opacity 1s;
}

.refreshfade-leave-active {
  transition: opacity 1s;
}

.refreshfade-enter,
.refreshfade-leave-to {
  opacity: 0;
}

.modal__control-flpagi {
  display: block;
  width: 15px;
  height: 15px;
  text-align: center;
  font-family: $font_header_normal;
  line-height: 15px;
  /*border-radius: $box-radius;*/
  border: none;
  background-color: $weiss;
}

.modal__control-flpagi--prev {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../images/minipagi-prev-active.svg);
  background-size: auto 15px;
}

.modal__control-flpagi--prevdisabled {
  background-image: url(../../images/minipagi-prev-disabled.svg);
}

.modal__control-flpagi--next {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../images/minipagi-next-active.svg);
  background-size: auto 15px;
}

.modal__control-flpagi--nextdisabled {
  background-image: url(../../images/minipagi-next-disabled.svg);
}

.minipagination {
  height: 15px;
}

.minipagination--right {
  float: right;
}

.minipagination_page {
  text-align: right;
  padding-right: 2px;
}

.minipagination_control {
  width: 18px;
  vertical-align: middle;
}

.minipagination_control--prev {
  padding-right: 3px;
}

.minipagination_control--next {
  padding-left: 3px;
}

:focus {
  outline: none;
}
