.rangeslider,
.rangeslider__fill {
  display: block;
  border-radius: 6px;
}

.rangeslider {
  background: $weiss;
  position: relative;
  box-shadow: 0 0 50px 6px rgba(0, 0, 0, 0.1);
}

.rangeslider--horizontal {
  height: 12px;
  width: 100%;
}

.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeslider__fill {
  background: $gruen;
  position: absolute;
}
.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}
.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%;
}

.rangeslider__handle {
  background: transparent;
  cursor: pointer;
  display: inline-block;
  width: 32px;
  height: 32px;
  position: absolute;
  background-image: url(../../images/bpm-handler-32.png);
}

.rangeslider--horizontal .rangeslider__handle {
  top: -10px;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}

input[type="range"]:focus + .rangeslider .rangeslider__handle {
  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
}

@media screen and (min-width: 768px) {
  .rangeslider,
  .rangeslider__fill {
    border-radius: 10px;
  }

  .rangeslider--horizontal {
    height: 20px;
  }

  .rangeslider__handle {
    width: 40px;
    height: 40px;
    background-image: url(../../images/bpm-handler-40.png);
  }
}
