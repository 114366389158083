html {
  height: 100%;
}

body {
  background-color: $grau;
  min-width: 320px;
}

.tmp-comingsoon {
  background-color: $hellgrau;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font_header_normal, $font_fallback;
  font-weight: normal;
  text-transform: uppercase;
}

h2 {
  font-family: $font_header_normal, $font_fallback;
  font-size: 30px; // 18
  font-weight: normal;
  line-height: 35px; // 30
  color: $dunkelblau;
  margin: 0 0 50px 0;
  text-transform: uppercase;
}

.h2--bold {
  font-family: $font_header_bold, $font_fallback;
  font-size: 29px;
}

h3 {
  font-family: $font_header_normal, $font_fallback;
  font-weight: normal;
  margin: 0;
}

.h3--bold {
  font-family: $font_header_bold, $font_fallback;
}

h4 {
  font-family: $font_header_bold, $font_fallback;
  font-weight: normal;
  font-size: 18px;
  margin: 0;
}

.h4--bold {
  font-family: $font_header_bold, $font_fallback;
  font-size: 18px;
}

h5 {
  font-family: $font_header_normal, $font_fallback;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  color: $dunkelblau;
  margin: 0 0 25px 0;
  text-transform: uppercase;
}

label,
p,
#fast-privacy-btn {
  font-family: $font_content_normal, $font_fallback;
  font-size: 15px;
}

a {
  color: $gruen;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  h2 {
    font-size: 50px;
    line-height: 50px;
    margin: 40px 0 70px 0;
  }

  .h2--bold {
    font-size: 50px;
  }

  h5 {
    font-size: 18px;
    line-height: 25px;
  }

  #fast-privacy-btn,
  p {
    font-size: 18px;
  }
}
