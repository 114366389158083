/*** Tooltips ***/
.ebrief-tooltip-content {
  display: none;
}

.tipso_bubble {
  position: absolute;
  z-index: 99999999;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(255, 255, 255, 0.97);
  border-radius: $box-radius;
  border: 1px solid $bordergrau;
}

.tipso_content {
  word-wrap: break-word;
  text-align: center;
  padding: 15px;
}

.tipso_bubble p {
  font-family: $font_content_normal;
  font-size: 13px;
  line-height: 15px;
  color: $blau;
}

.tipso_bubble p.text-bold {
  font-family: $font_content_bold;
}

.tipso_bubble p:not(:last-child) {
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .tipso_content {
    padding: 25px;
  }

  .tipso_bubble p {
    font-size: 15px;
    line-height: 18px;
  }
}
