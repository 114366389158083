@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?r5l5i6");
  src:
    url("../../fonts/icomoon.eot?r5l5i6#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?r5l5i6") format("truetype"),
    url("../../fonts/icomoon.woff?r5l5i6") format("woff"),
    url("../../fonts/icomoon.svg?r5l5i6#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// fira-sans-condensed-regular - latin
@font-face {
  font-family: "FiraSansCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/fira-sans-condensed-v3-latin-regular.eot"); // IE9 Compat Modes
  src:
    local("Fira Sans Condensed Regular"),
    local("FiraSansCondensed-Regular"),
    url("../../fonts/fira-sans-condensed-v3-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    // IE6-IE8
    url("../../fonts/fira-sans-condensed-v3-latin-regular.woff2")
      format("woff2"),
    // Super Modern Browsers
    url("../../fonts/fira-sans-condensed-v3-latin-regular.woff") format("woff"),
    // Modern Browsers
    url("../../fonts/fira-sans-condensed-v3-latin-regular.ttf")
      format("truetype"),
    // Safari, Android, iOS
    url("../../fonts/fira-sans-condensed-v3-latin-regular.svg#FiraSansCondensed")
      format("svg"); // Legacy iOS
}

// fira-sans-condensed-600 - latin
@font-face {
  font-family: "FiraSansCondensedSemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/fira-sans-condensed-v3-latin-600.eot"); // IE9 Compat Modes
  src:
    local("Fira Sans Condensed SemiBold"),
    local("FiraSansCondensed-SemiBold"),
    url("../../fonts/fira-sans-condensed-v3-latin-600.eot?#iefix")
      format("embedded-opentype"),
    // IE6-IE8
    url("../../fonts/fira-sans-condensed-v3-latin-600.woff2") format("woff2"),
    // Super Modern Browsers
    url("../../fonts/fira-sans-condensed-v3-latin-600.woff") format("woff"),
    // Modern Browsers
    url("../../fonts/fira-sans-condensed-v3-latin-600.ttf") format("truetype"),
    // Safari, Android, iOS
    url("../../fonts/fira-sans-condensed-v3-latin-600.svg#FiraSansCondensedSemiBold")
      format("svg"); // Legacy iOS
}

// source-sans-pro-regular - latin
@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/source-sans-pro-v12-latin-regular.eot"); // IE9 Compat Modes
  src:
    local("Source Sans Pro Regular"),
    local("SourceSansPro-Regular"),
    url("../../fonts/source-sans-pro-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    // IE6-IE8
    url("../../fonts/source-sans-pro-v12-latin-regular.woff2") format("woff2"),
    // Super Modern Browsers
    url("../../fonts/source-sans-pro-v12-latin-regular.woff") format("woff"),
    // Modern Browsers
    url("../../fonts/source-sans-pro-v12-latin-regular.ttf") format("truetype"),
    // Safari, Android, iOS
    url("../../fonts/source-sans-pro-v12-latin-regular.svg#SourceSansPro")
      format("svg"); // Legacy iOS
}

// source-sans-pro-regular - latin
@font-face {
  font-family: "SourceSansProItalic";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/source-sans-pro-v13-latin-italic.eot"); // IE9 Compat Modes
  src:
    local("Source Sans Pro Italic"),
    local("SourceSansPro-Italic"),
    url("../../fonts/source-sans-pro-v13-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    // IE6-IE8
    url("../../fonts/source-sans-pro-v13-latin-italic.woff2") format("woff2"),
    // Super Modern Browsers
    url("../../fonts/source-sans-pro-v13-latin-italic.woff") format("woff"),
    // Modern Browsers
    url("../../fonts/source-sans-pro-v13-latin-italic.ttf") format("truetype"),
    // Safari, Android, iOS
    url("../../fonts/source-sans-pro-v13-latin-italic.svg#SourceSansPro")
      format("svg"); // Legacy iOS
}

// source-sans-pro-600 - latin
@font-face {
  font-family: "SourceSansProSemiBold";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/source-sans-pro-v13-latin-600.eot"); // IE9 Compat Modes
  src:
    local("Source Sans Pro Semi Bold"),
    local("SourceSansPro-SemiBold"),
    url("../../fonts/source-sans-pro-v13-latin-600.eot?#iefix")
      format("embedded-opentype"),
    // IE6-IE8
    url("../../fonts/source-sans-pro-v13-latin-600.woff2") format("woff2"),
    // Super Modern Browsers
    url("../../fonts/source-sans-pro-v13-latin-600.woff") format("woff"),
    // Modern Browsers
    url("../../fonts/source-sans-pro-v13-latin-600.ttf") format("truetype"),
    // Safari, Android, iOS
    url("../../fonts/source-sans-pro-v13-latin-600.svg#SourceSansProBold")
      format("svg"); // Legacy iOS
}

// source-sans-pro-700 - latin
@font-face {
  font-family: "SourceSansProBold";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/source-sans-pro-v12-latin-700.eot"); // IE9 Compat Modes
  src:
    local("Source Sans Pro Bold"),
    local("SourceSansPro-Bold"),
    url("../../fonts/source-sans-pro-v12-latin-700.eot?#iefix")
      format("embedded-opentype"),
    // IE6-IE8
    url("../../fonts/source-sans-pro-v12-latin-700.woff2") format("woff2"),
    // Super Modern Browsers
    url("../../fonts/source-sans-pro-v12-latin-700.woff") format("woff"),
    // Modern Browsers
    url("../../fonts/source-sans-pro-v12-latin-700.ttf") format("truetype"),
    // Safari, Android, iOS
    url("../../fonts/source-sans-pro-v12-latin-700.svg#SourceSansProBold")
      format("svg"); // Legacy iOS
}

@font-face {
  font-family: "IcoFont";
  font-weight: normal;
  font-style: normal;
  src:
    url("../../fonts/icofont.woff2") format("woff2"),
    url("../../fonts/icofont.woff") format("woff");
}

/*** icofont ***/
[class^="icofont-"],
[class*=" icofont-"],
.job__header:before {
  font-family: "IcoFont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.icofont-trash:before {
  content: "\ee09";
}

.icofont-tick-mark:before {
  content: "\f00e";
}

.icofont-user-alt-4:before {
  content: "\ecfe";
}

.icofont-navigation-menu:before {
  content: "\efa2";
}

.icofont-rounded-right:before {
  content: "\eaa0";
}

.icofont-ui-laoding:before {
  content: "\ec60";
}

.icofont-exclamation-tringle:before {
  content: "\ef1b";
}

.icofont-question-circle:before {
  content: "\efca";
}

.icofont-basket:before {
  content: "\eeb0";
}

.icofont-ui-search:before {
  content: "\ec82";
}

.icofont-ui-edit:before {
  content: "\ec55";
}

.icofont-close:before {
  content: "\eee4";
}

.icofont-refresh:before {
  content: "\efd1";
}

.icon-font-thumbs-up:before {
  content: "\e900";
}

.icofont-spin {
  -webkit-animation: icofont-spin 2s infinite linear;
  animation: icofont-spin 2s infinite linear;
  display: inline-block;
}

@-webkit-keyframes icofont-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes icofont-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icofont-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icofont-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icofont-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

:root .icofont-rotate-90,
:root .icofont-rotate-180,
:root .icofont-rotate-270,
:root .icofont-flip-horizontal,
:root .icofont-flip-vertical {
  -webkit-filter: none;
  filter: none;
  display: inline-block;
}

[class^="icon-font-"],
[class*=" icon-font-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
