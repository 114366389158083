.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: rgba(49, 81, 113, 0.9);
  text-align: center;
  white-space: nowrap;
  overflow: auto;
}

.overlay--error {
  z-index: 999;
}

.overlay--campaign {
  z-index: 9999;
}

.overlay--admin {
  background-color: rgba(244, 244, 244, 0.9);
}

.overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.overlay__wrapper {
  display: inline-block;
  width: 100%;
  padding: $std-padding-mobile;
  vertical-align: middle;
}

.overlay__content {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  margin-left: 0.25em;
  white-space: normal;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  background-color: rgba(49, 81, 113, 0.9);
}

.validation-error {
  color: $rot;
  font-size: 14px;
}

.modal__wrapper {
  /* This way it could be display flex or grid or whatever also. */
  display: block;
  /* Probably need media queries here */
  //width: 600px;
  max-width: 100%;
  //height: 400px;
  max-height: 100%;
  position: fixed;
  z-index: 2147483648;
  left: 50%;
  top: 50%;
  /* Use this for centering if unknown width/height */
  //transform: translate(-50%, -50%);
  /* If known, negative margins are probably better (less chance of blurry text). */
  /* margin: -200px 0 0 -200px; */
  //background: white;
  //box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
  border: 20px solid transparent;
  overflow: auto;
}
.modal.closed .modal__overlay,
.modal.closed .modal__wrapper {
  display: none;
}

.modal__content-list {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
  border-radius: $box-radius;
  font-family: $font_content_normal;
}

.preview__container {
  p {
    font-size: 14px;
  }
}

.modal__content-list--autowidth {
  width: auto;
  max-width: none;
}

.modal__content-list--control,
.modal__content-list--preview,
.modal__content-list--edit,
.modal__content-list--campaign {
  max-width: 280px;
}

.modal__content-item {
  list-style-type: none;
  text-align: center;
}

.modal__content-item--close {
  height: 38px;
  padding: 10px;
}

.modal__content-item--title {
  height: 58px;
  padding: 20px;
  border-bottom: 1px solid $bordergrau;
}

.modal__content-item--campaigntitle {
  height: 48px;
  padding: 20px 20px 0 20px;
}

.modal__close {
  display: inline-block;
  float: right;
  width: 12px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 12px;
  background-image: url(../../images/modal-close.svg);
  cursor: pointer;
}

.modal__close--campaignclose {
  height: 28px;
}

.modal__title {
  display: inline-block;
  float: left;
  padding-right: 15px;
  font-family: $font_header_bold;
  font-size: 18px;
  line-height: 18px;
  color: $dunkelblau;
  text-transform: uppercase;
}

.modal__title_contend-desk {
  display: none;
}

.modal__content-item--content {
  padding: 0 10px 35px 10px;
}

.modal__content-item--padconsistent {
  padding: 0 10px 10px 10px;
}

.modal__content-item--preview {
  padding: 35px 20px 35px 20px;
}

.modal__content-item--footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12.5px 15px 12.5px 15px;
  background-color: $hellgrau;
  color: $gruen;
  font-size: 13px;
  border-bottom-left-radius: $box-radius;
  border-bottom-right-radius: $box-radius;
}

.modal__header {
  font-family: $font_header_bold;
  font-size: 30px;
  line-height: 30px;
  color: $dunkelblau;
}

.modal__header--small {
  font-size: 20px;
  line-height: 20px;
}

.modal__message {
  margin-top: 10px;
  font-size: 18px;
  line-height: 25px;
  color: $blau;
}

.modal__message--smaller {
  font-size: 15px;
}

.modal__message--error {
  display: inline-block;
  margin-top: 0;
  padding-left: 20px;
  background-image: url(../../images/warning-errored.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: left center;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 15px;
}

.modal__message--modalcontrol {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 20px;
  background-image: url(../../images/warning-errored.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: left center;
  font-family: $font_content_normal;
  font-size: 16px !important;
  line-height: 15px;
  color: $errorcolor;
}

.modal__message--small {
  font-size: 20px;
}

.modal__uploaderror {
  margin: 0;
  margin-bottom: 12px;
  color: $blau;
}

.modal__uploaderror:last-of-type {
  margin-bottom: 0;
}

.modal__uploaderror_title {
  margin-top: 0;
  padding-left: 22.5px;
  background-image: url(../../images/warning-errored.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: left center;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 18px;
}

.modal__uploaderror_descr {
  margin-top: 2px;
  margin-left: 0;
  padding: 0 22.5px 0 22.5px;
  font-family: $font_content_normal;
  font-size: 12px;
  line-height: 15px;
}

.modal__uploaderror_filenames {
  max-width: 100%;
  margin-top: 10px;
  padding: 0;
}

.modal__uploaderror_item {
  padding: 0;
  list-style-type: none;
  margin-top: 5px;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 18px;
}

.modal__uploaderror_itemfilename {
  display: inline-block;
  max-width: 590px;
  margin: 0 auto;
  height: 18px;
  padding-left: 20px;
  word-break: break-all;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 14px;
  background-image: url(../../images/modal-error-list-type.svg);
}

.modal__uploaderror_para {
  margin-top: 15px;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 18px;
}

.modal__iconline {
  margin-top: 26px;
}

.modal__lineitem {
  vertical-align: top;
  display: inline-block;
  font-size: 0;
}

.modal__iconitem {
  margin: 0;
}

.modal__icondescr {
  height: 50px;
  padding-top: 10px;
  font-size: 15px;
  line-height: 20px;
  color: $blau;
}

.modal__icondescr--desk {
  display: none;
}

.modal__icondescr--tick {
  font-family: $font_content_bold;
  line-height: 40px;
}

.modal__lineitem--letter,
.modal__lineitem--link,
.modal__lineitem--tick {
  width: 70px;
  height: 120px;

  &-single {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.modal__lineitem--direction {
  width: 14px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center 87.5px;
  background-size: 14px auto;
  background-image: url(../../images/registration-right.svg);
}

.modal__circle {
  width: 70px;
  height: 70px;
  background-color: $hellgrau;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
}

.modal__circle--letter {
  background-image: url(../../images/registration-letter.svg);
  background-size: 35px auto;
}

.modal__circle--link {
  margin: 0 auto;
  background-image: url(../../images/registration-link.svg);
  background-size: 29px auto;
}

.modal__circle--tick {
  background-image: url(../../images/registration-tick.svg);
  background-size: 35px auto;
}

.modal__campaign {
  padding: 20px;
}

.modal__campaign_header {
  margin-bottom: 15px;
  font-size: 22px;
  color: $dunkelblau;
  text-align: left;
}

.modal__campaign_paragraph {
  margin-bottom: 25px;
  font-size: 15px;
  color: $blau;
  text-align: left;
}

.modal__control {
  padding: 20px;
}

.modal__control_header {
  margin-bottom: 15px;
  font-family: $font_header_bold;
  font-size: 15px;
  color: $dunkelblau;
  text-transform: uppercase;
  text-align: left;
}

.modal__control_header_sub {
  text-align: left;
  font-size: 15px;
  line-height: 15px;
  font-family: SourceSansPro;
  color: #315171;
  margin-bottom: 1em;
}

.modal__control_uploadcta {
  width: 100%;
  height: 40px;
  padding: 0 42px 0 15px;
  background-color: $weiss;
  border: 1px solid $gruen;
  border-radius: $box-radius;
  color: $gruen;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 28px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-image: url(../../images/upload-modal.svg);
  background-size: 18px auto;
  text-align: left;
}

.modal__control_file-list {
  width: 100%;
  //margin-top: 15px;
  padding: 0;
  max-width: 240px;
}

.modal__control_file-list-additional {
  display: none;
  font: $font_content_normal;
  text-align: right;
  margin: 5px 5px 0 0;
  span:nth-child(3) {
    text-decoration: underline;
    cursor: pointer;
  }
}

.modal__control_file-list--empty {
  margin-top: 0;
  height: 0;
  overflow-clip: hidden;
}

.modal__control_file-listitem {
  width: 100%;
  height: 26px;
  margin-top: 5px;
  border-radius: $box-radius;
  background-color: rgba(47, 81, 113, 0.15);
  list-style-type: none;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 26px;
  color: $blau;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 0 30px 0 10px;
}

.modal__control_file-listitem:first-child {
  margin-top: 0;
}

.modal__control_file-itemremover {
  display: inline-block;
  float: right;
  width: 10px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../images/modal-control-file-remover.svg);
  background-size: 10px auto;
  position: absolute;
  top: 0;
  right: 10px;
}

.modal__control_file-list-control {
  width: 100%;
  height: 15px;
  margin-top: 3px;
  text-align: left;
  font-size: 15px;
  line-height: 15px;
  font-family: $font_content_normal;
  color: $blau;
}

.modal__control_label {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  font-family: $font_content_normal;
  font-size: 12px;
  color: $dunkelgrau;
  text-transform: uppercase;
  &.--blue {
    color: $blau;
  }
  &.--text-standard {
    text-transform: none;
  }
}

.modal__control_label--return-envelope {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  font-family: $font_content_normal;
  font-size: 15px;
  color: $blau;
}

.modal__control_label--char-count {
  display: inline-block;
  width: 100%;
  font-family: $font_content_normal;
  font-size: 12px;
  text-align: right;
  color: $dunkelgrau;
  &.--warning {
    color: #ffa500 !important;
  }
}

.modal__control_label--spliting-value {
  width: auto;
  padding-left: 10px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 40px;
  color: $blau;
  text-transform: none;
}

.modal__control_input {
  width: 90px;
  height: 40px;
  padding: 0 10px 0 10px;
  border: 1px solid $bordergrau;
  border-radius: $box-radius;
  font-family: $font_content_normal;
  font-size: 15px;
  line-height: 38px;
  color: $blau;
}

.modal__control_action {
  margin-top: 35px;
}

.modal__attachments_warning {
  padding-left: 25px;
  font-size: 18px;
  line-height: 23px;
  font-family: SourceSansPro;
  color: #df3b2a;
  margin-top: 12px;
}

.modal__control_actionwrapper {
  margin-top: 20px;
}

.modal__control_actionwrapper--barcodefixing {
  margin-bottom: 35px;
}

.modal__control_text {
  color: $blau;
  font-size: 16px;
  margin-bottom: 25px;
  line-height: 1.4;
}

.modal__content-list--preview,
.modal__content-list--error {
  .modal__title {
    color: $rot;
    &.modal__title--preview-success {
      color: $blau;
    }
    &.modal__title--preview-warning {
      color: $jobwarningcolor;
    }
  }
}

.modal__content-list--error {
  .modal__control_header {
    text-align: center;
    font-size: 18px;
  }
}

.modal__content-list--preview {
  .modal__control_submit {
    max-width: 300px;
  }
}

.modal__content-list--edit {
  .modal__control_submit {
    display: inline-block;
    max-width: 300px;
  }
}

.modal__content-preview-subheader {
  margin: 0 0 15px 0;
  text-align: left;
  font-family: $font_content_bold;
  font-size: 15px;
  line-height: 18px;
  color: $jobwarningcolor;
}

// .modal__content-list--bulk .controlform--upload--attachments{
//   display: none;
// }

// .modal__content-list--bulk .controlform--upload--attachments.show-uploads{
//   display: block;
// }

button.modal__delete_action,
#edit-letter-submit {
  width: 98%;
}

span.modal__control_cancel,
button.modal__control_cancel {
  color: $rot;
  background: $hellgrau;
  border: 1px solid $grau;
  padding: 0 20px;
  height: 40px;
  line-height: 38px;
  cursor: pointer;
  border-radius: $box-radius;
  font-family: $font_header_bold;
  text-transform: uppercase;
  display: inline-block;
  //text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);

  &:hover {
    background: $grau;
  }

  &.modal__error-button {
    color: $blau;
  }
}

.modal__title--limited {
  max-width: 250px;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal__title--full {
  display: inline-block;
  overflow: hidden;
}

.modal__accept_action--desktop {
  display: none;
}

.modal__accept_action--mobile {
  display: inline-block;
  margin-bottom: 15px;
}

.modal__preview-info {
  display: inline-block;
  margin: 0 auto;
  padding-left: 20px;
  line-height: 18px;
  color: $blau;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(../../images/info-circle-green.svg);
}

.text--content-preview {
  font-size: 15px;
  line-height: 18px;
  color: $blau;
  text-align: left;
  margin-bottom: 15px;
}

.text--content-line {
  margin-bottom: 0;
}

.campaignContent {
  cursor: default;
  pointer-events: none;
}

.campaignClose {
  cursor: pointer;
  pointer-events: auto;
}

.modal__control_section_right {
  margin-top: 25px;
}

.modal__control_section_right .word-spliting .modal__control_input {
  width: 100%;
}

.modal__control_section_right .modal__control_header {
  margin-top: 25px;
}

.modal__control_section_right .form__pincheckbox-label {
  width: 80%;
  line-height: 1.3;
}

.modal__control_section_right .form__formrow--left {
  margin-bottom: 10px;
}

.modal__control_section_left .controlform--upload--attachments {
  margin-bottom: 5px;
}

.modal__control_section_left .attachments_wrapper {
  padding-left: 0;
}

@media screen and (min-width: 769px) {
  .preview-barcode-wrapper {
    padding: 0 205px 10px 230px;
  }
}

@media screen and (min-width: 768px) {
  .modal__accept_action--desktop {
    display: inline-block;
  }

  .modal__control_label--return-envelope {
    margin-top: 2rem;
  }

  .modal__control_section_right .modal__control_header,
  .modal__control_section_right {
    margin-top: inherit;
  }

  .modal__control_section_right .form__pincheckbox-label {
    width: auto;
    line-height: inherit;
  }

  .modal__content-list--single
    .modal__control_section_right
    .form__formrow--left {
    margin-bottom: 30px;
  }

  .form_block {
    display: block !important;
  }

  .modal__accept_action--mobile,
  .preview-barcode-text-mobile {
    display: none;
  }

  .preview__number {
    top: 0;
    bottom: unset;
  }

  .preview__error {
    i {
      display: inline;
    }
  }

  .preview-nav-mobile {
    display: none;
  }

  .preview-nav-desk {
    display: block;
  }

  .modal__title_contend-desk {
    display: inline;
  }

  .modal__control_text {
    padding: 0 20px;
  }

  .modal__control_uploadcta {
    position: relative;
    top: 7px;
  }

  .modal__content-item--close {
    height: 58px;
    padding: 20px 15px 20px 15px;
  }

  .modal__close {
    width: 18px;
    height: 18px;
    background-size: auto 18px;
  }

  .modal__close--campaignclose {
    height: 28px;
  }

  .modal__content-item--content {
    padding: 0 15px 40px 15px;
  }

  .modal__content-item--padconsistent {
    padding: 0 15px 15px 15px;
  }

  .modal__content-item--footer {
    padding: 12.5px 15px 12.5px 15px;
    font-size: 15px;
  }

  .modal__control_section_left .attachments_wrapper {
    padding-left: 15px;
  }

  .modal__header {
    font-size: 50px;
    line-height: 50px;
  }

  .modal__header--small {
    font-size: 18px;
    line-height: 18px;
  }

  .modal__message {
    margin-top: 15px;
    font-size: 25px;

    &--small {
      font-size: 20px;
    }

    &--smaller {
      font-size: 15px;
    }
  }

  .modal__uploaderror {
    margin-bottom: 15px;
  }

  .modal__uploaderror_title {
    padding-left: 25px;
    /*background-size: 15px 15px;*/
    font-size: 18px;
    line-height: 23px;
  }

  //.modal__uploaderror_filenames {
  //  padding: 0;
  //}

  .modal__uploaderror_item {
    font-family: $font_content_normal;
    font-size: 18px;
    line-height: 23px;
  }

  .modal__uploaderror_itemfilename {
    height: 23px;
    padding-left: 27px;
    background-size: auto 19px;
  }

  .modal__uploaderror_para {
    font-size: 18px;
    line-height: 23px;
  }

  .modal__iconline {
    margin-top: 40px;
  }

  .modal__icondescr {
    height: auto;
    padding-top: 15px;
    font-size: 18px;
    line-height: 23px;
  }

  .modal__icondescr--desk {
    display: inline;
  }

  .modal__icondescr--mobile {
    display: none;
  }

  .modal__lineitem--letter,
  .modal__lineitem--link,
  .modal__lineitem--tick {
    height: auto;
  }

  .modal__lineitem--letter {
    width: 125px;
    margin-right: 47px;
  }

  .modal__lineitem--link {
    width: 182px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .modal__lineitem--tick {
    width: 125px;
    margin-left: 47px;
    &-single {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  .modal__lineitem--direction {
    width: 27px;
    height: 158px;
    background-position: center 38px;
    background-size: auto;
  }

  .modal__circle {
    width: 125px;
    height: 125px;
  }

  .modal__circle--letter {
    background-size: 62px auto;
  }

  .modal__circle--link {
    background-size: 62px auto;
  }

  .modal__circle--tick {
    background-size: 62px auto;
  }

  .modal__content-list--control {
    max-width: 560px;
  }

  .modal__content-list--control.modal__content-list--bulk {
    max-width: 1000px;
    min-height: 660px;
  }

  .modal__content-list--control.modal__content-list--single {
    max-width: 1000px;
    min-height: 520px;
  }

  .modal__content-list--bulk
    .modal__control_section_right
    .modal__control_header-high {
    margin-top: 3em;
  }

  .modal__content-list--preview {
    max-width: 630px;
  }

  .modal__content-list--edit {
    max-width: 700px;
  }

  .modal__content-list--campaign {
    max-width: 465px;
  }

  .modal__content-list--bulk
    .word-spliting
    .modal__control_label--spliting-value {
    display: block;
    margin-bottom: 3px;
  }

  .modal__content-list--bulk .word-spliting .modal__control_input {
    width: 96%;
  }

  .modal__content-list--bulk .modal__control_label.splitingtype {
    margin-top: 10px;
  }

  .modal__content-list--bulk .upload__section_header_tooltip {
    left: 7px !important;
    top: 2px !important;
  }

  .preview__slide {
    padding: 25px 90px;
  }

  .modal__title--limited {
    display: inline-block;
  }

  .modal__content-list--single .modal__control,
  .modal__content-list--bulk .modal__control {
    overflow: hidden;
  }

  .modal__control_section_left {
    float: left;
    width: 33%;
    margin-left: 2%;
  }

  .modal__content-list--bulk .modal__control_section_left .scroll {
    max-height: 240px;
    overflow-y: scroll;
  }

  .modal__content-list--single .modal__control_section_left .scroll {
    max-height: 180px;
    overflow-y: scroll;
  }

  .modal__control_section_right {
    float: right;
    width: 61%;
  }

  .modal__content-list--bulk .form__formrow-flat {
    margin-bottom: 0;
  }

  .modal__content-list--bulk .modal__control_file-list-control {
    margin-bottom: 10px;
  }

  .modal__content-list--bulk .modal__control_action {
    text-align: left;
  }

  .modal__content-list--bulk .modal__control_actionwrapper_submit {
    display: inline-block;
    width: 70%;
  }

  .modal__content-list--bulk .modal__control_actionwrapper_close {
    display: inline-block;
    width: 25%;
    text-align: right;
  }

  // .modal__content-list--bulk .controlform--upload--attachments{
  //   display: none;
  // }

  // .modal__content-list--bulk .controlform--upload--attachments.show-uploads{
  //   display: block;
  // }

  .modal__content-preview-subheader {
    font-family: $font_content_semibold;
    font-size: 18px;
  }
}
